import ComparisonMode from "../../../utils/ComparisonMode"
import Challenge from "../../Challenge"
import TaskGroup from "../../TaskGroup"
import PerformanceIndicatorTaskOptions from "../../tasks/options/PerformanceIndicatorTaskOptions"
import PIUnsatisfiedNodesRelativeCountTask from "../../tasks/PIUnsatisfiedNodesRelativeCountTask"

export default function PIUnsatisfiedNodesRelativeCounts(): Challenge {
  return new Challenge([
    new TaskGroup([
      new PIUnsatisfiedNodesRelativeCountTask(new PerformanceIndicatorTaskOptions({
        comparisonMode: ComparisonMode.lt,
        threshold: 0.125
      }))
    ])
  ])
}
