import { Close, Delete, Edit } from "@mui/icons-material"
import { Box, Button, Card, CardActions, CardHeader, IconButton, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BaseFeature } from "../../../data/BaseFeature"
import FormTitle from "../../forms/FormTitle"
import { EWAModelWrapper } from "../EWAModelWrapper"
import { useCallback, useEffect } from "react"

export interface SelectedFeatureOverlayProps {
    selectedFeatureIndices: number[]
    setSelectedFeatureIndices: (_: number[]) => void
    modelWrapper: EWAModelWrapper
    setModelWrapper: (_:EWAModelWrapper) => void
    openEdit: () => void
}

export default function SelectedFeatureOverlay(props: SelectedFeatureOverlayProps) {
    const {
        selectedFeatureIndices,
        setSelectedFeatureIndices,
        modelWrapper,
        setModelWrapper,
        openEdit
    } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const onDeleteButtonDown = useCallback((e: KeyboardEvent) => {
        if (e.key !== 'Delete') return

        handleDelete()
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', onDeleteButtonDown)

        return () => {
            document.removeEventListener('keydown', onDeleteButtonDown)
        }
    }, [])

    const model = modelWrapper.model
    const features: BaseFeature<any, any, any>[] = []
    for (const i of selectedFeatureIndices) {
        features.push(model.features[i])
    }

    function handleDelete() {
        for (const feature of features) {
            feature.delete()
        }

        setModelWrapper(new EWAModelWrapper(modelWrapper!.model));
        setSelectedFeatureIndices([])
    }

    return <Card sx={{ position: "absolute", bottom: 10, right: 10, backgroundColor: theme.palette.darkBlue.main, width: 250, zIndex: 2 }}>
        <CardHeader
            title={<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <FormTitle features={features} />
                </Box>
                <IconButton title={t('general.close') as string} onClick={() => setSelectedFeatureIndices([])}><Close /></IconButton>
            </Box>}
        />
        <CardActions sx={{ justifyContent: 'center' }}>
            <Button color="success" variant="contained" onClick={() => openEdit()} title={t('general.edit') as string}><Edit /></Button>
            <Button color="error" variant="contained" onClick={() => handleDelete()} title={t('general.delete') as string}><Delete /></Button>
        </CardActions>
    </Card>
}
