import { Junction } from "../../data/Junction";
import { Pipe } from "../../data/Pipe";
import { Pump } from "../../data/Pump";
import { Reservoir } from "../../data/Reservoir";
import { Tank } from "../../data/Tank";
import { ValveFCV } from "../../data/ValveFCV";
import { ValveGPV } from "../../data/ValveGPV";
import { ValvePBV } from "../../data/ValvePBV";
import { ValvePRV } from "../../data/ValvePRV";
import { ValvePSV } from "../../data/ValvePSV";
import { ValveTCV } from "../../data/ValveTCV";
import { SingleJunctionForm } from "./SingleJunctionForm";
import { SingleTankForm } from "./SingleTankForm";
import { SingleReservoirForm } from "./SingleReservoirForm";
import { SinglePipeForm } from "./SinglePipeForm";
import { SinglePumpForm } from "./SinglePumpForm";
import { SingleValvePRVForm } from "./SingleValvePRVForm";
import { SingleValvePSVForm } from "./SingleValvePSVForm";
import { SingleValvePBVForm } from "./SingleValvePBVForm";
import { SingleValveTCVForm } from "./SingleValveTCVForm";
import { SingleValveGPVForm } from "./SingleValveGPVForm";
import { SingleValveFCVForm } from "./SingleValveFCVForm";
import { MultiJunctionForm } from "./MultiJunctionForm";
import { MultiTankForm } from "./MultiTankForm";
import { MultiReservoirForm } from "./MultiReservoirForm";
import { MultiPipeForm } from "./MultiPipeForm";
import { MultiPumpForm } from "./MultiPumpForm";
import { MultiValvePRVForm } from "./MultiValvePRVForm";
import { MultiValveFCVForm } from "./MultiValveFCVForm";
import { MultiValveGPVForm } from "./MultiValveGPVForm";
import { MultiValvePBVForm } from "./MultiValvePBVForm";
import { MultiValvePSVForm } from "./MultiValvePSVForm";
import { MultiValveTCVForm } from "./MultiValveTCVForm";
import { useFormContext } from "./FormContext";
import { Well } from "../../data/Well";
import { Spring } from "../../data/Spring";
import { SingleWellForm } from "./SingleWellForm";
import { SingleSpringForm } from "./SingleSpringForm";
import { MultiWellForm } from "./MultiWellForm";
import { MultiSpringForm } from "./MultiSpringForm";

export interface EditFormProps {
}

export function EditForm(props: EditFormProps) {
    const { model, selectedFeatureIndices } = useFormContext();

    let currentDate = useFormContext().currentDate ?? model!.modelDate;
    if (Array.isArray(currentDate)) {
        currentDate = currentDate[0]
    }

    function renderSelectionInfo() {
        if (selectedFeatureIndices!.length === 1) {
            let element = model!.features[selectedFeatureIndices![0]]
            if (element instanceof Junction) {
                return (<SingleJunctionForm junction={element} />)
            } else if (element instanceof Well) {
                return <SingleWellForm well={element} />
            } else if (element instanceof Spring) {
                return <SingleSpringForm spring={element} />
            } else if (element instanceof Tank) {
                return (<SingleTankForm tank={element} />)
            } else if (element instanceof Reservoir) {
                return (<SingleReservoirForm reservoir={element} />)
            } else if (element instanceof Pipe) {
                return (<SinglePipeForm pipe={element} />)
            } else if (element instanceof Pump) {
                return (<SinglePumpForm pump={element} />)
            } else if (element instanceof ValvePRV) {
                return (<SingleValvePRVForm valve={element} />)
            } else if (element instanceof ValvePSV) {
                return (<SingleValvePSVForm valve={element} />)
            } else if (element instanceof ValvePBV) {
                return (<SingleValvePBVForm valve={element} />)
            } else if (element instanceof ValveTCV) {
                return (<SingleValveTCVForm valve={element} />)
            } else if (element instanceof ValveGPV) {
                return (<SingleValveGPVForm valve={element} />)
            } else if (element instanceof ValveFCV) {
                return (<SingleValveFCVForm valve={element} />)
            }
        } else {
            const element = model!.features[selectedFeatureIndices![0]]
            const elements = []
            for (const i of selectedFeatureIndices!) {
                elements.push(model!.features[i])
            }
            if (element instanceof Junction) {
                return (<MultiJunctionForm junctions={elements as Junction[]} />)
            } else if (element instanceof Well) {
                return <MultiWellForm wells={elements as Well[]} />
            } else if (element instanceof Spring) {
                return <MultiSpringForm springs={elements as Spring[]} />
            } else if (element instanceof Tank) {
                return (<MultiTankForm tanks={elements as Tank[]} />)
            } else if (element instanceof Reservoir) {
                return (<MultiReservoirForm reservoirs={elements as Reservoir[]} />)
            } else if (element instanceof Pipe) {
                return (<MultiPipeForm pipes={elements as Pipe[]} />)
            } else if (element instanceof Pump) {
                return (<MultiPumpForm pumps={elements as Pump[]} />)
            } else if (element instanceof ValveFCV) {
                return (<MultiValveFCVForm valves={elements as ValveFCV[]} />)
            } else if (element instanceof ValveGPV) {
                return (<MultiValveGPVForm valves={elements as ValveGPV[]} />)
            } else if (element instanceof ValvePBV) {
                return (<MultiValvePBVForm valves={elements as ValvePBV[]} />)
            } else if (element instanceof ValvePRV) {
                return (<MultiValvePRVForm valves={elements as ValvePRV[]} />)
            } else if (element instanceof ValvePSV) {
                return (<MultiValvePSVForm valves={elements as ValvePSV[]} />)
            } else if (element instanceof ValveTCV) {
                return (<MultiValveTCVForm valves={elements as ValveTCV[]} />)
            }
        }
        return (<div></div>)
    }

    return <>
        {renderSelectionInfo()}
    </>
}
