import EventType from "../EventType";

export interface ChallengeCompletedEventArgs {
}

export default class ChallengeCompletedEvent extends CustomEvent<ChallengeCompletedEventArgs> {
  public constructor() {
    super(EventType.ChallengeCompleted)
  }
}
