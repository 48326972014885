import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { EWAComponentType } from "../../../../data/EWAComponentType";
import { ValveComponentType } from "../../../../data/ValveComponentType";
import { IComponentTaskOptions } from "../../../tasks/options/ComponentTaskOptions";
import YearSelect from "./common_inputs/YearSelect";
import ComponentTypeSelect from "./common_inputs/ComponentTypeSelect";
import ValveTypeSelect from "./common_inputs/ValveTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  componentType?: EWAComponentType
  valveType?: ValveComponentType
  year?: number
}

class ComponentTaskOptionsForm extends TaskOptionsExtrasForm<State, IComponentTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IComponentTaskOptions>) {
    super(props);

    this.onComponentType = this.onComponentType.bind(this);

    this.renderComponentTypeSelect = this.renderComponentTypeSelect.bind(this);
    this.renderValveTypeSelect = this.renderValveTypeSelect.bind(this);
    this.renderYearField = this.renderYearField.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IComponentTaskOptions>): State {
    return {
      componentType: props.options.componentType,
      valveType: props.options.valveType,
      year: props.options.year
    };
  }

  public extractOptions() {
    const componentType = this.state.componentType
    const valveType = componentType === EWAComponentType.valve ? this.state.valveType : undefined

    return {
      componentType,
      year: this.state.year,
      valveType
    }
  }

  renderYearField() {
    return <YearSelect year={this.state.year} onYearChange={(_?: number) => this.setState({ year: _ })} />
  }

  onComponentType(componentType?: EWAComponentType) {
    const newState: Partial<State> = {
      componentType
    }

    if (componentType !== EWAComponentType.valve) {
      newState.valveType = undefined
    }

    this.setState(newState)
  }

  renderComponentTypeSelect() {
    return <ComponentTypeSelect componentType={this.state.componentType} onChangeComponentType={this.onComponentType} />
  }

  renderValveTypeSelect() {
    return <ValveTypeSelect valveType={this.state.valveType} onValveTypeChange={(_?: ValveComponentType) => this.setState({ valveType: _ })} />
  }

  render() {
    const isValve = this.state.componentType === EWAComponentType.valve;

    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item xs={12}>{this.renderYearField()}</Grid>
      {isValve && <>
        <Grid item sm={6} xs={12}>{this.renderComponentTypeSelect()}</Grid>
        <Grid item sm={6} xs={12}>{this.renderValveTypeSelect()}</Grid>
      </>}
      {!isValve && <Grid item xs={12}>{this.renderComponentTypeSelect()}</Grid>}
    </Grid>;
  }
}

export default withTranslation('translation', { withRef: true })(ComponentTaskOptionsForm)
