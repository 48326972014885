import { EWAComponentType } from "../../data/EWAComponentType";
import { SimulationNewResultEventArgs } from "../../events/events/SimulationNewResultEvent";
import { EWAResultType } from "../../views/model/simulation/EWAResultType";
import { compare, comparisonModeToHumanString } from "../../utils/ComparisonMode";
import PerformanceIndicatorTask from "./base/PerformanceIndicatorTask"
import PerformanceIndicatorTaskOptions, { IPerformanceIndicatorTaskOptions } from "./options/PerformanceIndicatorTaskOptions";
import i18n from "../../i18n";

export default class PIUnsatisfiedNodesRelativeCountTask extends PerformanceIndicatorTask {
    protected _type: string = "PIUnsatisfiedNodesRelativeCountTask";
    protected performanceIndicatorName: string = EWAResultType.UnsatisfiedNodes;

    constructor(options?: PerformanceIndicatorTaskOptions) {
        if (options === undefined) {
            options = new PerformanceIndicatorTaskOptions()
        }

        // ensures the threshold is always defined and between 0 and 1
        if (options.threshold === undefined || options.threshold < 0) {
            options.threshold = 0
        } else if (options.threshold > 1) {
            options.threshold = 1
        }

        super(options);
    }

    protected passesTest(e: SimulationNewResultEventArgs): boolean {
        const result = e.lastResult
        if (result === undefined) return false

        const model = e.model
        const featureCounts = model.featureCounts
        const options = this._options as PerformanceIndicatorTaskOptions;

        // can never pass if no threshold is set for this task - but will be set in constructor to 0 anyway
        if (options.threshold === undefined) return false

        const timedResults = result.timed_results
        if (timedResults === undefined) return false

        let availableYears = Object.keys(timedResults)
        if (options.year !== undefined) {
            availableYears = availableYears.filter(y => y === options.year!.toString())
        }

        let validOperatingConditionsIds: string[] = []
        if (options.operatingConditionType !== undefined) {
            if (e.model === undefined) return false // we need the model to get a list of operating conditions

            validOperatingConditionsIds = e.model.conditions.filter(condition => condition.id !== undefined && condition.type === options.operatingConditionType)
                .map(condition => condition.id!)
        }

        for (const year of availableYears) {
            let singleResults = timedResults[year].filter(item => item.name === this.performanceIndicatorName)
            const junctionCount = featureCounts[Number.parseInt(year)][EWAComponentType.junction]
            if (singleResults.length === 0) continue

            // filter to relevant scenarios if option is set
            if (options.scenario !== undefined) {
                singleResults = singleResults.filter(item => item.scenario === options.scenario)
            }

            // filter to relevant operating condition
            if (options.operatingConditionType !== undefined) {
                singleResults = singleResults.filter(item => item.operating_condition !== undefined && validOperatingConditionsIds.includes(item.operating_condition))
            }

            // check every SingleResult
            for (const singleResult of singleResults) {
                if (singleResult.value === undefined) continue

                const ratio = (singleResult.value / junctionCount)
                const comparison = compare<number>(options.comparisonMode, ratio, options.threshold)
                if (!comparison) {
                    return false
                }
            }
        }

        return true
    }

    public static fromPlainTaskOptions(plainOptions: IPerformanceIndicatorTaskOptions) {
        const options = new PerformanceIndicatorTaskOptions(plainOptions);
        return new PIUnsatisfiedNodesRelativeCountTask(options)
    }

    public get description(): string {
        const options = this._options as PerformanceIndicatorTaskOptions;
        const threshold = ((options.threshold!) * 100).toFixed(1)
        return i18n.t('challenge.task.pi_unsatisfied_nodes_relative_count', {
            comparisonString: comparisonModeToHumanString(options.comparisonMode),
            threshold: threshold
        })
    }
}
