import { StateType } from "../api/client"

export const unfinishedStateTypes = [StateType.PENDING, StateType.RUNNING, StateType.SCHEDULED]
export const finishedStateTypes = [StateType.COMPLETED, StateType.CANCELLED, StateType.FAILED]

export function isStateFinished(state: StateType) {
    return finishedStateTypes.includes(state)
}

export function isStateUnfinished(state: StateType) {
    return unfinishedStateTypes.includes(state)
}
