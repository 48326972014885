import {
    useCallback,
    useEffect,
    useState,
    useMemo
} from "react";
import {
    EWAModelDTO,
    EWAModelType,
    ModellingService,
} from "@/api/client";
import {
    Box,
    Button,
    TextField,
    Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHref } from 'react-router-dom';
import { Delete, Download, Launch, Save, Upload } from "@mui/icons-material";
import ChallengeUploadDialog from "@/challenges/components/home/ChallengeUploadDialog";
import ChallengeDeleteDialog from "@/challenges/components/home/ChallengeDeleteDialog";


export interface CompletedModelFormProps {
    model: EWAModelDTO
    setModel: (_: EWAModelDTO) => void
    setSelection: (_?: string) => void
    fetchModels: () => void
    accessibleModels: Set<string>
    challengeToModelName: Map<string, string>
}

export function CompletedModelForm(props: CompletedModelFormProps) {
    const { model, setModel, accessibleModels, challengeToModelName } = props;
    const [name, setName] = useState<string>(model.name);
    const [challengeUploadOpen, setChallengeUploadOpen] = useState<boolean>(false);
    const [challengeDeleteOpen, setChallengeDeleteOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [description, setDescription] = useState<string>(model.description);
    const isAccesible = useMemo<boolean>(() => {
        return (accessibleModels.has(model.id) ?? true)
    }, [accessibleModels])

    useEffect(() => {
        setName(model.name);
        setDescription(model.description);
    }, [model, setName, setDescription]);

    const handleDelete = useCallback(async () => {
        await ModellingService.deleteModelV1ModelsModelIdDelete(model.id);
        props.setSelection(undefined)
        props.fetchModels()
    }, [model.id]);

    const canModifyChallenge = useMemo<boolean>(() => {
        return !(model.shared || model.type === EWAModelType.USER)
    }, [model])

    function handleSave() {
        const ewaModel = {
            id: model.id,
            name: name,
            description: description,
            tags: model.tags,
            state: model.state,
            flow_run_id: model.flow_run_id,
            type: model.type
        } as EWAModelDTO
        ModellingService.updateModelV1ModelsModelIdPut(ewaModel.id, ewaModel).then().catch(e => {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        })
        setModel(ewaModel)
        props.fetchModels()
    }

    function handleDownload() {
        ModellingService.modelV1ModelsModelIdGet(model.id, undefined, true).then(m => {
            const str = JSON.stringify(m.network);
            const bytes = new TextEncoder().encode(str);
            let blob = new Blob([bytes], { type: 'application/json;charset=utf-8' });
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = m.name + ".ewa";
            a.click();
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 0)

        }).catch(e => {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        })

    }

    function openButtonTooltip() {
        if (isAccesible) {
            return undefined
        }

        const items: JSX.Element[] = []
        for (const requiredChallenge of (model.challenge?.required_challenges ?? [])) {
            const modelName = challengeToModelName.get(requiredChallenge)
            if (modelName === undefined) continue

            items.push(<li key={requiredChallenge}>{modelName}</li>)
        }

        return <>
            <p>{t('home.model_inaccessible')}</p>
            <ul>
                {items}
            </ul>
        </>
    }

    function renderChallengeFields() {
        if (!canModifyChallenge) return

        return <>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                    variant="contained"
                    onClick={() => setChallengeUploadOpen(true)}
                    startIcon={<Upload />}
                >
                    {t('challenge.home.upload_challenge.title')}
                </Button>
                {model.challenge && <Button
                    variant="contained"
                    onClick={() => setChallengeDeleteOpen(true)}
                    startIcon={<Delete />}
                    color="error"
                >{t('challenge.home.delete_challenge.title')}</Button>}
            </Box>
        </>
    }

    return (
        <div style={{
            padding: "30px", width: "100%", height: "100%",
            display: "flex", flexDirection: "column"
        }}>
            <div style={{ flexGrow: 1 }}>
                <TextField
                    disabled={model.shared}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    style={{ flexGrow: 1 }}
                    label={t('general.name')}
                    fullWidth
                />
                <TextField
                    disabled={model.shared}
                    rows={6}
                    multiline
                    margin="normal"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    label={t('general.description')}
                    fullWidth
                />
                {renderChallengeFields()}
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        variant="contained"
                        color="error"
                        type="submit"
                        onClick={handleDelete}
                        startIcon={<Delete />}
                    >
                        {t('general.delete')}
                    </Button>
                </div>
                <div style={{ flexGrow: 0, display: 'flex', gap: '15px' }}>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleDownload}
                        startIcon={<Download />}
                    >
                        {t('general.download')}
                    </Button>
                    {!model.shared && <>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!(model.name !== name || model.description !== description)}
                            onClick={handleSave}
                            startIcon={<Save />}
                        >
                            {t('general.save')}
                        </Button>
                    </>}
                    <Tooltip title={openButtonTooltip()}>
                        <div>
                            <Button
                                variant="contained"
                                color="success"
                                type="submit"
                                startIcon={<Launch />}
                                href={useHref(`/tool/model/${model.id}`)}
                                disabled={!isAccesible}
                            >
                                {t('general.open')}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <ChallengeUploadDialog
                open={challengeUploadOpen}
                onClose={() => setChallengeUploadOpen(false)}
                model={model}
                setModel={setModel}
            />
            <ChallengeDeleteDialog
                open={challengeDeleteOpen}
                onClose={() => setChallengeDeleteOpen(false)}
                model={model}
                setModel={setModel}
            />
        </div>)
}
