import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { commentMaxLength } from "../../utils/constants"

export interface SaveModelDialogProps {
    open: boolean
    onClose: () => void
    onSave: (_?: string) => void
}

export default function SaveModelDialog(props: SaveModelDialogProps) {
    const {
        open,
        onClose,
        onSave
    } = props

    const { t } = useTranslation()
    const [comment, setComment] = useState<string>('')
    const [invalid, setInvalid] = useState<boolean>(false)

    useEffect(() => {
        setComment('')
        setInvalid(false)
    }, [open])

    function submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        if (invalid) return

        onSave(comment)
        onClose()
    }

    function onCommentChange(value: string) {
        setInvalid(value.length > commentMaxLength)
        setComment(value)
    }

    return <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => onClose()}
    >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {t('model.controls.save_model')}
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <form onSubmit={(e) => submit(e)}>
                <TextField
                    margin="normal"
                    label={t('general.comment')}
                    fullWidth
                    multiline
                    rows={5}
                    inputProps={{ maxLength: commentMaxLength }}
                    onChange={(e) => onCommentChange(e.target.value)}
                    helperText={`${comment.length}/${commentMaxLength}`}
                    error={invalid}
                />
                <Button type="submit" disabled={invalid} variant="contained" color="primary">{t('general.save')}</Button>
            </form>
        </DialogContent>
    </Dialog>
}

