import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import TaskOptions, { ITaskOptions } from "./options/TaskOptions";
import i18n from "../../i18n";
import SimulationNewResultEvent from "../../events/events/SimulationNewResultEvent";

export default class WaitForSimulationTask extends Task {
  protected _type: string = 'WaitForSimulationTask';
  public constructor(options?: TaskOptions) {
    super(options);

    this.onSimulationNewResult = this.onSimulationNewResult.bind(this);

  }

  public static fromPlainTaskOptions(plainOptions: ITaskOptions) {
    const options = new TaskOptions(plainOptions);
    return new WaitForSimulationTask(options);
  }

  protected onSimulationNewResult(e: SimulationNewResultEvent) {
    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    return i18n.t('challenge.task.wait_for_simulation');
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.SimulationNewResult, this.onSimulationNewResult);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.SimulationNewResult, this.onSimulationNewResult);
  }
}
