import { Login, OpenInNew } from '@mui/icons-material';
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router';

export interface HowToProps {

}

export default function HowTo(props: HowToProps) {
    const { t } = useTranslation('landing')
    return <>
        <Typography variant='h3'>
            {t('sections.howto.header')}
        </Typography>
        <p>{t('sections.howto.pre_instructions')}</p>
        <ol>
            <li>{t('sections.howto.instructions.create_account')}<br/><Button size='small' startIcon={<OpenInNew />} variant='contained' href='https://tugnet.tugraz.at/dienste/connect/' target='_blank'> {t('sections.howto.instructions.create_account_guide')}</Button></li>
            <li>{t('sections.howto.instructions.login')}<br/><Button size='small' startIcon={<Login />} href={useHref('tool')} variant='contained'>Login</Button></li>
            <li>{t('sections.howto.instructions.play')}</li>
        </ol>
    </>
}
