import { OrthoLayer } from "../../../data/OrthoLayer"
import Challenge from "../../Challenge"
import UpdateTaskType from "../../misc/UpdateTaskType"
import TaskGroup from "../../TaskGroup"
import ChangeOrthoLayerTask from "../../tasks/ChangeOrthoLayerTask"
import ChangeOrthoLayerTaskOptions from "../../tasks/options/ChangeOrthoLayerTaskOptions"

export default function ChangeOrthoLayerTasks(): Challenge {
  const taskGroups = [
    new TaskGroup([
      new ChangeOrthoLayerTask(),
      new ChangeOrthoLayerTask(new ChangeOrthoLayerTaskOptions({
        orthoLayer: OrthoLayer.OSM,
        updateTaskType: UpdateTaskType.ExactValue
      })),
      new ChangeOrthoLayerTask(new ChangeOrthoLayerTaskOptions({
        orthoLayer: OrthoLayer.BasemapGrau,
        updateTaskType: UpdateTaskType.ExactValue
      })),
      new ChangeOrthoLayerTask(new ChangeOrthoLayerTaskOptions({
        orthoLayer: OrthoLayer.BasemapOrtho,
        updateTaskType: UpdateTaskType.ExactValue
      }))
    ])
  ]

  return new Challenge(taskGroups)
}
