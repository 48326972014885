import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { TankApply } from "../../views/forms/TankApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class TankUpdateElevationTask extends NumberValueUpdateTask<TankApply> {
  protected _type: string = 'TankUpdateElevationTask';
  protected componentName: string = "ewa_component_type.Tank_one";
  protected fieldName: string = "general.elevation";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new TankUpdateElevationTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.TankApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.TankApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<TankApply>): ValuesPair<number> {
    return {
      newValue: args.apply.elevation,
      oldValue: args.oldApply.elevation
    };
  }
}
