import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import SaveModelTask from "../../tasks/SaveModelTask";
import StartSimulationTask from "../../tasks/StartSimulationTask";

export default function RunSimulationSaveModelTasks(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new SaveModelTask(),
      new StartSimulationTask()
    ])
  ];

  return new Challenge(taskGroups);
}
