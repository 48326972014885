import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "../../i18n";
import { OrthoLayer } from "../../data/OrthoLayer";

export interface ConfigurationState {
    language: string
    saveWithComment: boolean
    defaultOrthoLayer: OrthoLayer
}

const storageKey = 'ewa/configuration'

function saveToLocalStorage(state: ConfigurationState) {
    const serialized = JSON.stringify(state)

    window.localStorage.setItem(storageKey, serialized)
}

function loadFromLocalStorage(): ConfigurationState {
    const defaultState: ConfigurationState = {
        language: 'en',
        saveWithComment: false,
        defaultOrthoLayer: OrthoLayer.BasemapGrau
    }

    const serialized = window.localStorage.getItem(storageKey)

    if (serialized === null) {
        return defaultState
    }

    const deserialized = JSON.parse(serialized)

    const state = {
        ...defaultState,
        ...deserialized,
    } as ConfigurationState

    return state
}

const initialState = loadFromLocalStorage()

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: initialState,
    reducers: {
        saveConfiguration(state, action: Action) {
            saveToLocalStorage(state)
        },
        loadConfiguration(state, action: Action) {
            Object.assign(state, loadFromLocalStorage())
            i18n.changeLanguage(state.language)
        },
        setSaveWithComment(state, action: PayloadAction<boolean>) {
            state.saveWithComment = action.payload
        },
        setLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload
            i18n.changeLanguage(state.language)

            saveToLocalStorage(state)
        },
        setDefaultOrthoLayer(state, action: PayloadAction<OrthoLayer>) {
            state.defaultOrthoLayer = action.payload
        }
    }
})

export const {
    saveConfiguration,
    loadConfiguration,
    setSaveWithComment,
    setLanguage,
    setDefaultOrthoLayer
} = configurationSlice.actions

export default configurationSlice.reducer
