import { useTranslation } from "react-i18next";
import { EWASimulationDTO, ModellingService } from "../../../api/client"
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material";
import { Close } from "@mui/icons-material";

export interface SimulationDeleteDialogProps {
    ewaSimulations: EWASimulationDTO[]
    onClose: () => void
    onDeleted: () => void
    lastSimulation: EWASimulationDTO|undefined
    setLastSimulation: (_?: EWASimulationDTO) => void
}

export default function SimulationDeleteDialog(props: SimulationDeleteDialogProps) {
    const {
        ewaSimulations,
        onClose,
        onDeleted,
        lastSimulation,
        setLastSimulation,
    } = props

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false)

    async function deleteSimulations () {
        setLoading(true)

        await Promise.all(ewaSimulations.map(_ => deleteSimulation(_)))

        onDeleted()

        setLoading(false)
    }

    async function deleteSimulation(ewaSimulation: EWASimulationDTO) {
        if (lastSimulation?.id === ewaSimulation.id) {
            setLastSimulation(undefined)
        }

        try {
            await ModellingService.deleteSimulationV1SimulationsIdDelete(ewaSimulation.id)

            enqueueSnackbar(t('simulation_view.delete_simulation_success', { name: ewaSimulation.name}), {
                variant: 'success'
            })


        } catch {
            enqueueSnackbar(t('simulation_view.delete_simulation_fail', { name: ewaSimulation.name}), {
                variant: 'error'
            })
        }

    }

    return <Dialog
        open
        fullWidth
        maxWidth="sm"
        onClose={() => onClose()}
    >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{t('simulation_view.delete_simulation')}</div>
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ height: 4 }}>
                {loading && <LinearProgress></LinearProgress>}
            </Box>
            <p>{t('simulation_view.delete_simulation_hint')}</p>
            <ul>{ewaSimulations.map(_ => <li key={_.id}>{_.name}</li>)}</ul>
            <Button disabled={loading} variant="contained" color="error" onClick={() => deleteSimulations()}>{t('general.delete')}</Button>
        </DialogContent>
    </Dialog>
}
