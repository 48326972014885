import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf'
import {
    ChallengeModel,
    ConsumptionMode,
    CurveModel,
    ElevationService,
    EWAModelDTO,
    FCVConditionProps,
    FCVModel,
    FCVProps,
    FCVTimedProps,
    GPVConditionProps,
    GPVModel,
    GPVProps,
    GPVTimedProps,
    IndustrialConsumption,
    JunctionConditionProps,
    JunctionModel,
    JunctionProps,
    JunctionTimedProps,
    OperatingCondition,
    PBVConditionProps,
    PBVModel,
    PBVProps,
    PBVTimedProps,
    PipeConditionProps,
    PipeModel,
    PipeProps,
    PipeTimedProps,
    Point,
    PrivateConsumption,
    PRVConditionProps,
    PRVModel,
    PRVProps,
    PRVTimedProps,
    PSVConditionProps,
    PSVModel,
    PSVProps,
    PSVTimedProps,
    PumpConditionProps,
    PumpModel,
    PumpProps,
    PumpTimedProps,
    ReservoirConditionProps,
    ReservoirModel,
    ReservoirProps,
    ReservoirTimedProps,
    SourceConditionProps,
    SourceTimedProps,
    SpringModel,
    SpringProps,
    TankConditionProps,
    TankModel,
    TankProps,
    TankTimedProps,
    TCVConditionProps,
    TCVModel,
    TCVProps,
    TCVTimedProps,
    WellModel,
    WellProps,
    LineString as ApiLineString,
    PipeMaterial
} from "../api/client";
import EventManager from '../events/EventManager';
import { ValveComponentType } from './ValveComponentType';
import { EWAComponentType } from './EWAComponentType';
import { EWAModel as EWASerializableModel } from '../api/client/models/EWAModel';
import { ModelViewMode } from './ModelViewMode';
import { LineString } from './LineString';
import { ComponentModel, BaseFeature } from './BaseFeature';
import { LinkModel } from './BaseLinestringFeature';
import { Tank } from './Tank';
import { Pump } from './Pump';
import { Pipe } from './Pipe';
import { Reservoir } from './Reservoir';
import { Junction } from './Junction';
import { ValveTCV } from './ValveTCV';
import { ValveGPV } from './ValveGPV';
import { ValvePSV } from './ValvePSV';
import { ValveFCV } from './ValveFCV';
import { ValvePBV } from './ValvePBV';
import { ValvePRV } from './ValvePRV';
import { Spring } from './Spring';
import { Well } from './Well';
import { NodeModel } from './BasePointFeature';

export type ewaFeatureType = EWAComponentType | ValveComponentType

export const pointComponents: ewaFeatureType[] = [
    EWAComponentType.junction, EWAComponentType.tank, EWAComponentType.reservoir, EWAComponentType.spring, EWAComponentType.well
]

export class EWAModel {
    type: string = "FeatureCollection";
    id: string;
    meta?: EWAModelDTO
    features: BaseFeature<any, any, any>[] = [];
    featureCounts: Record<number, Record<EWAComponentType | ValveComponentType, number>> = {};
    viewMode: ModelViewMode;
    showComponents: EWAComponentType[]
    minDate: number
    maxDate: number
    minJunctionDemand: number
    maxJunctionDemand: number
    modelDate: number
    bbox?: Array<number>;
    challenges?: ChallengeModel
    currentDate: number
    currentCondition: string | undefined
    conditions: Array<OperatingCondition> = new Array<OperatingCondition>()
    curves: Array<CurveModel> = new Array<CurveModel>()
    public forceUpdate() {
        this.id = uuidv4()
    }

    constructor(data: any, viewMode: ModelViewMode, showComponents: EWAComponentType[], minDate: number, maxDate: number, modelDate: number, currentDate: number, currentCondition: string | undefined) {
        this.id = uuidv4()
        this.currentCondition = currentCondition;
        this.showComponents = showComponents;
        this.viewMode = viewMode;
        this.minDate = minDate;
        this.minJunctionDemand = 0;
        this.maxJunctionDemand = 0;
        this.maxDate = maxDate;
        this.modelDate = modelDate;
        this.currentDate = currentDate;

        // initialize feature counts record
        for (let i = modelDate; i <= maxDate; i += 10) {
            this.featureCounts[i] = {
                [EWAComponentType.junction]: 0,
                [EWAComponentType.spring]: 0,
                [EWAComponentType.well]: 0,
                [EWAComponentType.tank]: 0,
                [EWAComponentType.reservoir]: 0,
                [EWAComponentType.pipe]: 0,
                [EWAComponentType.pump]: 0,
                [EWAComponentType.valve]: 0,
                [ValveComponentType.fcv]: 0,
                [ValveComponentType.tcv]: 0,
                [ValveComponentType.gpv]: 0,
                [ValveComponentType.prv]: 0,
                [ValveComponentType.psv]: 0,
                [ValveComponentType.pbv]: 0,
            }
        }

        if (data instanceof EWAModel) {
            this.curves = data.curves
            this.meta = data.meta
            this.conditions = data.conditions;
            this.challenges = data.challenges;
            this.features = data.features;
            this.featureCounts = data.featureCounts;
            this.bbox = data.bbox;
            for (let i = 0; i < data.features.length; i++) {
                this.features[i].collection = this;
            }
        } else if (data.state !== undefined) {
            this.meta = {
                id: data.id,
                name: data.name,
                description: data.description,
                tags: data.tags,
                type: data.type,
                state: data.state,
                flow_run_id: data.flow_run_id,
                version: data.version
            } as EWAModelDTO

            if (data.network !== undefined) {
                if (data.network.curves != null) {
                    this.curves = data.network.curves
                }
                this.conditions = data.network.conditions;
                this.challenges = data.network.challenges;
                this.bbox = data.network.bbox;

                if (data.network.features !== undefined) {
                    for (let i = 0; i < data.network.features.length; i++) {
                        const feature = (data.network.features[i]) as ComponentModel
                        const componentType = feature.component_type
                        const lifetime = feature.lifetime
                        const visibleFrom = lifetime[0]
                        const visibleTo = lifetime[1]
                        let baseFeature: BaseFeature<any, any, any> | undefined = undefined

                        switch (componentType) {
                            case EWAComponentType.reservoir:
                                baseFeature = new Reservoir(this, feature as NodeModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.tank:
                                baseFeature = new Tank(this, feature as NodeModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.pipe:
                                baseFeature = new Pipe(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.pump:
                                baseFeature = new Pump(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.prv:
                                baseFeature = new ValvePRV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.tcv:
                                baseFeature = new ValveTCV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.gpv:
                                baseFeature = new ValveGPV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.fcv:
                                baseFeature = new ValveFCV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.psv:
                                baseFeature = new ValvePSV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case ValveComponentType.pbv:
                                baseFeature = new ValvePBV(this, feature as LinkModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.spring:
                                baseFeature = new Spring(this, feature as NodeModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.well:
                                baseFeature = new Well(this, feature as NodeModel, visibleTo, visibleFrom)
                                break
                            case EWAComponentType.junction:
                            default:
                                baseFeature = new Junction(this, feature as NodeModel, visibleTo, visibleFrom)
                                break
                        }


                        // increase feature count for each year the component exists
                        for (let i: number = feature.lifetime[0] as number; i <= (feature.lifetime[1] as number); i += 10) {
                            this.increaseFeatureCounter(i, baseFeature.componentType)
                            if (baseFeature.valveType !== undefined) {
                                this.increaseFeatureCounter(i, baseFeature.valveType)
                            }
                        }

                        this.features.push(baseFeature!)
                    }
                }
            }
        }
    }

    public updateVersion(version: string) {
        this.meta = {
            ...(this.meta ?? {}),
            version
        } as EWAModelDTO
    }

    public deleteOperatingCondition(id: string) {
        let index = this.conditions.findIndex(condition => condition.id === id)
        if (index !== -1) {
            this.conditions.splice(index, 1)
        }
        for (let i = 0; i < this.features.length; i++) {
            if (this.features[i].properties.conditional !== undefined) {
                try {
                    delete this.features[i].properties.conditional[id]
                } catch (e) {

                }
            }
        }
    }

    public increaseFeatureCounter(year: number, componentType: EWAComponentType | ValveComponentType) {
        this.featureCounts[year][componentType]++
    }

    public increaseFeatureCounterForNewComponent(component: BaseFeature<any, any, any>) {
        const lifetime = (component.data.lifetime as number[]) ?? [this.modelDate, this.maxDate]
        const isValve = component.valveType !== undefined
        for (let year: number = lifetime[0]; year <= lifetime[1]; year += 10) {
            this.increaseFeatureCounter(year, component.componentType)
            if (isValve) {
                this.increaseFeatureCounter(year, component.valveType)
            }
        }
    }

    public decreaseFeatureCounter(year: number, componentType: EWAComponentType | ValveComponentType) {
        this.featureCounts[year][componentType]--
    }

    public decreaseFeatureCounterForDeletedComponent(component: BaseFeature<any, any, any>) {
        const lifetime = (component.data.lifetime as number[]) ?? [this.modelDate, this.maxDate]
        const isValve = component.valveType !== undefined
        for (let year: number = lifetime[0]; year <= lifetime[1]; year += 10) {
            this.decreaseFeatureCounter(year, component.componentType)
            if (isValve) {
                this.decreaseFeatureCounter(year, component.valveType)
            }
        }
    }

    public getFeatureCount(componentType: EWAComponentType | ValveComponentType) {
        return this.featureCounts[this.currentDate][componentType]
    }

    public save(): EWASerializableModel {
        let model = {
            type: this.type,
            curves: this.curves,
            conditions: this.conditions,
            challenges: this.challenges,
            bbox: this.bbox,
            features: new Array<ComponentModel>()
        } as EWASerializableModel

        for (let i = 0; i < this.features.length; i++) {
           model.features?.push(this.features[i].toComponentModel())
        }

        try {
            model.bbox = turf.bbox(model);
        } catch (e) {
            console.log("Problem calculating bbox")
        }

        return model
    }
    public componentExists(id: string, type: EWAComponentType): boolean {
        if (this.features.filter(e => e.id === id && e.componentType === type).length > 0) {
            return true;
        }
        return false;
    }

    public findAnyComponent(id: string): BaseFeature<any, any, any> | undefined {
        let filtered = this.features.filter(e => e.id === id);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    }
    public findAnyComponentIndex(id: string): number {
        return this.features.findIndex(e => e.id === id);
    }

    public findComponent(id: string, type: string): BaseFeature<any, any, any> | undefined {
        let filtered = this.features.filter(e => e.id === id && e.componentType === type);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return undefined;
    }

    public findConnections(id: string): BaseFeature<any, any, any>[] {
        let filtered = this.features.filter((e: any) => (e.startnode === id || e.endnode === id));
        return filtered;
    }

    public findNewId(componentType: EWAComponentType): string {
        let baseName = componentType.toLowerCase() + "_";
        let componentId = componentType.toLowerCase() + "_0";
        //TODO Move this to a function with callback
        for (let i = 1; i < 1000000; i++) { //Hope to not add more than that ;-)
            componentId = baseName + i;
            if (!this.componentExists(componentId, componentType)) {
                break;
            }
        }
        return componentId;
    }


    public async getNewPointGeometry(feature: any, id: string): Promise<any> {
        const elevation = await ElevationService.getElevationV1GetElevationsPost({
            type: "Point",
            coordinates: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]]
        } as Point)
        return {
            type: "Point",
            coordinates: [feature.geometry.coordinates[0], feature.geometry.coordinates[1], elevation.coordinates[2], this.currentDate]
        }
    }

    public async addComponent(type: EWAComponentType | ValveComponentType, feature: any, currentDate: number): Promise<boolean | number> {
        let newIndex: boolean | number = false
        switch (type) {
            case EWAComponentType.junction:
                newIndex = await this.addJunction(feature, currentDate as number)
                break
            case EWAComponentType.spring:
                newIndex = await this.addSpring(feature, currentDate as number)
                break
            case EWAComponentType.well:
                newIndex = await this.addWell(feature, currentDate as number)
                break
            case EWAComponentType.tank:
                newIndex = await this.addTank(feature, currentDate as number)
                break
            case EWAComponentType.reservoir:
                newIndex = await this.addReservoir(feature, currentDate as number)
                break
            case EWAComponentType.pipe:
                newIndex = this.addPipe(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case EWAComponentType.pump:
                newIndex = this.addPump(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.fcv:
                newIndex = this.addFCV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.tcv:
                newIndex = this.addTCV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.gpv:
                newIndex = this.addGPV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.prv:
                newIndex = this.addPRV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.psv:
                newIndex = this.addPSV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
            case ValveComponentType.pbv:
                newIndex = this.addPBV(feature.geometry.properties.startNode,
                    feature.geometry.properties.endNode, feature.geometry.properties.startNodeType,
                    feature.geometry.properties.endNodeType, currentDate as number);
                break
        }

        return newIndex
    }

    public async addSpring(feature: any, currentDate: number): Promise<number> {
        let springId = this.findNewId(EWAComponentType.spring);
        let newFeature = await this.getNewPointGeometry(feature, springId);
        let conditional: Record<string, SourceConditionProps> = {};

        conditional[this.currentCondition!] = {} as SourceConditionProps

        let timed: Record<string, SourceTimedProps> = {};

        timed[this.currentDate!.toString()] = {
            inflow: 0
        } as SourceTimedProps

        let newSpring = {
            id: springId,
            type: "Feature",
            component_type: EWAComponentType.spring,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                strength: 0
            } as SpringProps,
            geometry: newFeature as Point
        } as SpringModel

        let spring = new Spring(this, newSpring, this.maxDate, currentDate);

        this.features.push(spring)

        EventManager.getInstance().onComponentCreated({
            id: springId,
            type: EWAComponentType.spring,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(spring);

        return this.features.length - 1;
    }

    public async addWell(feature: any, currentDate: number): Promise<number> {
        let wellId = this.findNewId(EWAComponentType.well);
        let newFeature = await this.getNewPointGeometry(feature, wellId);
        let conditional: Record<string, SourceConditionProps> = {};

        conditional[this.currentCondition!] = {} as SourceConditionProps

        let timed: Record<string, SourceTimedProps> = {};

        timed[this.currentDate!.toString()] = {
            inflow: 0
        } as SourceTimedProps

        let newWell = {
            id: wellId,
            type: "Feature",
            component_type: EWAComponentType.well,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                strength: 0
            } as WellProps,
            geometry: newFeature as Point
        } as WellModel

        let well = new Well(this, newWell, this.maxDate, currentDate);

        this.features.push(well)

        EventManager.getInstance().onComponentCreated({
            id: wellId,
            type: EWAComponentType.well,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(well);

        return this.features.length - 1;
    }

    public async addJunction(feature: any, currentDate: number): Promise<number> {
        let junctionId = this.findNewId(EWAComponentType.junction);
        let newFeature = await this.getNewPointGeometry(feature, junctionId);
        let conditional: Record<string, JunctionConditionProps> = {};

        conditional[this.currentCondition!] = {
            fireflow: 0
        } as JunctionConditionProps

        let timed: Record<string, JunctionTimedProps> = {};

        timed[this.currentDate!.toString()] = {
            demand: 0,
            emittercoefficient: 0,
            scale_demand: true,
            mode: ConsumptionMode.CLASSIC,
            customers: new Array<PrivateConsumption | IndustrialConsumption>()
        } as JunctionTimedProps

        let newJunction = {
            id: junctionId,
            type: "Feature",
            component_type: EWAComponentType.junction,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                strength: 0
            } as JunctionProps,
            geometry: newFeature as Point
        } as JunctionModel
        let junction = new Junction(this, newJunction, this.maxDate, currentDate);
        this.features.push(junction)

        EventManager.getInstance().onComponentCreated({
            id: junctionId,
            type: EWAComponentType.junction,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(junction);

        return this.features.length - 1;
    }

    public async addTank(feature: any, currentDate: number): Promise<number> {
        let tankId = this.findNewId(EWAComponentType.tank);
        let newFeature = await this.getNewPointGeometry(feature, tankId);
        let conditional: Record<string, TankConditionProps> = {

        };
        conditional[this.currentCondition!] = {

        } as TankConditionProps
        let timed: Record<string, TankTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            initlevel: 1,
            diameter: 1
        } as TankTimedProps
        let newTank = {
            id: tankId,
            type: "Feature",
            component_type: EWAComponentType.tank,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                strength: 0
            } as TankProps,
            geometry: newFeature as Point
        } as TankModel

        let tank = new Tank(this, newTank, this.maxDate, currentDate);
        this.features.push(tank)

        EventManager.getInstance().onComponentCreated({
            id: tankId,
            type: EWAComponentType.tank,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(tank);
        return this.features.length - 1;
    }

    public async addReservoir(feature: any, currentDate: number): Promise<number> {
        let reservoirId = this.findNewId(EWAComponentType.reservoir);
        let newFeature = await this.getNewPointGeometry(feature, reservoirId);
        let conditional: Record<string, ReservoirConditionProps> = {

        };
        conditional[this.currentCondition!] = {

        } as ReservoirConditionProps
        let timed: Record<string, ReservoirTimedProps> = {

        };

        timed[this.currentDate!.toString()] = {
            head: ((newFeature as Point).coordinates[2]) + 1
        } as ReservoirTimedProps
        let newReservoir = {
            id: reservoirId,
            type: "Feature",
            component_type: EWAComponentType.reservoir,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                strength: 0
            } as ReservoirProps,
            geometry: newFeature as Point
        } as ReservoirModel
        let reservoir = new Reservoir(this, newReservoir, this.maxDate, currentDate);

        EventManager.getInstance().onComponentCreated({
            id: reservoirId,
            type: EWAComponentType.reservoir,
            year: this.currentDate!,
            model: this
        });

        this.features.push(reservoir)

        this.increaseFeatureCounterForNewComponent(reservoir);
        return this.features.length - 1;
    }

    public line_length(points: number[][]) {
        let length = 0;
        let options = { units: "kilometers" };
        for (let i = 0; i < points.length - 1; i++) {
            let from = turf.point([points[i][0], points[i][1], points[i][2]!]);
            let to = turf.point([points[i + 1][0], points[i + 1][1], points[i + 1][2]!]);
            let distance = turf.distance(from, to, options as any);
            length = length + distance;
        }
        return length;
    }

    public getNewLineGeometry(startNode: string, endNode: string, startNodeType: string, endNodeType: string): ApiLineString {
        let startFeature = this.findComponent(startNode, startNodeType);
        let endFeature = this.findComponent(endNode, endNodeType); //TODO Add timed coordinates
        let sc = startFeature?.geometry.realCoordinates;
        let ec = endFeature?.geometry.realCoordinates;

        return {
            type: "LineString",
            coordinates: [
                [sc[0], sc[1], sc[2]],
                [ec[0], ec[1], ec[2]]
            ]
        }

    }
    public getLineGeometry(startNode: string, endNode: string, startNodeType: string, endNodeType: string) {
        let startFeature = this.findComponent(startNode, startNodeType);
        let endFeature = this.findComponent(endNode, endNodeType); //TODO Add timed coordinates
        let sc = startFeature?.geometry.realCoordinates;
        let ec = endFeature?.geometry.realCoordinates;
        let data = {
            "geometry": {
                "coordinates": [
                    [sc[0], sc[1], sc[2]],
                    [ec[0], ec[1], ec[2]]
                ]
            }
        }
        let geometry = new LineString(data);
        return geometry;
    }

    public addPipe(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let pipeId = this.findNewId(EWAComponentType.pipe);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, PipeConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as PipeConditionProps

        let timed: Record<string, PipeTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 250,
            minorloss: 0,
            roughness: 0.1,
            construction_year: currentDate,
            material: PipeMaterial.U
        } as PipeTimedProps

        let newPipe = {
            id: pipeId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: EWAComponentType.pipe,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: '',
                length: this.line_length(geometry.coordinates as number[][]) * 1000
            } as PipeProps,
            geometry: geometry
        } as PipeModel

        let pipe = new Pipe(this, newPipe, this.maxDate, currentDate);
        this.features.push(pipe)
        pipe.updateNodes();

        EventManager.getInstance().onComponentCreated({
            id: pipeId,
            type: EWAComponentType.pipe,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(pipe);

        return this.features.length - 1;
    }


    public addPump(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let pumpId = this.findNewId(EWAComponentType.pump);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, PumpConditionProps> = {

        };

        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as PumpConditionProps

        let timed: Record<string, PumpTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            power: undefined,
            head_curve: '',
            speed: 1.0,
            pattern: '',
            setting: undefined
        } as PumpTimedProps

        let newPump = {
            id: pumpId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: EWAComponentType.pump,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: '',
            } as PumpProps,
            geometry: geometry
        } as PumpModel

        let pump = new Pump(this, newPump, this.maxDate, currentDate);
        this.features.push(pump)

        EventManager.getInstance().onComponentCreated({
            id: pumpId,
            type: EWAComponentType.pump,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(pump);

        return this.features.length - 1;
    }

    public addPRV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, PRVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as PRVConditionProps

        let timed: Record<string, PRVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            maximum_pressure: 20,
        } as PRVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.prv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as PRVProps,
            geometry: geometry
        } as PRVModel

        let valve = new ValvePRV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.prv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public addPBV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, PBVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as PBVConditionProps

        let timed: Record<string, PBVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            pressure_drop: 20,
        } as PBVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.pbv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as PBVProps,
            geometry: geometry
        } as PBVModel

        let valve = new ValvePBV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.pbv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public addFCV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, FCVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as FCVConditionProps

        let timed: Record<string, FCVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            maximum_flow: 20,
        } as FCVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.fcv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as FCVProps,
            geometry: geometry
        } as FCVModel

        let valve = new ValveFCV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.fcv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public addPSV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, PSVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as PSVConditionProps

        let timed: Record<string, PSVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            pressure_limit: 20,
        } as PSVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.psv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as PSVProps,
            geometry: geometry
        } as PSVModel

        let valve = new ValvePSV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.psv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public addGPV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, GPVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as GPVConditionProps

        let timed: Record<string, GPVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            headloss_curve: "",
        } as GPVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.gpv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as GPVProps,
            geometry: geometry
        } as GPVModel

        let valve = new ValveGPV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.gpv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public addTCV(startNode: string, endNode: string, startNodeType: string, endNodeType: string, currentDate: number) {
        let valveId = this.findNewId(EWAComponentType.valve);
        let geometry = this.getNewLineGeometry(startNode, endNode, startNodeType, endNodeType)
        let conditional: Record<string, TCVConditionProps> = {

        };
        conditional[this.currentCondition!] = {
            status: "OPEN"
        } as TCVConditionProps

        let timed: Record<string, TCVTimedProps> = {

        };
        timed[this.currentDate!.toString()] = {
            diameter: 200,
            minorloss: 0,
            headloss_coefficient: 0,
        } as TCVTimedProps

        let newValve = {
            id: valveId,
            startnode: startNode,
            endnode: endNode,
            type: "Feature",
            component_type: ValveComponentType.tcv,
            lifetime: [this.currentDate, this.maxDate],
            properties: {
                conditional: conditional,
                timed: timed,
                comment: ''
            } as TCVProps,
            geometry: geometry
        } as TCVModel

        let valve = new ValveTCV(this, newValve, this.maxDate, currentDate);
        this.features.push(valve)

        EventManager.getInstance().onComponentCreated({
            id: valveId,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.tcv,
            year: this.currentDate!,
            model: this
        });

        this.increaseFeatureCounterForNewComponent(valve);

        return this.features.length - 1;
    }

    public getVisibleFeaturesOfType (componentType: EWAComponentType, minDate: number = this.modelDate) {
        return this.features.filter(_ => _.componentType === componentType && _.visibleFrom <= minDate)
    }
}
