import { Box, Button, Card, CardContent, CardHeader, Divider, Tab, Tabs } from "@mui/material";
import { useState, useRef, useEffect, useMemo } from "react"
import { ITaskGroupOptions } from "../../../TaskGroupOptions";
import TaskGroupOptionForm, { TaskGroupOptionFormState } from "./TaskGroupOptionForm";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TaskGroupDescriptionDialog from "./TaskGroupDescriptionDialog";
import TaskForm from "../task/TaskForm";
import { useTranslation } from "react-i18next";
import { LocalizableText } from "../../../LocalizableText";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { addTaskGroup, changeActiveTaskGroup, removeTaskGroup, setTaskGroupOptionsDirty, updateTaskGroupOptions } from "../../../../store/slices/challengeEditorSlice";
import ConfirmationDialog from "../../../../widgets/ConfirmationDialog";

export interface TaskGroupFormProps {

}

export default function TaskGroupForm(props: TaskGroupFormProps) {
  const [descriptionEditOpen, setDescriptionEditOpen] = useState<boolean>(false)

  const taskOptionsRef = useRef<any>()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const locale = useSelector((state: RootState) => state.challengeEditor.locale)
  const taskGroupIndex = useSelector((state: RootState) => state.challengeEditor.taskGroupIndex)
  const taskGroups = useSelector((state: RootState) => state.challengeEditor.taskGroups)

  const taskGroupIsDirty = useSelector((state: RootState) => state.challengeEditor.taskGroupOptionsDirty)
  const setTaskGroupIsDirty = (isDirty: boolean) => dispatch(setTaskGroupOptionsDirty(isDirty))
  const taskIsDirty = useSelector((state: RootState) => state.challengeEditor.taskOptionsDirty)

  const [tabChangeConfirmationCallback, setTabChangeConfirmationCallback] = useState<(() => void)|undefined>(undefined)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

  const taskGroup = useMemo(() => {
    return taskGroups[taskGroupIndex]
  }, [taskGroups, taskGroupIndex])

  const tabs = useMemo(() => {
    const tabs: JSX.Element[] = []

    for (let i = 0; i < taskGroups.length; i++) {
      tabs.push(<Tab key={i} label={`${i + 1}`} value={i} />)
    }

    return tabs
  }, [taskGroups])

  useEffect(() => {
    setDescriptionEditOpen(false)
  }, [taskGroup])

  function extractOptionsFormState(): Partial<ITaskGroupOptions> {
    const options = taskGroup.options

    const optionsFormState = taskOptionsRef.current.state as TaskGroupOptionFormState

    const name: LocalizableText = {
      ...options.name
    }

    if (optionsFormState.name === '') {
      delete name[locale]
    } else {
      name[locale] = optionsFormState.name
    }

    return {
      name,
      autoShowDescription: optionsFormState.autoShowDescription
    }
  }

  function onTabChange(tab: number) {
    if (taskGroupIsDirty || taskIsDirty) {
      const cb = () => { dispatch(changeActiveTaskGroup(tab)) }
      setTabChangeConfirmationCallback(_ => cb)
    } else {
      dispatch(changeActiveTaskGroup(tab))
    }
  }

  function handleSave() {
    if (taskOptionsRef.current === null) return

    const options: ITaskGroupOptions = {
      ...taskGroup.options,
      ...extractOptionsFormState()
    }

    dispatch(updateTaskGroupOptions(options))
  }

  function renderHeaderAction() {
    return <Box sx={{ gap: 1, display: 'flex'}}>
      <Button variant="contained" disabled={!taskGroupIsDirty} onClick={handleSave} color="success"><SaveIcon /></Button>
      <Button variant="contained" onClick={() => setConfirmDeleteOpen(true)} color="error"><DeleteIcon /></Button>
    </Box>
  }

  function renderTaskGroup() {
    if (taskGroup === undefined) return <></>

    return <>
      <Card variant="outlined">
        <CardHeader action={renderHeaderAction()} />
        <CardContent>
          <TaskGroupOptionForm
            locale={locale}
            ref={taskOptionsRef}
            options={taskGroup.options}
            onOptionsChange={(_: ITaskGroupOptions) => dispatch(updateTaskGroupOptions(_))}
            onEditDescription={() => setDescriptionEditOpen(true)}
            setIsDirty={setTaskGroupIsDirty}
            taskGroupIndex={taskGroupIndex}
          />
          <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
          <TaskForm />
        </CardContent>
      </Card>
      {descriptionEditOpen && <TaskGroupDescriptionDialog
        taskGroupOptions={taskGroup.options}
        onOptionsChange={(_: ITaskGroupOptions) => dispatch(updateTaskGroupOptions(_))}
        onClose={() => setDescriptionEditOpen(false)}
      />}
    </>
  }

  return <Card variant="outlined">
    <CardHeader
      title={t('challenge.editor.task_groups.title') as string}
      action={<Button
        variant="contained"
        onClick={() => dispatch(addTaskGroup())}
      >
        <AddIcon />
      </Button>}
    />
    {taskGroups.length > 0 && <CardContent>
      <Tabs
        value={taskGroupIndex}
        onChange={(e, value) => onTabChange(value)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs}
      </Tabs>
      {tabChangeConfirmationCallback !== undefined && <ConfirmationDialog
        onClose={() => {
          setTabChangeConfirmationCallback(undefined)
        }}
        onConfirm={tabChangeConfirmationCallback}
        text={t('challenge.editor.confirm_task_group_tab_change') as string}
      />}
      {confirmDeleteOpen && <ConfirmationDialog
        onClose={() => setConfirmDeleteOpen(false)}
        onConfirm={() => dispatch(removeTaskGroup())}
        text={t('challenge.editor.confirm_delete_task_group') as string}
      />}
      {renderTaskGroup()}
    </CardContent>}
  </Card>
}
