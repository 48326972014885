import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

export interface PublicationsProps {

}

export default function Publications(props: PublicationsProps) {
    const { t } = useTranslation('landing')
    return <>
        <Typography variant='h3' sx={{ marginBottom: 5 }}>
            {t('sections.publications.header')}
        </Typography>
        <Paper sx={{ padding: 5, borderRadius: 3 }}>
            <Typography variant='h5'>2023</Typography>
            <p>
                Fuchs-Hanusch, D., Arbesser-Rastburg, G., Adler, V., Stelzl, A., Camhy, D., & Pirker, J. (2023). Gamification of Hydraulic Modeling to Create Awareness for the Effects of Climate Change and Urbanization on Water Supply. Abstract from EGU General Assembly 2023, Vienna, Austria. <a href="https://doi.org/10.5194/egusphere-egu23-16427">https://doi.org/10.5194/egusphere-egu23-16427</a>
            </p>
            <p>
                Stelzl, A., & Fuchs-Hanusch, D. (2023). Predicting future water demand in Austria due to climate change and demographic development. Abstract from EGU General Assembly 2023, Vienna, Austria. <a href="https://doi.org/10.5194/egusphere-egu23-14124">https://doi.org/10.5194/egusphere-egu23-14124</a>
            </p>
            <p>
                Stelzl, A., Adler, V. J., Arbesser-Rastburg, G., Camhy, D., Pirker, J., & Fuchs-Hanusch, D. (2023). EWA - Ein Serious Game für die Wasserversorgung. Talk at ÖVGW Kongress und Fachmesse Gas Wasser 2023, Innsbruck, Austria.
            </p>
            <p>
                Stelzl, A., Adler, V. J., Arbesser-Rastburg, G., Camhy, D., Pirker, J., & Fuchs-Hanusch, D. (2023). Development of a web-based serious game to create awareness for the impact of climate change and demographic development on water supply systems in Austria. Talk at Water Innovation and Circularity Conference, Athen, Greece.
            </p>
            <Typography variant='h5'>2022</Typography>
            <p>
                Stelzl, A., & Fuchs-Hanusch, D. (2022). Estimating the impact of climate indices and demographic development on future peak water demand in Austria. Talk at World Environmental & Water Resources Congress 2022: EWRI 2022, Atlanta, United States.
            </p>
            <Typography variant='h5'>2021</Typography>
            <p>
                Stelzl, A., Pointl, M. K., & Fuchs-Hanusch, D. (2021). Estimating Future Peak Water Demand with a Regression Model Considering Climate Indices. Water (Switzerland), 13(14), [1912]. <a href="https://doi.org/10.3390/w13141912">https://doi.org/10.3390/w13141912</a>
            </p>
            <p>
                Stelzl, A. (2021). Modeling the effects of climate change on daily water consumption in Austria. Talk at World Environmental and Water Resources Congress 2021, Virtual, United States.
            </p>
        </Paper>
    </>
}
