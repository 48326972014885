import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    selected: {
        "&&": {
            backgroundColor: "#2277B1 !important",
        },
    },
    toolBarContent: {
        display: "flex",
        padding: "0 10px"
    },
    mainContainer: {
        position: "relative",
        flexGrow: 1,
        display: "flex",
    },
    mainContent: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    }
}));
