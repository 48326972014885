import { EWAComponentType } from "../../data/EWAComponentType";
import EventType from "../EventType";


export interface ComponentVisibilityChangedEventArgs {
  componentType: EWAComponentType
  visible: boolean
}

export default class ComponentVisibilityChangedEvent extends CustomEvent<ComponentVisibilityChangedEventArgs> {
  public constructor(detail: ComponentVisibilityChangedEventArgs) {
    super(EventType.ComponentVisibilityChanged, { detail })
  }
}
