import { Paper, ToggleButton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import PointerImage from "../../../assets/pointer-solid-24.png";
import { ShowResults } from "../../../data/ShowResults";
import { ModelViewMode } from "../../../data/ModelViewMode";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { EditMode } from "../EditMode";
import EventManager from "../../../events/EventManager";
import EventType from "../../../events/EventType";
import EconomyEnabledChangedEvent from "../../../events/events/EconomyEnabledChangedEvent";
import { useTranslation } from "react-i18next";
import useToolboxStyles from "../util/useToolboxStyles";
import ComponentMenu from "./ComponentMenu";
import ValveComponentMenu from "./ValveComponentMenu";
import { EWAModelWrapper } from "../EWAModelWrapper";
import ShowResultsMenu from "./ShowResultsMenu";
import { OrthoLayer } from "../../../data/OrthoLayer";
import OrthoLayerMenu from "./OrthoLayerMenu";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface ToolboxProps {
  modelWrapper: EWAModelWrapper
  editModeSelection: number
  editCreateMode: EWAComponentType | ValveComponentType | null
  editLassoMode: EWAComponentType | ValveComponentType | null
  viewMode: ModelViewMode
  showResults: ShowResults
  showComponents: EWAComponentType[]
  orthoLayer: OrthoLayer
  setShowComponents: (_: EWAComponentType[]) => void
  setEditCreateMode: (_: EWAComponentType | ValveComponentType | null) => void
  setEditLassoMode: (_: EWAComponentType | ValveComponentType | null) => void
  setEditMode: (editMode: EditMode) => void
  setViewMode: (_: ModelViewMode) => void
  setShowResults: (_: ShowResults) => void
  setOrthoLayer: (_: OrthoLayer) => void
}

export function Toolbox(props: ToolboxProps) {
  const {
    setEditCreateMode,
    editCreateMode,
    setEditMode,
    editLassoMode,
    setEditLassoMode,
    showResults,
    setShowResults,
    showComponents,
    setShowComponents,
    editModeSelection,
    modelWrapper,
    orthoLayer,
    setOrthoLayer
  } = props;
  const classes = useToolboxStyles();
  const [economyEnabled, setEconomyEnabled] = useState(false)
  const { t } = useTranslation();
  const [selectedComponentButton, setSelectedComponentButton] = useState<EWAComponentType | ValveComponentType | null>(null)
  const theme = useTheme()
  const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

  // attach/detach event listeners
  useEffect(() => {
    EventManager.getInstance().attachListener(EventType.EconomyEnabledChanged, onEconomyEnabledChanged)

    return () => {
      EventManager.getInstance().detachListener(EventType.EconomyEnabledChanged, onEconomyEnabledChanged)
    }
  }, [])

  useEffect(() => {
    setSelectedComponentButton(editLassoMode ?? editCreateMode)
  }, [editLassoMode, editCreateMode])

  function onEconomyEnabledChanged(event: EconomyEnabledChangedEvent) {
    setEconomyEnabled(event.detail.newValue)
  }

  function unsetComponentModes() {
    setEditMode(EditMode.Select)
    setEditCreateMode(null)
    setEditLassoMode(null)
  }

  function setComponentAddMode(componentType: EWAComponentType | ValveComponentType) {
    setEditMode(EditMode.Add)
    setEditCreateMode(componentType)
    setEditLassoMode(null)
  }

  function setComponentLassoMode(componentType: EWAComponentType | ValveComponentType) {
    setEditMode(EditMode.Lasso)
    setEditCreateMode(null)
    setEditLassoMode(componentType)
  }

  function setComponentMoveMode() {
    setEditMode(EditMode.Modify)
    setEditCreateMode(null)
    setEditCreateMode(null)
  }

  const commonComponentMenuProps = {
    selectedComponentButton,
    editModeSelection,
    editLassoMode,
    editCreateMode,
    unsetComponentModes,
    setComponentAddMode,
    setComponentLassoMode,
    economyEnabled,
    setShowComponents,
    showComponents,
    modelWrapper
  }

  return (
    <>
      <Paper sx={{ position: "absolute", top: "10px", left: "10px", zIndex: "1002", backgroundColor, display: "flex", flexDirection: "column", maxHeight: 'calc(100% - 20px)', overflow: 'auto' }}>
        <ToggleButton
          classes={{ selected: classes.selected }}
          value={EditMode.Select}
          selected={editModeSelection === EditMode.Select}
          title={t('model.controls.edit_mode.select') as string}
          onClick={() => unsetComponentModes()}>
          <img src={PointerImage} height={24} width={24} alt={"Pointer"} />
        </ToggleButton>
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.junction} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.well} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.spring} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.tank} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.reservoir} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.pipe} />
        <ComponentMenu {...commonComponentMenuProps} componentType={EWAComponentType.pump} />
        <ValveComponentMenu {...commonComponentMenuProps} />
        <ToggleButton
          classes={{ selected: classes.selected }}
          title={t('model.controls.edit_mode.modify') as string}
          value={EditMode.Modify}
          selected={editModeSelection === EditMode.Modify}
          onClick={() => setComponentMoveMode()}
        >
          <OpenWithIcon />
        </ToggleButton>
        <ShowResultsMenu
          modelWrapper={modelWrapper}
          showResults={showResults}
          setShowResults={setShowResults}
        />
        <OrthoLayerMenu orthoLayer={orthoLayer} setOrthoLayer={setOrthoLayer} />
      </Paper>
      {/*<Paper  style={{position: "absolute", bottom: "10px", right: "12px", zIndex: "1002", backgroundColor: "#103B65" }}>*/}
      {/*    <ToggleButton classes={{ selected: classes.selected }} value="ground" selected={viewMode===ModelViewMode.TwoD}  onChange={(event) =>*/}
      {/*      setViewMode(viewMode=== ModelViewMode.TwoD ? ModelViewMode.ThreeD : ModelViewMode.TwoD)}>*/}
      {/*      <Tooltip placement="top-end" title={<>Ever put components on top of each other or are you not seeing your pipes/pumps or valves?<br/><br/>*/}
      {/*        Try a combination of turning <b>grounding</b> off using this button and using Alt+Click+Drag on the map</>}>*/}
      {/*      <StickyNote2Icon/>*/}
      {/*      </Tooltip>*/}
      {/*    </ToggleButton>*/}
      {/*</Paper>*/}
    </>
  )
}
