import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EWAComponentType } from "../../../../../data/EWAComponentType";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice";

export interface ComponentTypeSelectProps {
    componentType?: EWAComponentType
    onChangeComponentType: (_?: EWAComponentType) => void
    noAny?: boolean
}

export default function ComponentTypeSelect(props: ComponentTypeSelectProps) {
    const {
        componentType,
        onChangeComponentType,
        noAny
    } = props

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const items = useMemo(() => {
        const items: JSX.Element[] = []

        if (!noAny) {
            items.push(<MenuItem key="" value=""><i>{t('general.any')}</i></MenuItem>)
        }

        for (const value of Object.values(EWAComponentType)) {
            items.push(<MenuItem key={value} value={value}>
                {t(`ewa_component_type.${value}`, { count: 1 })}
            </MenuItem>)
        }
        return items
    }, [i18n.language, noAny])

    function onChange (e: SelectChangeEvent<string>) {
        const value = e.target.value
        onChangeComponentType(value === '' ? undefined : value as EWAComponentType)
        dispatch(setTaskOptionsDirty(true))
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="componentType" id="componentTypeLabel">{t('ewa_component_type.label')}</InputLabel>
        <Select
            id="componentType"
            labelId="componentTypeLabel"
            onChange={onChange}
            value={componentType ?? ''}
            label={t('ewa_component_type.label')}
        >
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.component_type_hint')}</FormHelperText>
    </FormControl>;
}
