/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LineString } from '../models/LineString';
import type { MultiLineString } from '../models/MultiLineString';
import type { MultiPoint } from '../models/MultiPoint';
import type { MultiPolygon } from '../models/MultiPolygon';
import type { Point } from '../models/Point';
import type { Polygon } from '../models/Polygon';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ElevationService {

    /**
     * Get Elevation
     * Gets the elevation of a point defined by an x- and y- coordinate from a digital terrain model.
 *
 * This function is triggered by a POST request sent to ``/get_elevation``. The request must contain a Geometry in its
 * body with coordinates (WGS84) in it.
 *
 * Args:
 * geom: Geometry that contains either a single Point or a list of Points
 *
 * Raises:
 * ValueError if a Geometry with more than two coordinates is passed.
 *
 * Returns:
 * Geometry complete with elevation in meters
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getElevationV1GetElevationsPost(
requestBody: (Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon),
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/get_elevations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}