import { EWAResult, SingleResult } from "../../../../api/client"
import { EWAModel } from "../../../../data/EWAModel"
import { EWAResultType } from "../../../../views/model/simulation/EWAResultType"
import { AveragePipeAgeForm } from "./performance_indicators/AveragePipeAgeForm"
import { InflowDemandRatioForm } from "./performance_indicators/InflowDemandRatioForm"
import { ResilienceIndexForm } from "./performance_indicators/ResilienceIndexForm"
import { UnsatisfiedNodesForm } from "./performance_indicators/UnsatisfiedNodes"

export interface SingleResultFormProps {
    year: number
    scenario: string
    operatingCondition: string
    performanceIndicator: EWAResultType
    result: EWAResult
    model?: EWAModel
    handleSave: (newSingleResult: SingleResult) => void
}

export interface PerformanceIndicatorFormProps {
    singleResult: SingleResult
    model?: EWAModel
    handleSave: (newSingleResult: SingleResult) => void
}

export function SingleResultForm(props: SingleResultFormProps) {
    const yearResults = props.result?.timed_results?.[props.year] ?? []
    const relevantResult = yearResults.find(item => {
        return item.scenario === props.scenario &&
            item.operating_condition === props.operatingCondition &&
            item.name === props.performanceIndicator
    }) ?? {
        name: props.performanceIndicator,
        operating_condition: props.operatingCondition,
        scenario: props.scenario
    }

    function renderForm() {
        const { performanceIndicator, handleSave } = props

        switch (performanceIndicator) {
            case EWAResultType.ResilienceIndex:
                return <ResilienceIndexForm model={props.model} singleResult={relevantResult} handleSave={handleSave} />
            case EWAResultType.UnsatisfiedNodes:
                return <UnsatisfiedNodesForm model={props.model} singleResult={relevantResult} handleSave={handleSave} />
            case EWAResultType.InflowDemandRatio:
                return <InflowDemandRatioForm model={props.model} singleResult={relevantResult} handleSave={handleSave} />
            case EWAResultType.AveragePipeAge:
                return <AveragePipeAgeForm model={props.model} singleResult={relevantResult} handleSave={handleSave} />
            default:
                return "Performance Indicator not yet supported"
        }
    }

    return <>
        {renderForm()}
    </>
}
