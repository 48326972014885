import { DeveloperMode, EditAttributes } from "@mui/icons-material"
import { Box, Button, IconButton, Slider, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import EconomyPanel from "../../challenges/components/EconomyPanel"
import { EWAModelWrapper } from "./EWAModelWrapper"
import { useMemo, useState } from "react"
import ModelVersionDialog from "./ModelVersionDialog"
import ModelMetaDataDialog from "./meta_data/ModelMetaDataDialog"


export interface ModelFooterProps {
    minDate: number
    maxDate: number
    currentDate: number
    setCurrentDate: (value: number) => void
    debugMode: boolean
    showDebugPanel: () => void
    modelWrapper: EWAModelWrapper
    loadModelData: (modelId: string, versionId?: string) => Promise<void>
}

export default function ModelFooter(props: ModelFooterProps) {
    const {
        minDate,
        maxDate,
        currentDate,
        setCurrentDate,
        debugMode,
        showDebugPanel,
        modelWrapper,
        loadModelData
    } = props

    const [versionListOpen, setVersionListOpen] = useState<boolean>(false)
    const [metaDataOpen, setMetaDataOpen] = useState<boolean>(false)

    const marks = useMemo(() => {
        const marks = []
        for (let i = minDate; i <= maxDate; i += 10) {
            marks.push({
                value: i,
                label: i
            })
        }
        return marks
    }, [minDate, maxDate])
    const { t } = useTranslation()
    const theme = useTheme()
    const backgroundColor = theme.palette.darkBlue.main

    return <div style={{ backgroundColor, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ marginLeft: 3, marginRight: 3, maxWidth: 768, width: '100%', backgroundColor }}>
            <Slider
                value={currentDate}
                onChange={(event, newValue) => setCurrentDate(newValue as number)}
                step={10}
                marks={marks}
                min={minDate}
                max={maxDate}
            />
        </Box>
        <Box sx={{ marginLeft: 3, marginRight: 3 }}>
            <EconomyPanel />
        </Box>
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" gap="1" sx={{marginRight: 1}}>
            {debugMode && <IconButton title={t('model.controls.show_debug_dialog') as string} onClick={() => showDebugPanel()}><DeveloperMode /></IconButton>}
            <IconButton onClick={() => setMetaDataOpen(true)} title={t('model.controls.show_meta_dialog') as string}><EditAttributes /></IconButton>
            <Button onClick={() => setVersionListOpen(true)} variant="contained">Version: {modelWrapper.model.meta?.version}</Button>
        </Box>
        {versionListOpen && <ModelVersionDialog
            onClose={() => setVersionListOpen(false)}
            modelWrapper={modelWrapper}
            loadModelData={loadModelData}
        ></ModelVersionDialog>}
        {metaDataOpen && <ModelMetaDataDialog
            onClose={() => setMetaDataOpen(false)}
            modelWrapper={modelWrapper}
        />}
    </div>
}
