import { Button } from "@mui/material";
import React from "react";
import EconomyManager from "../../managers/EconomyManager";

interface Props { };

interface State { };

export default class Economy extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  setEconomyEnabled(enabled: boolean) {
    EconomyManager.getInstance().enabled = enabled;
  }

  setBudget(value: number) {
    EconomyManager.getInstance().budget = value;
  }

  render() {
    return <div>
      <h3>Economy</h3>
      <div>
        <Button variant="outlined" onClick={() => this.setEconomyEnabled(true)}>Enable</Button>
        <Button variant="outlined" onClick={() => this.setEconomyEnabled(false)}>Disable</Button>
        <Button variant="outlined" onClick={() => this.setBudget(1000)}>1000€</Button>
        <Button variant="outlined" onClick={() => this.setBudget(0)}>0</Button>
      </div>
    </div>
  }
}
