
export interface ISaveData {
  elapsedTime?: number
  taskGroupIndex?: number
}

export default class SaveData {
  public elapsedTime: number = 0;
  public taskGroupIndex: number = 0;

  public constructor (saveData:ISaveData = {}) {
    this.elapsedTime = saveData.elapsedTime ?? 0;
    this.taskGroupIndex = saveData.taskGroupIndex ?? 0;
  }
}
