import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EWAComponentType } from "@/data/EWAComponentType";
import { useMemo } from "react";
import getImageSource from "@/views/model/util/getImageSource";

export interface ComponentTypeSelectProps {
    componentType: EWAComponentType
    onChangeComponentType: (_?: EWAComponentType) => void
    availableComponents?: EWAComponentType[]
    disabled?: boolean
}

export default function ComponentTypeSelect(props: ComponentTypeSelectProps) {
    const {
        componentType,
        onChangeComponentType,
        availableComponents,
        disabled
    } = props

    const { t, i18n } = useTranslation()

    const items = useMemo(() => {
        const items: JSX.Element[] = []

        const availableValues = availableComponents ?? Object.values(EWAComponentType)
        for (const value of availableValues) {
            items.push(<MenuItem key={value} value={value} sx={{dispaly: 'flex', gap: 1}}>
                <img src={getImageSource(value)} width="24" height="24" alt={t(`ewa_component_type.${value}`, { count: 1 }) as string}/>
                {t(`ewa_component_type.${value}`, { count: 1 })}
            </MenuItem>)
        }
        return items
    }, [i18n.language, availableComponents])

    function onChange(e: SelectChangeEvent<string>) {
        const value = e.target.value
        onChangeComponentType(value === '' ? undefined : value as EWAComponentType)
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="componentType" id="componentTypeLabel">{t('ewa_component_type.label')}</InputLabel>
        <Select
            id="componentType"
            labelId="componentTypeLabel"
            onChange={onChange}
            value={componentType ?? ''}
            label={t('ewa_component_type.label')}
            SelectDisplayProps={{style: {display: 'flex', gap: '8px'}}}
            disabled={disabled}
        >
            {items}
        </Select>
    </FormControl>;
}
