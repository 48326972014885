import { Position } from "./Position";
import * as turf from '@turf/turf'

export class LineString {
    type: string = "LineString";
    feature: any;
    coordinates: Position[]

    constructor(data: any) {
        let coordinates = new Array<Position>();
        for (let i = 0; i < data.geometry.coordinates.length; i++) {
            let point = [data.geometry.coordinates[i][0], data.geometry.coordinates[i][1], data.geometry.coordinates[i][2]] as Position;
            coordinates.push(point)
        }
        this.coordinates = coordinates;
    }

    public get length() {
        let length = 0;
        let options = { units: "kilometers" };
        for (let i = 0; i < this.coordinates.length - 1; i++) {
            let from = turf.point([this.coordinates[i][0], this.coordinates[i][1], this.coordinates[i][2]!]);
            let to = turf.point([this.coordinates[i + 1][0], this.coordinates[i + 1][1], this.coordinates[i + 1][2]!]);
            let distance = turf.distance(from, to, options as any);
            length = length + distance;
        }
        return length;
    }
}
