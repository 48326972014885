import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import React, {useState, useCallback} from 'react'
import keycloakClient from "@/keycloak";
import { AuthProvider } from "@/api/auth";
import { Route, Routes } from "react-router";
import { Home } from "./home/Home";
import ChallengeEditor from "@/challenges/components/editor/ChallengeEditor";
import { Model } from "./model/Model";
import { EwaToolAppBar } from "./EwaToolAppBar";

interface EwaToolRoutesProps {
    portalContainer?: Element
}
function EwaToolRoutes(props: EwaToolRoutesProps) {
    const { portalContainer } = props
    return <>
        <Routes>
            <Route path="" element={<Home portalContainer={portalContainer} />} />
            <Route path="challenges/edit" element={<ChallengeEditor />} />
            <Route path="model/:id" element={<Model portalContainer={portalContainer} />} />
        </Routes>
    </>
}

interface AuthenticatedEwaToolProps {
    children?: React.ReactNode
}

function AuthenticatedEwaTool(props: AuthenticatedEwaToolProps) {
    const keycloakInstance = useKeycloak()
    return <AuthProvider {...keycloakInstance}>{props.children}</AuthProvider>
}

export interface EwaToolProps {
    portalContainer?: Element
}

export default function EwaTool(props: EwaToolProps) {
    const [portalContainer, setPortalContainer] = useState<Element | undefined>(undefined)
    const portalContainerCallbackRef = useCallback((node: any) => {
        setPortalContainer(node ?? undefined)
    }, [])


    return <ReactKeycloakProvider
        autoRefreshToken={true}
        authClient={keycloakClient}
        initOptions={{
            flow: "standard",
            onLoad: 'login-required',
            pkceMethod: "S256",
            enableLogging: true,
        }}
    >
        <AuthenticatedEwaTool>
            <EwaToolAppBar portalContainerCallbackRef={portalContainerCallbackRef} />
            <EwaToolRoutes portalContainer={portalContainer}></EwaToolRoutes>
        </AuthenticatedEwaTool>
    </ReactKeycloakProvider>
}
