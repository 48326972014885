import { TextField } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";

class UpdateNumberValueTaskOptionsForm  extends UpdateValueTaskOptionsForm<number> {
  protected noRelativeUpdateTypes: boolean = false;
  protected onlyEqualityComparison: boolean = false;

  targetValueOrDefault (targetValue?: number) {
    return targetValue ?? 0
  }

  extractTargetValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value =  Number.parseFloat(e.target.value);
    return isNaN(value) ? 0 : value
  }

  renderTargetValueField () {
    return <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      label={this.props.t('challenge.editor.tasks.target_value')}
      helperText={this.props.t('challenge.editor.tasks.target_value_hint')}
      onChange={(e) => this.onTargetValueChange(this.extractTargetValue(e))}
      type="number"
      value={this.state.targetValue}
    />
  }
}

export default withTranslation('translation', { withRef: true})(UpdateNumberValueTaskOptionsForm)
