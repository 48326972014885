import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import WellUpdateCommentTask from "../../tasks/WellUpdateCommentTask";
import WellUpdateElevationTask from "../../tasks/WellUpdateElevationTask";
import WellUpdateLifetimeTask from "../../tasks/WellUpdateLifetimeTask";
import WellUpdateInflowTask from "../../tasks/WellUpdateInflowTask";
import UpdateValueTaskOptions from "../../tasks/options/UpdateValueTaskOptions";

export default function WellUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new WellUpdateCommentTask(),
      new WellUpdateInflowTask(new UpdateValueTaskOptions<number>({ year: 2035, id: 'well_1' })),
      new WellUpdateElevationTask(),
      new WellUpdateLifetimeTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
