import { EWAResult } from "../../../../../api/client";
import { Scenario } from "../../Scenario";
import ScenarioValues from "../ScenarioValue";
import { ResultTypeFilter } from "./createPlotData";

export default function getTimedResults(result: EWAResult, year: number, resultTypeFilter: ResultTypeFilter, operatingCondition: string | undefined): ScenarioValues {
    const valueTuple: ScenarioValues = {
        [Scenario.favorable]: 0,
        [Scenario.unfavorable]: 0
    }

    if (operatingCondition !== undefined && result.timed_results !== undefined) {
        const entries = Object.entries(result.timed_results);
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            if (parseInt(entry[0]) !== year) {
                continue;
            }

            const data = entry[1];
            for (let j = 0; j < data.length; j++) {
                const dataEntry = data[j];
                if (!resultTypeFilter(dataEntry) ||
                dataEntry.operating_condition !== operatingCondition ||
                 dataEntry.scenario === undefined ||
                 dataEntry.value === undefined) {
                    continue;
                }
                const scenario = dataEntry.scenario as Scenario
                const value = dataEntry.value

                valueTuple[scenario] = value
            }
        }
    }

    return valueTuple
}
