import { SourceConditionProps, SourceTimedProps, SpringModel } from "../api/client";
import EventManager from "../events/EventManager";
import { SpringApply } from "../views/forms/SpringApply";
import { BasePointFeature } from "./BasePointFeature";
import { EWAComponentType } from "./EWAComponentType";

export class Spring extends BasePointFeature<SourceConditionProps, Partial<SourceTimedProps>> {
    public get componentType(): EWAComponentType {
        return EWAComponentType.spring;
    }

    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.spring) ? 1 : 0;
    }

    public apply(apply: SpringApply): void {
        const currentDate = this.collection.currentDate;
        const geometry = this._geometry
        const oldApply: SpringApply = {
            id: this.id,
            comment: this.data.properties.comment,
            lifetime: this.data.lifetime,
            longitude: geometry.coordinates[0],
            latitude: geometry.coordinates[1],
            elevation: geometry.coordinates[2],
            strength: this.data.properties.strength,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as SourceConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<SourceTimedProps>
        };

        super.apply(apply);

        this.updateCoordinates3d([apply.longitude, apply.latitude, apply.elevation!])

        EventManager.getInstance().onSpringApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.spring,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: EWAComponentType.spring,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as SpringModel

        return model
    }
}
