import { ShowResults } from "../../../data/ShowResults";
import { LocalizableText } from "../../LocalizableText";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IShowResultsTaskOptions extends ITaskOptions {
  showResults?: ShowResults
}

export default class ShowResultsTaskOptions extends TaskOptions {
  public showResults?: ShowResults;

  public constructor(options: IShowResultsTaskOptions = {}) {
    super(options);

    this.showResults = options.showResults;
  }

  public static fromPlain(plainOptions?: IShowResultsTaskOptions) {
    return new ShowResultsTaskOptions(plainOptions);
  }

  public toJSON(): IShowResultsTaskOptions {
    return {
     ...super.toJSON(),
     showResults: this.showResults
    }
  }
}
