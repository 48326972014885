import { BaseFeature } from "../data/BaseFeature";

export interface SinglePropertyGetter<T extends BaseFeature<any, any, any>, U> {
    item: T
    get: (_: U) => any
}

export function findProp(singlePropertyGetter: SinglePropertyGetter<any, any>, alternative: any): any {
    const result = singlePropertyGetter.get(singlePropertyGetter.item.properties);
    if (result !== undefined) {
        return result;
    }
    return alternative;
}
