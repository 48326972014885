import { InputLabel, Select, Tooltip, IconButton, MenuItem } from "@mui/material"
import { Add, Delete } from "@mui/icons-material"
import { OperatingCondition } from "../../api/client"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import OperatingConditionDialog from "./OperatingConditionDialog"
import { EWAModelWrapper } from "./EWAModelWrapper"
import EventManager from "../../events/EventManager"

export interface OperatingConditionToolbarItemProps {
    currentCondition: string | undefined
    setCurrentCondition: (_?: string) => void
    conditions: OperatingCondition[]
    modelWrapper: EWAModelWrapper
    setModelWrapper: (_: EWAModelWrapper) => void
}

export default function OperatingConditionToolbarItem(props: OperatingConditionToolbarItemProps) {
    const {
        currentCondition,
        setCurrentCondition,
        conditions,
        modelWrapper,
        setModelWrapper
    } = props
    const { t } = useTranslation()
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    function deleteCurrentCondition() {
        if (currentCondition !== undefined) {
            const oldCondition = modelWrapper.model.conditions.find(condition => condition.id === currentCondition);

            modelWrapper.model.deleteOperatingCondition(currentCondition)

            EventManager.getInstance().onOperatingConditionDeleted({
                id: oldCondition?.id,
                type: oldCondition?.type,
                model: modelWrapper.model
            })
        }
        let baseCondition = modelWrapper.model.conditions?.find(condition => condition.name === "base")?.id;
        if (baseCondition !== undefined) {
            setCurrentCondition(baseCondition);
        }
    }

    const conditionItems: JSX.Element[] = conditions.map((item, i) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)

    let conditionSelectValue = ''
    if (conditionItems.length) {
        conditionSelectValue = currentCondition ?? ''
    }

    return <>
        <InputLabel style={{ marginTop: "15px" }} id="operating-condition-label">{t('model.operating_condition.label')}</InputLabel>
        <Select
            style={{ marginLeft: "10px", marginTop: "10px", height: "30px" }}
            labelId="operating-condition-label"
            id="operating-condition"
            value={conditionSelectValue}
            label={t('model.operating_condition.label')}
            onChange={(event, newValue) => setCurrentCondition(event.target.value)}
        >
            {conditionItems}
        </Select>
        <Tooltip title={t('model.operating_condition.btn_add') as string}>
            <IconButton color="primary" onClick={() => setDialogOpen(true)}>
                <Add />
            </IconButton>
        </Tooltip>
        {conditions !== undefined && currentCondition !== undefined && conditions?.find(c => c?.name === "base")?.id !== currentCondition &&
            <Tooltip title={t('model.operating_condition.btn_delete') as string}>
                <IconButton color="primary" onClick={() => deleteCurrentCondition()}>
                    <Delete />
                </IconButton>
            </Tooltip>
        }
        <OperatingConditionDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            setCurrentCondition={setCurrentCondition}
            modelWrapper={modelWrapper}
            setModelWrapper={setModelWrapper}
        />
    </>
}
