import { ReactNode, useEffect, useState } from "react";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { TextField, InputAdornment } from "@mui/material";
import { BaseSingleLinestringForm, BaseSingleLinestringFormApply } from "./BaseSingleLineStringForm";
import { findCondition } from "../../utils/findCondition";
import { findTimedIfDefined } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import Valve from "../../data/Valve";
import ValveTimedProps from "../../data/ValveTimedProps";
import ValveApply from "./ValveApply";
import ValveConditionProps from "../../data/ValveConditionProps";
import { singleFormComputeIfFirstYear } from "./util";

export interface SingleValveFormProps {
    valve: Valve
    timedChildren?: ReactNode
    isFirstYear: boolean
    setIsFirstYear: (_:boolean) => void
    currentTimed: Partial<ValveTimedProps>
    fallbackTimed: Partial<ValveTimedProps>
    validate: () => boolean
    computeTimedApply: () => Partial<ValveTimedProps>
    updateTimedState: (_: Partial<ValveTimedProps>) => void
}

export function SingleValveForm(props: SingleValveFormProps) {
    const {
        valve,
        isFirstYear,
        currentTimed,
        fallbackTimed,
        setIsFirstYear
    } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [diameter, setDiameter] = useState<string>(currentTimed.diameter?.toString() ?? '');
    const [minorloss, setMinorloss] = useState<string>(currentTimed.minorloss?.toString() ?? '');
    const [status, setStatus] = useState<string>(findCondition({
        item: valve,
        get: _ => _.status
    }, context.condition, ''));

    const { t } = useTranslation();

    useEffect(() => {
        const newIsFirstYear = singleFormComputeIfFirstYear(valve, currentDate)
        setIsFirstYear(newIsFirstYear)

        const timed = findTimedIfDefined({
            item: valve,
            get: _ => _
        }, currentDate, {}) as Partial<ValveTimedProps>

        setMinorloss(timed.minorloss?.toString() ?? '')
        setDiameter(timed.diameter?.toString() ?? '')

        setStatus(findCondition({
            item: valve,
            get: _ => _.status
        }, context.condition, ''));


        props.updateTimedState(timed)
    }, [valve, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        if (isFirstYear || minorloss !== '') {
            const parsedMinorloss = parseFloatOrUndefined(minorloss)
            if (!validNumber(parsedMinorloss) || parsedMinorloss! < 0) {
                return false
            }
        }

        return props.validate()
    }

    function handleApply(a: BaseSingleLinestringFormApply) {
        const timed: Partial<ValveTimedProps> = {
            ...props.computeTimedApply()
        }

        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }

        const applyMinorloss = parseFloatOrUndefined(minorloss)
        if (applyMinorloss !== undefined) {
            timed.minorloss = applyMinorloss
        }

        let apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            conditional: {
                status: status
            } as ValveConditionProps,
            timed: timed
        } as ValveApply

        valve.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return (<BaseSingleLinestringForm validate={validate} handleApply={handleApply} feature={valve} timedChildren={<>
        <TextField
            required
            type="number"
            value={(diameter)}
            onChange={(event) => setDiameter((event.target.value))}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.valve.diameter')}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.diameter?.toString()}
        />
        <TextField
            type="number"
            value={(minorloss)}
            onChange={(event) => setMinorloss((event.target.value))}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.valve.minorloss')}
            helperText={isFirstYear ? undefined : fallbackTimed.minorloss?.toString()}
        />
        {props.timedChildren}
    </>
    } conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}>
    </BaseSingleLinestringForm>)
}
