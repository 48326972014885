import { configureStore } from "@reduxjs/toolkit";
import challengeReducer from './slices/challengeSlice';
import configurationReducer from './slices/configurationSlice'
import challengeEditorReducer from './slices/challengeEditorSlice'

const store = configureStore({
  reducer: {
    challenge: challengeReducer,
    challengeEditor: challengeEditorReducer,
    configuration: configurationReducer
  }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
