import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValvePSVApply } from "../../views/forms/ValvePSVApply";
import LifetimeValueUpdateTask from "./base/LifetimeValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateLifetimeTaskOptions from "./options/UpdateLifetimeTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class PSVUpdateLifetimeTask extends LifetimeValueUpdateTask<ValvePSVApply> {
  protected _type: string = 'PSVUpdateLifetimeTask';
  protected componentName: string = "valve_component_type.PSV";
  protected fieldName: string = "model.components.lifetime";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number|number[]>) {
    const options = new UpdateLifetimeTaskOptions(plainOptions);
    return new PSVUpdateLifetimeTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValvePSVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValvePSVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValvePSVApply>): ValuesPair<number|number[]> {
    return {
      newValue: args.apply.lifetime,
      oldValue: args.oldApply.lifetime
    }
  }
}
