import { Box, CardContent, CardHeader, IconButton } from "@mui/material";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { setOpenTaskGroupDialog } from "../../store/slices/challengeSlice";
import Challenge from "../Challenge";
import { Description, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import TaskItem from "./TaskItem";

export interface ChallengePanelHeaderProps {
  minifiable: boolean
  minified: boolean
  setMinified: (_: boolean) => void
  challenge: Challenge
}

export default function ChallengePanelHeader(props: ChallengePanelHeaderProps) {
  const {
    minifiable,
    minified,
    setMinified,
    challenge
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const hasDescription: boolean = useSelector((state: RootState) => state.challenge.hasDescription)

  function renderActions() {
    return <Box sx={{flexShrink: 0}}>
      {hasDescription && <IconButton
        title={t('challenge.panel.show_description') as string}
        onClick={() => dispatch(setOpenTaskGroupDialog(true))}
      >
        <Description />
      </IconButton>}
      {minifiable && <IconButton
        title={t(`general.${(minified ? 'maximize' : 'minimize')}`) as string}
        onClick={() => setMinified(!minified)}
      >
        {minified ? <UnfoldMore /> : <UnfoldLess />}
      </IconButton>}
    </Box>
  }

  function renderCurrentTask() {
    const currentTask = challenge.activeTaskGroup?.firstIncompleteTask
    if (currentTask === undefined) {
      return t('challenge.panel.no_tasks');
    }

    return <TaskItem task={currentTask} hideDivider />
  }

  if (minified) {
    return <CardContent>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {renderCurrentTask()}
        {renderActions()}
      </Box>
    </CardContent>
  }

  return <CardHeader
    action={renderActions()}
    title={challenge.name}
  />
}
