import UpdateTaskType from "../../misc/UpdateTaskType";
import ValueUpdateTask from "./ValueUpdateTask";
import { ComponentApplyEventArgs } from "../../../events/events/ComponentApplyEvent";
import { ConsumptionMode } from "../../../api/client";
import UpdateConsumptionModeTaskOptions from "../options/UpdateConsumptionModeTaskOptions";
import i18n from "../../../i18n";
import { compare, comparisonModeToSymbol } from "../../../utils/ComparisonMode";

export default abstract class ConsumptionModeValueUpdateTask<A> extends ValueUpdateTask<A, ConsumptionMode>
{
  public constructor(options?: UpdateConsumptionModeTaskOptions) {
    if (!options) {
      options = new UpdateConsumptionModeTaskOptions();
    }
    super(options);
  }

  public get description(): string {
    const options = this._options as UpdateConsumptionModeTaskOptions;
    let description = ''

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        description = i18n.t('challenge.task.value_update.exact_value', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName),
          value: options.targetValue,
          comparator: comparisonModeToSymbol(options.comparisonMode)
        })
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.value_update.any', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName)
        })
        break;
    }

    return description;
  }

  protected determineUpdate(e: ComponentApplyEventArgs<A>): boolean {
    const values = this.retrieveValues(e);

    if (values.newValue === values.oldValue) {
      return false;
    }

    let shouldUpdate = false;
    const options = this._options as UpdateConsumptionModeTaskOptions;

    if (options.id !== undefined && e.id !== options.id) return false;
    if (options.year !== undefined && e.year !== options.year) return false;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        if (values.newValue === options.targetValue) {
          shouldUpdate = compare(options.comparisonMode, values.newValue, options.targetValue)
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }
    return shouldUpdate;
  }
}
