import { EWAComponentType } from "../../../data/EWAComponentType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IOpenEditDialogTaskOptions extends ITaskOptions {
  id?: string
  year?: number
  componentType?: EWAComponentType
}

export default class OpenEditDialogTaskOptions extends TaskOptions {
  public id?: string
  public year?: number
  public componentType?: EWAComponentType

  public constructor(options: IOpenEditDialogTaskOptions = {}) {
    super(options);

    this.id = options.id
    this.year = options.year
    this.componentType = options.componentType
  }

  public static fromPlain(plainOptions?: IOpenEditDialogTaskOptions) {
    return new OpenEditDialogTaskOptions(plainOptions);
  }

  public toJSON(): IOpenEditDialogTaskOptions {
    return {
      ...super.toJSON(),
      id: this.id,
      year: this.year,
     componentType: this.componentType
    }
  }
}
