/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OperatingConditionType {
    BASE = 'base',
    FIRE = 'fire',
    FAILURE = 'failure',
}