import { EWAModel } from "../../data/EWAModel";
import { ShowResults } from "../../data/ShowResults";
import EventType from "../EventType";

export interface ResultsShownEventArgs {
  model: EWAModel
  oldShowResults: ShowResults
  newShowResults: ShowResults
}

export default class ResultsShownEvent extends CustomEvent<ResultsShownEventArgs> {
  public constructor(detail: ResultsShownEventArgs) {
    super(EventType.ResultsShown, { detail })
  }
}
