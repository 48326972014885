import {TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";

class UpdateStringValueTaskOptionsForm extends UpdateValueTaskOptionsForm<string> {
  targetValueOrDefault (targetValue?: string) {
    return targetValue ?? '';
  }

  renderTargetValueField () {
    return <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      label={this.props.t('challenge.editor.tasks.target_value')}
      helperText={this.props.t('challenge.editor.tasks.target_value_hint')}
      onChange={e => this.onTargetValueChange(e.target.value)}
      value={this.state.targetValue}
    />
  }
}

export default withTranslation('translation', { withRef: true})(UpdateStringValueTaskOptionsForm)
