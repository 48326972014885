import Challenge, { PlainChallenge } from "../Challenge";
import EventManager from "../../events/EventManager";
import store from '../../store/store';
import { setChallenge, setChallengeCompleted, setTaskGroupIndex, setHasDescription, setOpenTaskGroupDialog } from "../../store/slices/challengeSlice";
import TaskItem from "../components/TaskItem";
import { nanoid } from '@reduxjs/toolkit';
import { EWAReadModelDTO } from "../../api/client";
import Task from "../tasks/base/Task";
import { DateTime } from "luxon";

class ChallengeManager {
  //#region singleton stuff
  private static instance: ChallengeManager;
  public static getInstance(): ChallengeManager {
    if (!ChallengeManager.instance) {
      ChallengeManager.instance = new ChallengeManager();
    }

    return ChallengeManager.instance;
  }
  //#endregion

  //#region members
  protected _challengeId: string|undefined;
  protected _challenge: Challenge|undefined = undefined;
  protected _challengeStart: DateTime|undefined = undefined;
  protected _challengeEnd: DateTime|undefined = undefined;
  protected _taskViews: Map<Task, TaskItem> = new Map<Task, TaskItem>();
  //#endregion

  //#region constructor
  private constructor() { }
  //#endregion

  //#region getters/setters
  public get challenge(): Challenge|undefined {
    return this._challenge;
  }

  public get challengeStart(): DateTime|undefined {
    return this._challengeStart;
  }

  public get challengeEnd(): DateTime|undefined {
    return this._challengeEnd;
  }

  public get challengeTime(): number|undefined {
    if (this._challengeStart === undefined || this._challengeEnd === undefined) return undefined;

    return this._challengeEnd.valueOf() - this._challengeStart.valueOf();
  }

  public set challenge(challenge: Challenge|undefined) {
    this._taskViews.clear();
    this._challenge?.deactivate();

    const challengeCompleted = challenge?.completed ?? false;
    this._challenge = challenge;
    this._challengeId = challenge !== undefined ? nanoid() : undefined;
    // only activate if not yet completed
    if (!challengeCompleted) {
      this._challenge?.activate();
    }

    // set challenge start time
    this._challengeStart = this._challenge !== undefined ? DateTime.now().minus({ milliseconds: this._challenge.saveData.elapsedTime }) : undefined;
    this._challengeEnd = challengeCompleted ? DateTime.now() : undefined;

    this.dispatchChallengeData();
  }
  //#endregion

  //#region methods
  protected dispatchChallengeData () {
    // dispatch the challenge's features to the redux store
    const taskGroupIndex = this._challenge?.taskGroupIndex
    store.dispatch(setChallenge({
      challengeId: this._challengeId,
      taskGroupCount: taskGroupIndex !== undefined ? taskGroupIndex + 1 : undefined,
      taskGroupIndex,
      openTaskGroupDialog: (this._challenge?.activeTaskGroup?.hasDescription && this._challenge.activeTaskGroup.options.autoShowDescription) as boolean
    }));

    // dispatch challenge pannel texts/flags
    store.dispatch(setHasDescription(this._challenge?.activeTaskGroup?.hasDescription as boolean));
  }

  public taskStateChanged() {
    if (this._challenge?.tasksFinished) {
      if (!this._challenge.atLastTaskGroup) {
        this._challenge.nextTaskGroup();
        store.dispatch(setTaskGroupIndex(this._challenge.taskGroupIndex))
        store.dispatch(setOpenTaskGroupDialog(this._challenge.activeTaskGroup?.options.autoShowDescription ?? false))
      } else {
        this.finalizeChallenge()
      }
    }

    store.dispatch(setHasDescription(this._challenge?.activeTaskGroup?.hasDescription as boolean));
  }

  protected finalizeChallenge() {
    this._challengeEnd = DateTime.now();
    this._challenge?.deactivate();
    store.dispatch(setChallengeCompleted(true));
    EventManager.getInstance().onChallengeCompleted();
  }

  public reset() {
    if (this._challenge === undefined) return;

    this._challenge.reset();
    this._challengeStart = DateTime.now();
    this._challengeEnd = undefined;

    this.dispatchChallengeData();
  }

  public setTaskViewReference(task: Task, view: TaskItem) {
    this._taskViews.set(task, view);
  }

  public unsetTaskViewReference(task: Task) {
    this._taskViews.delete(task);
  }

  public forceTaskViewUpdate(task: Task) {
    this._taskViews.get(task)?.forceUpdate();
  }

  public challengeFromEWAModel(model: EWAReadModelDTO) {
    const network = model.network

    if (!network || !network.challenges || !network.challenges.taskGroups) return

    const plainChallenge = (network.challenges as PlainChallenge);
    if (plainChallenge === null) return;

    const challenge = Challenge.deserialize(plainChallenge);

    this.challenge = challenge;
  }
  //#endregion
}


export default ChallengeManager;
