import { Close, ResetTv, Save } from "@mui/icons-material"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import LanguageSelect from "./LanguageSelect"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../store/store"
import { loadConfiguration, saveConfiguration, setSaveWithComment } from "../store/slices/configurationSlice"
import DefaultOrthoLayerMenu from "./DefaultOrthoLayerMenu"

export interface UserSettingsProps {
  onClose: () => void
}

export default function UserSettings(props: UserSettingsProps) {
  const {
    onClose
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const saveWithComment = useSelector<RootState, boolean>((state: RootState) => state.configuration.saveWithComment)

  function onSave() {
    dispatch(saveConfiguration())

    onClose()
  }

  function onReset() {
    dispatch(loadConfiguration())
  }

  return <Dialog
    open
    maxWidth="sm"
    fullWidth
    onClose={() => onClose()}
  >
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>{t('user_settings.title')}</div>
      <IconButton aria-label="close" onClick={() => onClose()}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>{t('user_settings.language')}</span>
        <LanguageSelect variant="contained" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>{t('user_settings.save_with_comment')}</span>
        <Checkbox checked={saveWithComment} onChange={(e) => { dispatch(setSaveWithComment(e.target.checked)) }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <span>{t('user_settings.default_ortho_layer')}</span>
      <DefaultOrthoLayerMenu />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button startIcon={<Save />} onClick={onSave}>{t('general.save')}</Button>
      <Button startIcon={<ResetTv />} onClick={onReset}>{t('general.reset')}</Button>
    </DialogActions>
  </Dialog>
}
