import ComparisonMode from "../../../utils/ComparisonMode";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IUpdateValueTaskOptions<T> extends ITaskOptions {
  updateTaskType?: UpdateTaskType
  targetValue?: T
  comparisonMode?: ComparisonMode
  year?: number
  id?: string
}

export default class UpdateValueTaskOptions<T> extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public targetValue: T | undefined;
  public year: number | undefined;
  public id: string | undefined;
  public comparisonMode: ComparisonMode

  public constructor(options: IUpdateValueTaskOptions<T> = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.targetValue = options.targetValue;
    this.year = options.year;
    this.id = options.id;
    this.comparisonMode = options.comparisonMode ?? ComparisonMode.eq

    this.constrainUpdateTaskType();
    this.constrainComparisonMode();
  }

  protected constrainUpdateTaskType() {

  }

  protected constrainComparisonMode() {

  }

  public toJSON(): IUpdateValueTaskOptions<T> {
    return {
      ...super.toJSON(),
      updateTaskType: this.updateTaskType,
      targetValue: this.targetValue,
      year: this.year,
      id: this.id,
      comparisonMode: this.comparisonMode
    }
  }
}
