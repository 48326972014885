import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Box, Button, CardContent, LinearProgress, useTheme } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { decreaseTaskGroupIndex, increaseTaskGroupIndex } from "../../store/slices/challengeSlice"
import { RootState } from "../../store/store"
import Challenge from "../Challenge"

export interface ChallengePanelSubHeaderProps {
  minified: boolean
  challenge: Challenge
}

export default function ChallengePanelSubHeader(props: ChallengePanelSubHeaderProps) {
  const {
    minified,
    challenge
  } = props

  const dispatch = useDispatch()

  const taskGroupIndex = useSelector((state: RootState) => state.challenge.taskGroupIndex)
  const taskGroupCount = useSelector((state: RootState) => state.challenge.taskGroupCount)
  const displayTaskGroupNav = taskGroupCount !== undefined && taskGroupIndex !== undefined && taskGroupCount > 1
  const theme = useTheme()

  function renderCounter() {
    const taskGroupProgress = challenge.activeTaskGroup!.progress
    const challengeProgress = challenge.progress
    return <>
      {challenge.taskGroups.length > 1 && <LinearProgress
        color="primary"
        variant="determinate"
        value={challengeProgress}
        title={`${challengeProgress}%`}
        sx={{ marginBottom: 1, backgroundColor: theme.palette.darkBlue.main }}
      />}
      <LinearProgress
        color="primary"
        variant="determinate"
        value={taskGroupProgress}
        title={`${taskGroupProgress}%`}
        sx={{ backgroundColor: theme.palette.darkBlue.main }}
      />
    </>
  }

  function renderNavigation() {
    if (!displayTaskGroupNav) return

    return <Box
      sx={{
        textAlign: 'center'
      }}
    >
      <Button
        disabled={taskGroupIndex === 0}
        onClick={() => dispatch(decreaseTaskGroupIndex())}
      >
        <ChevronLeft />
      </Button>
      {taskGroupIndex + 1} / {taskGroupCount}
      <Button
        disabled={taskGroupIndex === taskGroupCount - 1}
        onClick={() => dispatch(increaseTaskGroupIndex())}
      >
        <ChevronRight />
      </Button>
    </Box>
  }

  if (minified) return <></>

  return <CardContent>
    {renderCounter()}
    {renderNavigation()}
  </CardContent>
}
