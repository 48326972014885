import { LocalizableText } from "../../LocalizableText";

export interface ITaskOptions {
  name?: LocalizableText
  tooltip?: LocalizableText
  requiredCount?: number
  currentCount?: number
}

export default class TaskOptions {
  public name: LocalizableText;
  public tooltip: LocalizableText;
  public requiredCount: number;
  public currentCount: number;

  public constructor(options: ITaskOptions = {}) {
    this.name = options.name ?? {en: ''};
    this.tooltip = options.tooltip ?? {en: ''};
    this.requiredCount = options.requiredCount ?? 1;
    this.currentCount = options.currentCount ?? 0;
  }

  public toJSON(): ITaskOptions {
    return {
      name: this.name,
      tooltip: this.tooltip,
      requiredCount: this.requiredCount,
      currentCount: this.currentCount
    }
  }
}
