import { Close } from "@mui/icons-material"
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EWASimulationDTO, ModellingService } from "../../../api/client"
import { SimulationResultsPlot } from "./SimulationResultsPlot"
import { useEffect, useState } from 'react'
import { useSnackbar } from "notistack"
import { EWAModelWrapper } from "../EWAModelWrapper"

export interface SimulationResultsPlotDialogProps {
  modelWrapper: EWAModelWrapper,
  ewaSimulations: EWASimulationDTO[],
  onClose: () => void
}

export default function SimulationResultsPlotDialog(props: SimulationResultsPlotDialogProps) {
  const {
    modelWrapper,
    ewaSimulations,
    onClose
  } = props

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const [loadedEwaSimulations, setLoadedEwaSimulations] = useState<EWASimulationDTO[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    prepareSimulations()
  }, [ewaSimulations])

  async function loadEWAResult(ewaSimulation: EWASimulationDTO): Promise<EWASimulationDTO> {
    return {
      ...ewaSimulation,
      result: await ModellingService.simulationResultsV1SimulationsIdResultsGet(ewaSimulation.id)
    }
  }

  async function prepareSimulations() {
    setLoading(true)
    try {
      const promises: Promise<EWASimulationDTO>[] = []
      for (const ewaSimulation of ewaSimulations) {
        promises.push(loadEWAResult(ewaSimulation))
      }

      setLoadedEwaSimulations(await Promise.all(promises))
    } catch (e: any) {
      if (e.message.indexOf("Not found") === -1) {
        enqueueSnackbar(e.message, {
          variant: 'error'
        })
      }
      setLoadedEwaSimulations([])
    }

    setLoading(false)
  }

  function renderPlot() {
    if (loadedEwaSimulations.length === 0) {
      return <Box sx={{ display: 'flex', height: 400, alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    }

    return <SimulationResultsPlot
      ewaSimulations={loadedEwaSimulations}
      operatingCondition={modelWrapper.model.currentCondition!}
      modelWrapper={modelWrapper}
    />
  }

  return <Dialog
    open
    maxWidth="lg"
    fullWidth
    onClose={() => onClose()}
  >
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>{t('simulation_view.simulation_results_plot')}</div>
      <IconButton aria-label="close" onClick={() => onClose()}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ height: 4 }}>
        {loading && <LinearProgress />}
      </Box>
     {renderPlot()}
    </DialogContent>
  </Dialog>
}
