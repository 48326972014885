import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import ShowResultsTaskOptions, { IShowResultsTaskOptions } from "./options/ShowResultsTaskOptions";
import ResultsShownEvent from "../../events/events/ResultsShownEvent";
import { ShowResults } from "../../data/ShowResults";
import i18n from "../../i18n";

export default class ShowResultsTask extends Task {
  protected _type: string = 'ShowResultsTask';
  public constructor(options?: ShowResultsTaskOptions) {
    if (!options) {
      options = new ShowResultsTaskOptions();
    }

    super(options);

    this.onResultsShown = this.onResultsShown.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IShowResultsTaskOptions) {
    const options = new ShowResultsTaskOptions(plainOptions);
    return new ShowResultsTask(options);
  }

  protected onResultsShown(e: ResultsShownEvent) {
    const options = this._options as ShowResultsTaskOptions;

    if (options.showResults !== undefined && e.detail.newShowResults !== options.showResults) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ShowResultsTaskOptions;

    let description = '';
    if (options.showResults === undefined) {
      description = i18n.t('challenge.task.show_results.any')
    } else {
      switch (options.showResults) {
        case ShowResults.HeatMap:
          description = i18n.t('challenge.task.show_results.heat_map')
          break;
        case ShowResults.Demand:
          description = i18n.t('challenge.task.show_results.demand')
          break;
        case ShowResults.None:
        default:
          description = i18n.t('challenge.task.show_results.none')
      }
    }

    return description
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ResultsShown, this.onResultsShown);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ResultsShown, this.onResultsShown);
  }

}
