import { useTranslation } from "react-i18next";
import { EWAResultType } from "../EWAResultType";
import CommonPlotProps from "./CommonPlotProps";
import { useEffect, useState } from "react";
import { Data, Layout } from "plotly.js";
import createPlotData from "./util/createPlotData";
import Plot from "react-plotly.js";
import { Scenario } from "../Scenario";
import i18n from "../../../../i18n";
import addAlphaToHexColor from "../../../../utils/addAlphaToHexColor";
import ScenarioValues from "./ScenarioValue";
import { SingleResult } from "../../../../api/client";

const ewaResultType = EWAResultType.InflowDemandRatio

const resultTypeFilter = (_: SingleResult) => _.name === ewaResultType

function generateTraces(years: number[], values: ScenarioValues[], color: string, enabledScenarios: Scenario[], ewaSimulationName: string): Data[] {
    const favorableY: number[] = [];
    const unfavorableY: number[] = [];

    for (let i = 0; i < values.length; i++) {
        const elem = values[i];
        favorableY.push(elem.favorable);
        unfavorableY.push(elem.unfavorable);
    }

    const scenarioSet = new Set(enabledScenarios)
    const traces: Data[] = []
    const colorFill = addAlphaToHexColor(color, 0.2)

    const favorableTrace: Data = {
        x: years,
        y: favorableY,
        mode: 'lines+markers',
        line: { color },
        name: i18n.t('scenario.favorable') as string,
        type: "scatter",
        visible: scenarioSet.has(Scenario.favorable)
    }
    traces.push(favorableTrace)

    const unfavorableTrace: Data = {
        x: years,
        y: unfavorableY,
        fill: 'tonexty',
        fillcolor: colorFill,
        mode: 'lines+markers',
        line: { color, dash: 'dot' },
        name: i18n.t('scenario.unfavorable') as string,
        type: "scatter",
        visible: scenarioSet.has(Scenario.unfavorable)
    }
    traces.push(unfavorableTrace)

    return traces
}

export interface InflowDemandRatioPlotProps extends CommonPlotProps {

}

export default function InflowDemandRatioPlot(props: InflowDemandRatioPlotProps) {
    const {
        ewaSimulations,
        width,
        height,
        operatingCondition,
        config,
        enabledScenarios
    } = props

    const { t, i18n } = useTranslation()
    const [data, setData] = useState<Data[]>([])

    useEffect(() => {
        const newData = createPlotData(ewaSimulations, operatingCondition, enabledScenarios, resultTypeFilter, generateTraces);
        setData(newData);
    }, [ewaSimulations, operatingCondition, i18n.language, enabledScenarios]);

    const layout: Partial<Layout> = {
        width,
        height,
        title: t(`ewa_result_type.${ewaResultType}`) as string,
        yaxis: {
            autorange: true
        },
        xaxis: {
            type: 'linear',
            tickvals: [2025, 2035, 2045, 2055]
        }
    }

    return <Plot
        config={config}
        data={data}
        layout={layout}
    />
}
