import React, {
  useEffect,
  useState
} from "react";
import InfoIcon from '@mui/icons-material/Info';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  EWAModelDTO,
  ModellingService,
  StateType
} from "@/api/client";
import {
  Box,
  LinearProgress,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { History } from "@mui/icons-material";
import { VersionsTable } from "./VersionsTable";
import { CompletedModelForm } from "./CompletedModelForm";
import { FlowModelForm } from "./FlowModelForm";
import { FlowLogForm } from "./FlowLogForm";
import { useTranslation } from "react-i18next";
import logo from "@/assets/logo.png";

export interface ModelFormProps {
  item: string | undefined
  state: StateType | undefined
  setSelection: (value?: string) => void
  fetchModels: () => void
  accessibleModels: Set<string>
  challengeToModelName: Map<string, string>
}

export function ModelForm(props: ModelFormProps) {
  const { item, state } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState<EWAModelDTO | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const theme = useTheme()

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    setCurrentTab(newValue);
  }

  async function fetchModel() {
    setIsLoading(true)

    if (item !== undefined) {
      try {
        const model = await ModellingService.modelV1ModelsModelIdGet(item)
        setModel(model)
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    } else {
      setModel(undefined)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchModel().catch(e => {
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
    });
  }, [item]);

  function renderTabContent() {
    switch (currentTab) {
      case 2:
        return <VersionsTable model={model!} />
      case 1:
        return <FlowLogForm state={state} item={item} />
      default:
        if (isLoading) return <LinearProgress />

        return (state === StateType.COMPLETED ?
          <CompletedModelForm
            model={model!}
            setModel={setModel}
            fetchModels={props.fetchModels}
            setSelection={props.setSelection}
            accessibleModels={props.accessibleModels}
            challengeToModelName={props.challengeToModelName}
          /> :
          <FlowModelForm model={model!} setModel={setModel} />)
    }
  }

  function renderBoxContent() {
    if (model !== undefined && state !== undefined) {
      return <>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab iconPosition="start" icon={<InfoIcon />} title={t('home.model_form.tabs.info') as string} />
          {!model.shared && <Tab iconPosition="start" icon={<TextSnippetIcon />} title={t('home.model_form.tabs.flow_logs') as string}/>}
          <Tab iconPosition="start" icon={<History />} title={t('home.model_form.tabs.versions') as string} />
        </Tabs>
        {renderTabContent()}
      </>
    }

    return <Box display="flex" justifyContent="center" alignItems="center" height="100%"><img src={logo} alt="logo" className="responsive-img" style={{maxWidth: '160px'}}></img></Box>
  }

  return <Box sx={{
    padding: "5px", width: "100%", height: "100%", backgroundColor: theme.palette.darkBlue.main,
    display: "flex", flexDirection: "column"
  }}>
    {renderBoxContent()}
  </Box>
}
