import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React from "react";
import { OperatingConditionType } from "../../../../api/client";
import { IPerformanceIndicatorTaskOptions } from "../../../tasks/options/PerformanceIndicatorTaskOptions";
import ComparisonMode from "../../../../utils/ComparisonMode";
import { withTranslation } from "react-i18next";
import YearSelect from "./common_inputs/YearSelect";
import IdInput from "./common_inputs/IdInput";
import OperatingConditionTypeSelect from "./common_inputs/OperatingConditionTypeSelect";
import ComparisonModeSelect from "./common_inputs/ComparisonModeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  id?: string
  year?: number
  threshold?: number
  scenario?: string
  operatingConditionType?: OperatingConditionType
  comparisonMode: ComparisonMode
}

class PerformanceIndicatorTaskOptionsForm extends TaskOptionsExtrasForm<State, IPerformanceIndicatorTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IPerformanceIndicatorTaskOptions>) {
    super(props);

    this.renderIdField = this.renderIdField.bind(this);
    this.renderYearField = this.renderYearField.bind(this);
    this.renderThresholdField = this.renderThresholdField.bind(this);
    this.renderScenarioField = this.renderScenarioField.bind(this);
    this.renderOpeartingConditionTypeField = this.renderOpeartingConditionTypeField.bind(this);
    this.renderComparisonModeField = this.renderComparisonModeField.bind(this);

    this.onThresholdChange = this.onThresholdChange.bind(this);
    this.onUpdateScenario = this.onUpdateScenario.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IPerformanceIndicatorTaskOptions>) {
    return {
      id: props.options.id,
      year: props.options.year,
      threshold: props.options.threshold,
      scenario: props.options.scenario,
      operatingConditionType: props.options.operatingConditionType,
      comparisonMode: props.options.comparisonMode ?? ComparisonMode.eq
    };
  }

  public extractOptions() {
    return {
      id: this.state.id,
      year: this.state.year,
      threshold: this.state.threshold,
      scenario: this.state.scenario,
      operatingConditionType: this.state.operatingConditionType,
      comparisonMode: this.state.comparisonMode ?? ComparisonMode.eq
    }
  }

  renderIdField () {
    return <IdInput value={this.state.id} onChange={(_) => this.setState({id:_})}/>
  }

  renderYearField () {
    return <YearSelect year={this.state.year} onYearChange={(_?:number) => this.setState({year:_})} />
  }

  onThresholdChange (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let threshold: number | undefined = Number.parseInt(e.target.value);
    if (Number.isNaN(threshold)) {
      threshold = undefined
    }

    this.setState({
      threshold
    })

    this.props.setIsDirty(true)
  }

  renderThresholdField() {
    return <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      label={this.props.t('general.threshold')}
      helperText={this.props.t('challenge.editor.tasks.performance_indicator.threshold_hint')}
      type="number"
      onChange={this.onThresholdChange}
      value={this.state.threshold ?? ''}
    />
  }

  onUpdateScenario(e: SelectChangeEvent<string>) {
    const value = e.target.value
    this.setState({
      scenario: (value !== '' ? value : undefined)
    })

    this.props.setIsDirty(true)
  }

  renderScenarioField() {
    const {t} = this.props
    const options: JSX.Element[] = [
      <MenuItem key='' value=''><i>{t('general.any')}</i></MenuItem>,
      <MenuItem key='favorable' value='favorable'>{t('scenario.favorable')}</MenuItem>,
      <MenuItem key='unfavorable' value='unfavorable'>{t('scenario.unfavorable')}</MenuItem>
    ]
    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="scenario">{t('general.scenario')}</InputLabel>
      <Select
        id="scenario"
        value={this.state.scenario ?? ''}
        onChange={this.onUpdateScenario}
        fullWidth
        label={t('general.scenario')}
      >
        {options}
      </Select>
      <FormHelperText>{t('challenge.editor.tasks.performance_indicator.scenario_hint')}</FormHelperText>
    </FormControl>
  }

  renderOpeartingConditionTypeField() {
    return <OperatingConditionTypeSelect value={this.state.operatingConditionType} onChange={(_) => this.setState({operatingConditionType: _})} />
  }

  renderComparisonModeField() {
    return <ComparisonModeSelect
      value={this.state.comparisonMode}
      onValueChange={(_) => this.setState({comparisonMode: _})}
    />
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderIdField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderYearField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderComparisonModeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderThresholdField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderScenarioField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderOpeartingConditionTypeField()}</Grid>
    </Grid>
  }
}

export default withTranslation('translation', {withRef: true})(PerformanceIndicatorTaskOptionsForm)
