import { useState } from "react";
import { TextField } from "@mui/material";
import { TCVTimedProps } from "../../api/client";
import { findTimed, findComputedTimed } from "../../utils/findTimed";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { SingleValveForm } from "./SingleValveForm";
import { singleFormComputeIfFirstYear } from "./util";
import { useTranslation } from "react-i18next";
import { ValveTCV } from "../../data/ValveTCV";

export interface SingleValveTCVFormProps {
    valve: ValveTCV
}

export function SingleValveTCVForm(props: SingleValveTCVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))
    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<TCVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<TCVTimedProps>

    const [headlossCoefficient, setHeadlossCoefficient] = useState<string>(currentTimed.headloss_coefficient?.toString() ?? '');

    const { t } = useTranslation();

    function updateTimedState(timed: Partial<TCVTimedProps>) {
        setHeadlossCoefficient(timed.headloss_coefficient?.toString() ?? '')
    }

    function validate() {
        if (isFirstYear || headlossCoefficient !== '') {
            const parsedHeadlossCoefficient = parseFloatOrUndefined(headlossCoefficient)
            if (!validNumber(parsedHeadlossCoefficient) || parsedHeadlossCoefficient! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<TCVTimedProps> {
        const timed: Partial<TCVTimedProps> = {}
        const applyHeadlossCoefficient = parseFloatOrUndefined(headlossCoefficient)
        if (applyHeadlossCoefficient !== undefined) {
            timed.headloss_coefficient = applyHeadlossCoefficient
        }
        return timed
    }

    return (<SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(headlossCoefficient)}
                onChange={(event) => setHeadlossCoefficient((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.tcv.headloss_coefficient')}
                helperText={isFirstYear ? undefined : fallbackTimed.headloss_coefficient?.toString()}
            />
        </>
        }></SingleValveForm>)
}
