import i18n from "../i18n";

export enum EWAComponentType {
    junction = "Junction",
    tank = "Tank",
    reservoir = "Reservoir",
    pipe = "Pipe",
    valve = "Valve",
    pump = "Pump",
    spring = "Spring",
    well = "Well"
}

export function isComponentALine(componentType: EWAComponentType): boolean {
    switch (componentType) {
        case EWAComponentType.pump:
        case EWAComponentType.pipe:
        case EWAComponentType.valve:
            return true;
        default:
            return false;
    }
}

export function isComponentAPoint(componentType: EWAComponentType): boolean {
    return !isComponentALine(componentType);
}

export function ewaComponentTypeFromString(value: string): EWAComponentType {
    switch (value) {
        case 'Junction':
        case 'Reservoir':
        case 'Tank':
        case 'Pump':
        case 'Pipe':
        case 'Valve':
        case 'Spring':
        case 'Well':
            return value as EWAComponentType
        default:
            throw Error(`Unable to determine type for: ${value}`)
    }
}

export function ewaComponentTypeText(value: EWAComponentType): string {
    return i18n.t(`ewa_component_type.${value}`, { count: 0 })
  }
