import { Slider } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";
import { maxYear, minYear } from "../../../../utils/constants";

class UpdateLifetimeValueTaskOptionsForm extends UpdateValueTaskOptionsForm<number|number[]> {
  targetValueOrDefault (targetValue?: number|number[]) {
    return targetValue ?? [minYear, maxYear]
  }

  renderTargetValueField () {
    const marks = []
    for (let i = minYear; i <= maxYear; i += 10) {
      marks.push({
        value: i,
        label: i
      })
    }

    return <div style={{margin: '30px 30px 0'}}>
      <Slider
        value={this.state.targetValue}
        onChange={(event, targetValue) => this.onTargetValueChange(targetValue)}
        valueLabelDisplay="on"
        min={minYear}
        max={maxYear}
        step={10}
        marks={marks}
      />
    </div>
  }
}

export default withTranslation('translation', { withRef: true})(UpdateLifetimeValueTaskOptionsForm)
