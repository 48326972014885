/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_v1_models_post } from '../models/Body_upload_v1_models_post';
import type { ChallengeMetaDataModel } from '../models/ChallengeMetaDataModel';
import type { ChallengeModel } from '../models/ChallengeModel';
import type { EWAModelDTO } from '../models/EWAModelDTO';
import type { EWAModelSaveDTO } from '../models/EWAModelSaveDTO';
import type { EWAModelType } from '../models/EWAModelType';
import type { EWAModelVersionDto } from '../models/EWAModelVersionDto';
import type { EWAReadModelDTO } from '../models/EWAReadModelDTO';
import type { EWAResult } from '../models/EWAResult';
import type { EWASimulationDTO } from '../models/EWASimulationDTO';
import type { EWATaskRunDTO } from '../models/EWATaskRunDTO';
import type { Log } from '../models/Log';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModellingService {

    /**
     * Model Logs
     * @param modelId
     * @returns Log Successful Response
     * @throws ApiError
     */
    public static modelLogsV1ModelsModelIdLogsGet(
        modelId: string,
    ): CancelablePromise<Array<Log>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/{model_id}/logs',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Model Versions
     * @param modelId
     * @returns EWAModelVersionDto Successful Response
     * @throws ApiError
     */
    public static modelVersionsV1ModelsModelIdVersionsGet(
        modelId: string,
    ): CancelablePromise<Array<EWAModelVersionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/{model_id}/versions',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Model
     * @param modelId
     * @param versionId
     * @param includePayload
     * @returns EWAReadModelDTO Successful Response
     * @throws ApiError
     */
    public static modelV1ModelsModelIdGet(
        modelId: string,
        versionId?: string,
        includePayload?: any,
    ): CancelablePromise<EWAReadModelDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models/{model_id}',
            path: {
                'model_id': modelId,
            },
            query: {
                'version_id': versionId,
                'include_payload': includePayload,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Model
     * @param modelId
     * @param requestBody
     * @returns EWAModelSaveDTO Successful Response
     * @throws ApiError
     */
    public static updateModelV1ModelsModelIdPut(
        modelId: string,
        requestBody: EWAModelSaveDTO,
    ): CancelablePromise<EWAModelSaveDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/models/{model_id}',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Simulate Model
     * @param modelId
     * @param simulationName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static simulateModelV1ModelsModelIdPost(
        modelId: string,
        simulationName?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/models/{model_id}',
            path: {
                'model_id': modelId,
            },
            query: {
                'simulation_name': simulationName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Model
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteModelV1ModelsModelIdDelete(
        modelId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/models/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Model Upload Challenge
     * @param modelId
     * @param requestBody
     * @returns ChallengeMetaDataModel Successful Response
     * @throws ApiError
     */
    public static modelUploadChallengeV1ModelsModelIdChallengePut(
        modelId: string,
        requestBody: ChallengeModel,
    ): CancelablePromise<ChallengeMetaDataModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/models/{model_id}/challenge',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Model Delete Challenge
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static modelDeleteChallengeV1ModelsModelIdChallengeDelete(
        modelId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/models/{model_id}/challenge',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Simulation Logs
     * @param id
     * @returns Log Successful Response
     * @throws ApiError
     */
    public static simulationLogsV1SimulationsIdLogsGet(
        id: string,
    ): CancelablePromise<Array<Log>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{id}/logs',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Task Runs
     * @param id
     * @returns EWATaskRunDTO Successful Response
     * @throws ApiError
     */
    public static taskRunsV1SimulationsIdTaskRunsGet(
        id: string,
    ): CancelablePromise<Array<EWATaskRunDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{id}/task_runs',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Simulation Results
     * @param id
     * @returns EWAResult Successful Response
     * @throws ApiError
     */
    public static simulationResultsV1SimulationsIdResultsGet(
        id: string,
    ): CancelablePromise<EWAResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{id}/results',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Simulation
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteSimulationV1SimulationsIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/simulations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Simulations
     * @param modelId
     * @returns EWASimulationDTO Successful Response
     * @throws ApiError
     */
    public static simulationsV1SimulationsModelIdGet(
        modelId: string,
    ): CancelablePromise<Array<EWASimulationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Last Simulation
     * @param modelId
     * @returns EWASimulationDTO Successful Response
     * @throws ApiError
     */
    public static lastSimulationV1SimulationsModelIdLastGet(
        modelId: string,
    ): CancelablePromise<Array<EWASimulationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{model_id}/last',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Pending Simulations
     * get list of all simulations that are either running, pending, or scheduled
     * @param modelId
     * @returns EWASimulationDTO Successful Response
     * @throws ApiError
     */
    public static pendingSimulationsV1SimulationsModelIdPendingGet(
        modelId: string,
    ): CancelablePromise<Array<EWASimulationDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/simulations/{model_id}/pending',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Models
     * @returns EWAModelDTO Successful Response
     * @throws ApiError
     */
    public static modelsV1ModelsGet(): CancelablePromise<Array<EWAModelDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/models',
        });
    }

    /**
     * Upload
     * @param name
     * @param description
     * @param modelYear
     * @param crs
     * @param type
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadV1ModelsPost(
        name: string,
        description: string,
        modelYear: number,
        crs?: string,
        type?: EWAModelType,
        formData?: Body_upload_v1_models_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/models',
            query: {
                'name': name,
                'description': description,
                'model_year': modelYear,
                'crs': crs,
                'type': type,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
