import { Box, Button, IconButton, Portal } from "@mui/material";
import { BaseFeature } from "../../data/BaseFeature";
import { ReactNode } from "react";
import { useFormContext } from "./FormContext";
import { useTranslation } from "react-i18next";
import FormTitle from "./FormTitle";
import { Close, Delete, Save } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export interface BaseFormProps {
    features: BaseFeature<any, any, any>[]
    children?: ReactNode;
    handleApply: () => void;
    validate: () => boolean;
    handleDelete: () => void;
}

export function BaseForm(props: BaseFormProps) {
    const { features, validate } = props;
    const formContext = useFormContext();
    const headerPortalContainerRef = formContext.headerPortalContainerRef
    const footerPortalContainerRef = formContext.footerPortalContainerRef
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleDelete() {
        props.handleDelete()
        if (formContext.onDelete !== undefined) {
            formContext.onDelete()
        }
    }

    function handleApply() {
        props.handleApply()

        enqueueSnackbar(t('model.changes_applied'), {
            variant: 'success',
            action: (snackbarId) => (<>
                <IconButton sx={{ color: 'white' }} onClick={() => { closeSnackbar(snackbarId) }} title={t('general.close') as string}><Close /></IconButton>
            </>)
        })
    }

    function renderTitle() {
        return <FormTitle features={features} />
    }

    function renderActions() {
        return <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={!validate()}
                onClick={handleApply}
                title={t('general.apply') as string}
            >
                <Save />
            </Button>
            <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={handleDelete}
                title={t('general.delete') as string}
            >
                <Delete />
            </Button>
        </Box>
    }

    function onFormSubmit(e: React.SyntheticEvent) {
        e.preventDefault()

        handleApply()
    }


    return (<>
        {headerPortalContainerRef !== undefined ? <Portal container={headerPortalContainerRef.current}>{renderTitle()}</Portal> : renderTitle()}
        <form onSubmit={onFormSubmit} noValidate>
            {props.children}
            <button type="submit" hidden></button>
        </form>
        {footerPortalContainerRef !== undefined ? <Portal container={footerPortalContainerRef.current}>{renderActions()}</Portal> : renderActions()}
    </>)
}
