import { JunctionConditionProps, JunctionModel, JunctionTimedProps } from "../api/client";
import EventManager from "../events/EventManager";
import { JunctionApply } from "../views/forms/JunctionApply";
import { BasePointFeature } from "./BasePointFeature";
import { EWAComponentType } from "./EWAComponentType";

export class Junction extends BasePointFeature<JunctionConditionProps, Partial<JunctionTimedProps>> {
    public get componentType(): EWAComponentType {
        return EWAComponentType.junction;
    }


    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.junction) ? 1 : 0;
    }

    public apply(apply: JunctionApply): void {
        const currentDate = this.collection.currentDate;
        const geometry = this._geometry
        const oldApply: JunctionApply = {
            id: this.id,
            comment: this.data.properties.comment,
            lifetime: this.data.lifetime,
            longitude: geometry.coordinates[0],
            latitude: geometry.coordinates[1],
            elevation: geometry.coordinates[2],
            strength: this.data.properties.strength,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as JunctionConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<JunctionTimedProps>
        };

        super.apply(apply);

        this.updateCoordinates3d([apply.longitude, apply.latitude, apply.elevation!])

        EventManager.getInstance().onJunctionApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.junction,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: EWAComponentType.junction,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as JunctionModel

        return model
    }
}
