import { EWAModelVersionDto } from "@/api/client";
import {
  Card,
  CardContent
} from "@mui/material";

export interface VersionCardProps {
  version: EWAModelVersionDto
}

export default function VersionCard(props: VersionCardProps) {
  const {
    version
  } = props

  return <Card>
    <CardContent>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>{version.version}</div>
        <div>{version.date}</div>
      </div>
    </CardContent>
  </Card>
}
