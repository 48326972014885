import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";
import { ConsumptionMode } from "../../../../api/client";
import { withTranslation } from "react-i18next";

class UpdateConsumptionModeValueTaskOptionsForm extends UpdateValueTaskOptionsForm<ConsumptionMode> {
  targetValueOrDefault (targetValue?: ConsumptionMode) {
    return targetValue ?? ConsumptionMode.CLASSIC
  }

  renderTargetValueField () {
    const items = [];
    for (const value of Object.values(ConsumptionMode)) {
      items.push(<MenuItem key={value} value={value}>{value}</MenuItem>);
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="targetValue">{this.props.t('challenge.editor.tasks.target_value')}</InputLabel>
      <Select id="targetValue" value={this.state.targetValue} onChange={(e) => this.onTargetValueChange(e.target.value as ConsumptionMode)} label={this.props.t('challenge.editor.tasks.target_value')}>
        {items}
      </Select>
      <FormHelperText>{this.props.t('challenge.editor.tasks.target_value_hint')}</FormHelperText>
    </FormControl>
  }
}

export default withTranslation('translation', { withRef: true})(UpdateConsumptionModeValueTaskOptionsForm)
