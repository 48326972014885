import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { PipeApply } from "../../views/forms/PipeApply";
import { ValuesPair } from "./base/ValueUpdateTask";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";
import { PipeMaterial } from "@/api/client";
import PipeMaterialValueUpdateTask from "./base/PipeMaterialValueUpdateTask";
import UpdatePipeMaterialTaskOptions from "./options/UpdatePipeMaterialTaskOptions";

export default class PipeUpdateMaterialTask extends PipeMaterialValueUpdateTask<PipeApply> {
  protected _type: string = 'PipeUpdateMaterialTask';
  protected componentName: string = "ewa_component_type.Pipe_one";
  protected fieldName: string = "model.components.pipe.material";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<PipeMaterial>) {
    const options = new UpdatePipeMaterialTaskOptions(plainOptions);
    return new PipeUpdateMaterialTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.PipeApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.PipeApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<PipeApply>): ValuesPair<PipeMaterial> {
    return {
      newValue: args.apply.timed.material,
      oldValue: args.oldApply.timed.material
    };
  }
}
