import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateTaskType from "../../../misc/UpdateTaskType";
import { IChangeYearTaskOptions } from "../../../tasks/options/ChangeYearTaskOptions";
import YearSelect from "./common_inputs/YearSelect";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";


interface State {
  year?: number
  updateTaskType: UpdateTaskType
}

class ChangeYearTaskOptionsForm extends TaskOptionsExtrasForm<State, IChangeYearTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IChangeYearTaskOptions>) {
    super(props)

    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
    this.renderYearField = this.renderYearField.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IChangeYearTaskOptions>) {
    return {
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any,
      year: props.options.year
    };
  }

  public extractOptions() {
    return {
      year: this.state.year,
      updateTaskType: this.state.updateTaskType
    }
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative
    />
  }

  renderYearField() {
    return <YearSelect year={this.state.year} onYearChange={(_?: number) => this.setState({ year: _ })} />
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderUpdateTypeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderYearField()}</Grid>
    </Grid>
  }
}

export default withTranslation('translation', { withRef: true })(ChangeYearTaskOptionsForm)
