import { Paper, ToggleButton, Fade, Popper, useTheme, ClickAwayListener } from "@mui/material";
import { OrthoLayer, orthoLayerImage, orthoLayerText } from "../../../data/OrthoLayer";
import { useTranslation } from "react-i18next";
import useToolboxStyles from "../util/useToolboxStyles";
import { usePopupState, bindPopper, bindTrigger } from "material-ui-popup-state/hooks";
import { Layers } from "@mui/icons-material";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";
import EventManager from "../../../events/EventManager";

export interface OrthoLayerMenuProps {
    orthoLayer: OrthoLayer
    setOrthoLayer: (_: OrthoLayer) => void
}

export default function OrthoLayerMenu(props: OrthoLayerMenuProps) {
    const {
        orthoLayer,
        setOrthoLayer
    } = props
    const classes = useToolboxStyles()
    const popupState = usePopupState({ variant: 'popper', popupId: 'showResultsMenu' })
    const { t } = useTranslation()
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    function changeOrthoLayer(newOrthoLayer: OrthoLayer) {
        const oldOrthoLayer = orthoLayer;
        setOrthoLayer(newOrthoLayer);

        EventManager.getInstance().onOrthoLayerChanged({
            oldOrthoLayer,
            newOrthoLayer
        });
    }

    function renderButtons() {
        const buttons: JSX.Element[] = []

        const items = [
            OrthoLayer.BasemapGrau,
            OrthoLayer.OSM,
            OrthoLayer.BasemapOrtho
        ]

        for (const item of items) {
            const title = orthoLayerText(item)
            buttons.push(<ToggleButton
                key={item}
                title={title}
                classes={{ selected: classes.selected }}
                value={item}
                selected={orthoLayer === item}
                onChange={(event) => changeOrthoLayer(item)}
            >
                <img src={orthoLayerImage(item)} height={24} width={24} alt={title} />
            </ToggleButton>)
        }

        return buttons
    }

    return <>
        <ClickAwayListener onClickAway={() => popupState.close()}>
            <div>
                <ToggleButton
                    value="orthoLayer"
                    classes={{ selected: classes.selected }}
                    selected={false}
                    title={t('model.controls.ortho_layer.label') as string}
                    style={{ padding: '11px' }}
                    {...bindTrigger(popupState)}
                >
                    <Layers />
                </ToggleButton>
                <Popper {...bindPopper(popupState)} placement={"right"} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ backgroundColor }}>
                                {renderButtons()}
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    </>
}
