import { Button } from '@mui/material';
import React from 'react'
import Challenge, { PlainChallenge } from '../../Challenge';
import ChallengeManager from '../../managers/ChallengeManager';
import ChallengeExportDialog from '../editor/ChallengeExportDialog';
import ChallengeImportDialog from '../editor/ChallengeImportDialog';

interface Props {

}

interface State {
  importOpen: boolean
  exportOpen: boolean
  exportContent: string
}

class ImportExport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      importOpen: false,
      exportOpen: false,
      exportContent: ''
    };

    this.openSave = this.openSave.bind(this);
    this.openExport = this.openExport.bind(this);
    this.closeExport = this.closeExport.bind(this);
    this.openImport = this.openImport.bind(this);
    this.closeImport = this.closeImport.bind(this);
    this.onImport = this.onImport.bind(this);
  }

  openExport() {
    const challenge = ChallengeManager.getInstance().challenge;
    if (challenge === undefined) return;

    // const serialized = challenge.toJSON();
    const content = JSON.stringify(challenge, null, 2);

    this.setState({
      exportOpen: true,
      exportContent: content
    });
  }

  openSave() {
    const challenge = ChallengeManager.getInstance().challenge;
    if (challenge === undefined) return;

    challenge.save();

    const content = JSON.stringify(challenge, null, 2);

    this.setState({
      exportOpen: true,
      exportContent: content
    });
  }

  closeExport() {
    this.setState({
      exportOpen: false,
      exportContent: ''
    });
  }

  openImport() {
    this.setState({
      importOpen: true
    });
  }

  onImport(importContent: string) {
    try {
      const plain = JSON.parse(importContent) as PlainChallenge;
      const challenge = Challenge.deserialize(plain);
      ChallengeManager.getInstance().challenge = challenge;
    } catch {
      return false;
    }

    return true;
  }

  closeImport() {
    this.setState({
      importOpen: false
    });
  }

  render() {
    return <div>
      <h3>Import/export</h3>
      <div>
        <Button variant="outlined" onClick={this.openSave}>Export (with SaveData)</Button>
        <Button variant="outlined" onClick={this.openExport}>Export</Button>
        <Button variant="outlined" onClick={this.openImport}>Import</Button>
      </div>
      <ChallengeImportDialog
        open={this.state.importOpen}
        onClose={this.closeImport}
        onImport={this.onImport}
      />
      <ChallengeExportDialog
        open={this.state.exportOpen}
        content={this.state.exportContent}
        onClose={this.closeExport}
      />
    </div>
  }
}

export default ImportExport;
