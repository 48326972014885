import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, List, ListItem, ListItemText } from "@mui/material"
import { EWAModelWrapper } from "./EWAModelWrapper"
import { Close, Replay } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { EWAModelVersionDto, ModellingService } from "../../api/client"
import { useSnackbar } from "notistack"

export interface ModelVersionDialogProps {
    onClose: () => void
    modelWrapper: EWAModelWrapper
    loadModelData: (modelId: string, versionId?: string) => Promise<void>
}

export default function ModelVersionDialog(props: ModelVersionDialogProps) {
    const {
        onClose,
        modelWrapper,
        loadModelData
    } = props

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [versions, setVersions] = useState<EWAModelVersionDto[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const modelId = modelWrapper.model.meta!.id

    async function loadVersions() {
        setLoading(true)
        try {
            const versions = await ModellingService.modelVersionsV1ModelsModelIdVersionsGet(modelId)
            setVersions(versions)
        } catch(e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        }
        setLoading(false)
    }

    useEffect(() => {
        loadVersions()
    }, [])

    function onVersionButtonClick(version?: string) {
        loadModelData(modelId, version)
        onClose()
    }

    function renderVersionList() {
        return <List>
            {versions.map((version) => (
                <ListItem
                    key={version.id}
                    secondaryAction={
                        <IconButton onClick={() => onVersionButtonClick(version.version)}>
                            <Replay />
                        </IconButton>
                    }

                >
                    <ListItemText primary={version.version} secondary={version.comment} />
                </ListItem>
            ))}
        </List>
    }

    return <Dialog open maxWidth="xs" fullWidth onClose={() => onClose()}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {t('model.version_dialog.title')}
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {loading && <LinearProgress variant="indeterminate" />}
            {renderVersionList()}
        </DialogContent>
    </Dialog>
}
