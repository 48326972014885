import { ReactNode, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MRT_TableInstance, MRT_Cell} from 'material-react-table';
import { useTranslation } from "react-i18next";
import { ComponentTableProps, filterAndMapComponents, TableFeature } from "./Table";
import { ReservoirConditionProps, ReservoirTimedProps } from "../../../api/client";
import { EWAComponentType } from "../../../data/EWAComponentType";
import parseFloatOrUndefined from "../../../utils/parseFloatOrUndefined";
import validNumber from "../../../utils/validNumber";
import { minYear } from "../../../utils/constants";
import { Reservoir } from "../../../data/Reservoir";
import { ReservoirApply } from "../../forms/ReservoirApply";
import { EWAModelWrapper } from "../EWAModelWrapper";
import RowActions from "./RowActions";

type ReservoirTableFeature = TableFeature<ReservoirTimedProps, ReservoirConditionProps>

interface ValidationErrors {
  head?: string
}

let isFirstYear = true
let currentDate = minYear
function validateRequiredNonNegative(value: string): boolean {
  if (isFirstYear || value !== '') {
    const parsed = parseFloatOrUndefined(value)
    if (!validNumber(parsed) || parsed! <= 0) {
      return false
    }
  }
  return true
}

export function ReservoirTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props;
  currentDate = props.currentDate
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })
  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<ReservoirTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
  }, [currentDate])

  const columns = useMemo<MRT_ColumnDef<ReservoirTableFeature>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id'
      },
      {
        header: `${t('model.components.reservoir.head')} (m)`,
        accessorKey: 'timedProps.head',
        Cell: ({ cell }) =>  renderNumber(cell.getValue<number|undefined>()),
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.head,
          helperText: validationErrors.head,
          type: 'number',
          onChange(event) {
            const value = event.target.value
            let error: string | undefined = undefined
            if (!validateRequiredNonNegative(value)) {
              error = t('validation.invalid_value') as string
            }
            setValidationErrors((prev) => ({ ...prev, head: error }))
          }
        }
      },
    ],
    [i18n.language, validationErrors]
  )

  const tableData = useMemo<ReservoirTableFeature[]>(() => {
    const tableData = filterAndMapComponents<ReservoirTimedProps, ReservoirConditionProps>(
      data.features,
      EWAComponentType.reservoir,
      currentDate,
      { head: 0 },
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow (props: {
    row: MRT_Row<ReservoirTableFeature>,
    table: MRT_TableInstance<ReservoirTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow (props: {
    exitEditingMode: () => void,
    row: MRT_Row<ReservoirTableFeature>,
    table: MRT_TableInstance<ReservoirTableFeature>,
    values: Record<string, any>
  }) {
    const {values, row, exitEditingMode} = props
    // first validate values
    if (values['id'] === '' ||!validateRequiredNonNegative(values['timedProps.head'])) {
      return
    }

    // create the Apply object
    const reservoir = row.original.feature as Reservoir

    const timed: Partial<ReservoirTimedProps> = {
    }

    const head = parseFloatOrUndefined(values['timedProps.head'])
    if (head !== undefined) {
      timed.head = head
    }

    const apply: ReservoirApply = {
      id: values['id'],
      comment: reservoir.comment,
      lifetime: reservoir.data.lifetime,
      longitude: reservoir._geometry.coordinates[0],
      latitude: reservoir._geometry.coordinates[1],
      elevation: reservoir._geometry.coordinates[2],
      strength: reservoir.data.properties.strength,
      conditional: {
      },
      timed
    }

    reservoir.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    exitEditingMode()
    setValidationErrors({})
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<ReservoirTableFeature>;
      row: MRT_Row<ReservoirTableFeature>;
      table: MRT_TableInstance<ReservoirTableFeature>;
    }): ReactNode => <RowActions<ReservoirTableFeature>
     {...props}
     onSaveRow={onSaveRow}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
