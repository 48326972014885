import { Close } from "@mui/icons-material"
import { Box, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EWAResult, EWASimulationDTO, ModellingService } from "../../../api/client"
import { useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import ReactJson from "react-json-view"

export interface SimulationResultsDetailsDialogProps {
  ewaSimulation: EWASimulationDTO
  onClose: () => void
}

export default function SimulationResultsDetailsDialog(props: SimulationResultsDetailsDialogProps) {
  const {
    onClose,
    ewaSimulation
  } = props

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [ewaResult, setEwaResult] = useState<EWAResult | undefined>()

  useEffect(() => {
    loadEWAResult()
  }, [ewaSimulation.id])

  async function loadEWAResult() {
    setLoading(true)
    try {
      const ewaResult = await ModellingService.simulationResultsV1SimulationsIdResultsGet(ewaSimulation.id)
      setEwaResult(ewaResult)
    } catch (e: any) {
      if (e.message.indexOf("Not found") === -1) {
        enqueueSnackbar(e.message, {
          variant: 'error'
        })
      }
      setEwaResult(undefined)
    }

    setLoading(false)
  }

  function renderJson() {
    if (ewaResult === undefined) {
      return <></>
    }

    return <ReactJson src={ewaResult} theme={"colors"} />
  }

  return <Dialog
    open
    fullWidth
    maxWidth="lg"
    onClose={() => onClose()}
  >
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>{t('simulation_view.simulation_results_details')}</div>
      <IconButton aria-label="close" onClick={() => onClose()}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ height: 4 }}>
        {loading && <LinearProgress></LinearProgress>}
      </Box>
      <Box sx={{ maxHeight: 500, overflow: 'auto' }}>{renderJson()}</Box>
    </DialogContent>
  </Dialog>
}
