import { Reservoir } from "../../data/Reservoir";
import { useState, useEffect } from "react";
import { ReservoirConditionProps, ReservoirTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { ReservoirApply } from "./ReservoirApply";
import { useFormContext } from "./FormContext";
import { TextField, InputAdornment } from "@mui/material";
import { findProp } from "../../utils/findProp";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";

export interface MultiReservoirFormProps {
    reservoirs: Reservoir[]
}

export function MultiReservoirForm(props: MultiReservoirFormProps) {
    const { reservoirs } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(reservoirs, currentDate))
    const [head, setHead] = useState<string>(findTimedMulti({
        items: reservoirs,
        get: _ => _.head
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackHead, setFallbackHead] = useState<number | undefined>(findComputedTimedMulti({
        items: reservoirs,
        get: _ => _.head
    }, context.currentDate, undefined));
    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(reservoirs, currentDate)
        setIsFirstYear(newIsFirstYear)

        setHead(findTimedIfDefinedMulti({
            items: reservoirs,
            get: (props) => props.head
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackHead(findComputedTimedMulti({
            items: reservoirs,
            get: _ => _.head
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
    }, [reservoirs, context.currentDate, context.condition, context.modelWrapper?.id])

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<ReservoirTimedProps> = {}
        const applyHead = parseFloatOrUndefined(head)
        if (applyHead !== undefined) {
            timed.head = applyHead
        }
        for (const reservoir of reservoirs) {
            const geometry = reservoir._geometry
            const apply = {
                id: reservoir.id,
                comment: reservoir.comment,
                lifetime: a.lifetime,
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
                elevation: geometry.coordinates[2],
                strength: findProp({
                    item: reservoir,
                    get: (props) => props.strength
                }, undefined),
                conditional: {} as ReservoirConditionProps,
                timed: timed
            } as ReservoirApply
            reservoir.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function validate() {
        if (isFirstYear || head !== '') {
            const parsedHead = parseFloatOrUndefined(head)
            if (!validNumber(parsedHead) || parsedHead! <= 0) {
                return false
            }
        }

        return true
    }

    return <BaseMultiForm
        features={reservoirs}
        handleApply={handleApply}
        validate={validate}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={head}
                onChange={(event) => setHead(event.target.value)}
                style={{ flexGrow: 1 }}
                label={t('model.components.reservoir.head')}
                InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackHead?.toString()}
            />
        </>}
    >
    </BaseMultiForm>
}
