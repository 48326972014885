import { useState } from "react";
import { TextField } from "@mui/material";
import { PSVTimedProps } from "../../api/client";
import { findTimed, findComputedTimed } from "../../utils/findTimed";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { SingleValveForm } from "./SingleValveForm";
import { singleFormComputeIfFirstYear } from "./util";
import { useTranslation } from "react-i18next";
import { ValvePSV } from "../../data/ValvePSV";

export interface SingleValvePSVFormProps {
    valve: ValvePSV
}

export function SingleValvePSVForm(props: SingleValvePSVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))
    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<PSVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<PSVTimedProps>

    const [pressureLimit, setPressureLimit] = useState<string>(currentTimed.pressure_limit?.toString() ?? '');

    const { t } = useTranslation();

    function updateTimedState(timed: Partial<PSVTimedProps>) {
        setPressureLimit(timed.pressure_limit?.toString() ?? '')
    }

    function validate() {
        if (isFirstYear || pressureLimit !== '') {
            const parsedPressureLimit = parseFloatOrUndefined(pressureLimit)
            if (!validNumber(parsedPressureLimit) || parsedPressureLimit! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PSVTimedProps> {
        const timed: Partial<PSVTimedProps> = {}
        const applyPressureLimit = parseFloatOrUndefined(pressureLimit)
        if (applyPressureLimit !== undefined) {
            timed.pressure_limit = applyPressureLimit
        }
        return timed
    }

    return (<SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(pressureLimit)}
                onChange={(event) => setPressureLimit((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.psv.pressure_limit')}
                helperText={isFirstYear ? undefined : fallbackTimed.pressure_limit?.toString()}
            />
        </>
        }></SingleValveForm>)
}
