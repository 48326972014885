enum OptionsExtrasType {
  Component,
  UpdateNumber,
  UpdateString,
  UpdateBoolean,
  UpdatePipeStatus,
  UpdateLifetime,
  UpdateConsumptionMode,
  ChangeYear,
  ChangeOperatingCondition,
  ChangeComponentVisibility,
  CreateDeleteOperatingCondition,
  ShowResults,
  ChangeTab,
  ChangeOrthoLayer,
  PerformanceIndicator,
  OpenEditDialog,
  OpenSimulationResultsDialog,
  UpdatePipeMaterial
};

export default OptionsExtrasType;
