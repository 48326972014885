import { PipeMaterial } from "@/api/client";
import { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";
import UpdateValueTaskWithoutRelativeOptions from "./UpdateValueTaskWithoutRelativeOptions";

export default class UpdatePipeMaterialTaskOptions extends UpdateValueTaskWithoutRelativeOptions<PipeMaterial>
{
  public constructor(options: IUpdateValueTaskOptions<PipeMaterial> = {}) {
    super(options);
  }

  public static fromPlain(plainOptions?: IUpdateValueTaskOptions<PipeMaterial>) {
    return new UpdatePipeMaterialTaskOptions(plainOptions);
  }
}
