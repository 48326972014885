import { EWAResult } from "../../../api/client";
import { ShowResults } from "../../../data/ShowResults";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { EWAResultType } from "../simulation/EWAResultType";
import getResultWeight from "../util/getResultWeight";

export interface HeatmapLayerProps {
    showResults: ShowResults
    junctions: any[]
    lastResult: EWAResult|undefined
    currentDate: number
    currentCondition: string|undefined
}

export default function createHeatmapLayer(props: HeatmapLayerProps) {
    const {
        showResults,
        junctions,
        lastResult,
        currentDate,
        currentCondition
    } = props

    const layer = new HeatmapLayer({
        id: 'heatmap',
        visible: showResults === ShowResults.HeatMap,
        data: junctions,
        pickable: false,
        getWeight: (feature: any) => {
            if (showResults === ShowResults.HeatMap) {
                if (lastResult !== undefined) {
                    let weights = getResultWeight(lastResult, currentDate, currentCondition, EWAResultType.UnsatisfiedNodes)
                    let value = 0;
                    for (let j = 0; j < weights.length; j++) {
                        if (weights[j] !== undefined) {
                            for (const [key, val] of Object.entries(weights[j])) {
                                if (key === feature.id) {
                                    value = value + val;
                                }
                            }
                        }
                    }

                    return value;
                }
            }
            return 0
        },
        getPosition: (feature: any) => feature.geometry.coordinates,
        aggregation: 'SUM'
    })

    return layer
}
