import TabPanel from "@/widgets/TabPanel"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FeatureProps } from "./Features"

export default function FeatureOverview(props: FeatureProps) {
    const { index, value } = props
    const { t } = useTranslation('landing')

    return <TabPanel value={value} index={index} idPrefix='features-'>
        <Box textAlign="justify">
            <Box component="p" whiteSpace="pre-line">{t('sections.features.overview.intro')}</Box>
            <Typography variant='h5'>{t('sections.features.overview.goals.caption')}</Typography>
            <ul>
                {[...Array(5).keys()].map((i) => <li key={i}>{t(`sections.features.overview.goals.items.${i}`)}</li>)}
            </ul>
        </Box>
    </TabPanel>
}
