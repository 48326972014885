import { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";
import { ConsumptionMode } from "../../../api/client";
import UpdateValueTaskWithoutRelativeOptions from "./UpdateValueTaskWithoutRelativeOptions";

export default class UpdateConsumptionModeTaskOptions extends UpdateValueTaskWithoutRelativeOptions<ConsumptionMode>
{
  public constructor(options: IUpdateValueTaskOptions<ConsumptionMode> = {}) {
    super(options);
  }

  public static fromPlain(plainOptions?: IUpdateValueTaskOptions<ConsumptionMode>) {
    return new UpdateConsumptionModeTaskOptions(plainOptions);
  }
}
