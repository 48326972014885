import { useState } from "react";
import { ValveGPV } from "../../data/ValveGPV";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { GPVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValveGPVFormProps {
    valves: ValveGPV[]
}

export function MultiValveGPVForm(props: MultiValveGPVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [headlossCurve, setHeadlossCurve] = useState<string | undefined>(findTimedMulti({
        items: valves,
        get: _ => _.headloss_curve
    }, context.currentDate, undefined));
    const [fallbackHeadlossCurve, setFallbackHeadlossCurve] = useState<string | undefined>(findComputedTimedMulti({
        items: valves,
        get: _ => _.headloss_curve
    }, context.currentDate, undefined));

    const { t } = useTranslation();

    function updateTimedState() {
        setHeadlossCurve(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.headloss_curve
        }, currentDate, undefined))
        setFallbackHeadlossCurve(findComputedTimedMulti({
            items: valves,
            get: _ => _.headloss_curve
        }, currentDate, undefined))
    }

    function validate() {
        return true
    }

    function computeTimedApply(): Partial<GPVTimedProps> {
        const timed: Partial<GPVTimedProps> = {}

        const applyHeadlossCurve = headlossCurve
        if (applyHeadlossCurve !== undefined) {
            timed.headloss_curve = applyHeadlossCurve
        }

        return timed
    }

    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                <InputLabel id="head-curve">{t('model.components.valve.gpv.head_curve')}</InputLabel>
                <Select
                    labelId="head-curve-label"
                    id="head-curve"
                    value={headlossCurve !== undefined ? headlossCurve : ''}
                    label={t('model.components.valve.gpv.head_curve')}
                    onChange={(event, newValue) => setHeadlossCurve(event.target.value)}
                >
                    {context.modelWrapper?.model?.curves.map((curve, j) => <MenuItem
                        value={curve.id}>{curve.id}</MenuItem>)}
                </Select>
                {isFirstYear && <FormHelperText>{fallbackHeadlossCurve}</FormHelperText>}
            </FormControl>
        </>}></MultiValveForm>
}
