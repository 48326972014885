import { useState, useEffect } from "react";
import { PumpConditionProps, PumpTimedProps } from "../../api/client";
import { Pump } from "../../data/Pump";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { PumpApply } from "./PumpApply";
import { TextField, FormControl, InputAdornment, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { findConditionMulti } from "../../utils/findConditionMulti";
import { useTranslation } from "react-i18next";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import { useFormContext } from "./FormContext";
import { multiFormComputeIfFirstYear } from "./util";
import validNumber from "../../utils/validNumber";

export interface MultiPumpFormProps {
    pumps: Pump[]
}

export function MultiPumpForm(props: MultiPumpFormProps) {
    const { pumps } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(pumps, currentDate))
    const [power, setPower] = useState<string>(findTimedMulti({
        items: pumps,
        get: _ => _.power
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackPower, setFallbackPower] = useState<number | undefined>(findComputedTimedMulti({
        items: pumps,
        get: _ => _.power
    }, context.currentDate, undefined))
    const [headCurve, setHeadCurve] = useState<string | undefined>(findTimedMulti({
        items: pumps,
        get: _ => _.head_curve
    }, context.currentDate, ''));
    const [fallbackHeadCurve, setFallbackHeadCurve] = useState<string | undefined>(findComputedTimedMulti({
        items: pumps,
        get: _ => _.head_curve
    }, context.currentDate, undefined))
    const [status, setStatus] = useState<string>(findConditionMulti({
        items: pumps,
        get: _ => _.status
    }, context.condition, ''));
    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(pumps, currentDate)
        setIsFirstYear(newIsFirstYear)

        setPower(findTimedIfDefinedMulti({
            items: pumps,
            get: (props) => props.power
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackPower(findComputedTimedMulti({
            items: pumps,
            get: _ => _.power
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setHeadCurve(findTimedIfDefinedMulti({
            items: pumps,
            get: (props) => props.head_curve
        }, currentDate, undefined)?.toString() ?? '');
        setFallbackHeadCurve(findComputedTimedMulti({
            items: pumps,
            get: _ => _.head_curve
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setStatus(findConditionMulti({
            items: pumps,
            get: (props) => props.status
        }, context.condition, undefined));
    }, [pumps, context.currentDate, context.condition, context.modelWrapper?.id])

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<PumpTimedProps> = {}
        const applyPower = parseFloatOrUndefined(power)
        if (applyPower !== undefined) {
            timed.power = applyPower
        }
        if (headCurve !== undefined) {
            timed.head_curve = headCurve
        }

        for (const pump of pumps) {
            const apply = {
                id: pump.id,
                comment: pump.comment,
                lifetime: a.lifetime,
                conditional: {
                    status: status
                } as PumpConditionProps,
                timed: timed
            } as PumpApply
            pump.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function validate() {
        if (isFirstYear || power !== '') {
            return validNumber(parseFloatOrUndefined(power))
        }

        return true
    }

    return <BaseMultiForm
        handleApply={handleApply}
        features={pumps}
        validate={validate}
        timedChildren={<>
            <TextField
                type="number"
                value={power}
                onChange={(event) => setPower(event.target.value)}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.pump.power')}
                InputProps={{ endAdornment: <InputAdornment position="end">kW</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackPower?.toString()}
            />
            <FormControl fullWidth>
                <InputLabel id="head-curve">{t('model.components.pump.head_curve')}</InputLabel>
                <Select
                    labelId="head-curve-label"
                    id="head-curve"
                    value={headCurve !== undefined ? headCurve : ''}
                    label={t('model.components.pump.head_curve')}
                    onChange={(event, newValue) => setHeadCurve(event.target.value)}
                >
                    {context.modelWrapper?.model?.curves.map((curve, j) => <MenuItem
                        value={curve.id}>{curve.id}</MenuItem>)}
                </Select>
                {isFirstYear && <FormHelperText>{fallbackHeadCurve}</FormHelperText>}
            </FormControl>
        </>}
        conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}
    ></BaseMultiForm>
}
