import { ClickAwayListener, Fade, Paper, Popper, useTheme } from "@mui/material";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { usePopupState, bindPopper } from "material-ui-popup-state/hooks";
import ComponentAddButton from "./ComponentAddButton";
import ComponentLassoButton from "./ComponentLassoButton";
import ComponentMenuButton from "./ComponentMenuButton";
import { EWAModelWrapper } from "../EWAModelWrapper";
import { ComponentToggleVisibilityButton } from "./ComponentToggleVisibilityButton";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface ComponentMenuProps {
    editModeSelection: number
    selectedComponentButton: EWAComponentType | ValveComponentType | null
    editLassoMode: EWAComponentType | ValveComponentType | null
    editCreateMode: EWAComponentType | ValveComponentType | null
    setComponentAddMode: (_: EWAComponentType | ValveComponentType) => void
    setComponentLassoMode: (_: EWAComponentType | ValveComponentType) => void
    unsetComponentModes: () => void
    economyEnabled: boolean
    componentType: EWAComponentType
    modelWrapper: EWAModelWrapper
    showComponents: EWAComponentType[]
    setShowComponents: (_: EWAComponentType[]) => void
}

export default function ComponentMenu(props: ComponentMenuProps) {
    const {
        selectedComponentButton,
        editCreateMode,
        editLassoMode,
        showComponents,
        setShowComponents,
        economyEnabled,
        componentType,
        modelWrapper
    } = props
    const popupState = usePopupState({ variant: "popper", popupId: componentType })
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    function setComponentAddMode(componentType: EWAComponentType | ValveComponentType) {
        popupState.close()
        props.setComponentAddMode(componentType)
    }
    function setComponentLassoMode(componentType: EWAComponentType | ValveComponentType) {
        popupState.close()
        props.setComponentLassoMode(componentType)
    }

    return <>
        <ClickAwayListener onClickAway={() => popupState.close()}>
            <div>
                <ComponentMenuButton
                    economyEnabled={economyEnabled}
                    editCreateMode={editCreateMode}
                    editLassoMode={editLassoMode}
                    componentType={componentType}
                    popupState={popupState}
                    selectedComponentButton={selectedComponentButton}
                    modelWrapper={modelWrapper}
                />
                <Popper {...bindPopper(popupState)} placement={"right"} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ backgroundColor }}>
                                <ComponentAddButton
                                    componentType={componentType}
                                    setComponentAddMode={setComponentAddMode}
                                    economyEnabled={economyEnabled}
                                />
                                <ComponentLassoButton
                                    componentType={componentType}
                                    setComponentLassoMode={setComponentLassoMode}
                                />
                                <ComponentToggleVisibilityButton
                                    componentType={componentType}
                                    setShowComponents={setShowComponents}
                                    showComponents={showComponents}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    </>
}
