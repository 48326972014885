import { Autocomplete, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import TaskTypeString from "../../../util/TaskTypeString"

interface TaskTypeSelectProps {
  value?: string,
  onValueChange: (_?: string) => void
}

type TypeSelectOption = {
  label: string,
  value: string
}

export default function TaskTypeSelect(props: TaskTypeSelectProps) {
  const { value, onValueChange } = props
  const { t, i18n } = useTranslation()
  const [typeSelectOptions, setTypeSelectOptions] = useState<TypeSelectOption[]>([])

  function isOptionEqualToValue(option: TypeSelectOption, value: TypeSelectOption): boolean {
    return option.value === value.value
  }

  function onTaskTypeSelectChange(option: TypeSelectOption | null) {
    onValueChange(option?.value)
  }

  useEffect(() => {
    const typeSelectOptions: TypeSelectOption[] = []
    for (let type in TaskTypeString) {
      const label = t(`challenge.task.type.${type}`)
      typeSelectOptions.push({
        label,
        value: type
      })
    }

    setTypeSelectOptions(typeSelectOptions)
  }, [i18n.language])

  let autoCompleteValue = null
  if (value !== undefined) {
    autoCompleteValue = {
      label: t(`challenge.task.type.${value}`),
      value
    }
  }

  const helperText = value === undefined ? undefined: t(`challenge.task.task_hint.${value}`)

  return <>
  <Autocomplete
    fullWidth
    value={autoCompleteValue}
    isOptionEqualToValue={isOptionEqualToValue}
    options={typeSelectOptions}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => <TextField {...params} variant="outlined" label={t('challenge.editor.tasks.task_type')} helperText={helperText} />}
    onChange={(event, value) => onTaskTypeSelectChange(value)}
  />
  </>
}
