import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import GPVUpdateCommentTask from "../../tasks/GPVUpdateCommentTask";
import GPVUpdateDiameterTask from "../../tasks/GPVUpdateDiameterTask";
import GPVUpdateHeadCurveTask from "../../tasks/GPVUpdateHeadCurveTask";
import GPVUpdateLifetimeTask from "../../tasks/GPVUpdateLifetimeTask";
import GPVUpdateStatusTask from "../../tasks/GPVUpdateStatusTask";

export default function GPVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new GPVUpdateCommentTask(),
      new GPVUpdateDiameterTask(),
      new GPVUpdateHeadCurveTask(),
      new GPVUpdateLifetimeTask(),
      new GPVUpdateStatusTask(),
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
