import { Close } from "@mui/icons-material"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import DebugPanel from "../../challenges/components/debug/DebugPanel"
import { EWAModelWrapper } from "./EWAModelWrapper"

export interface DebugPanelDialogProps {
    open: boolean
    onClose: () => void
    modelWrapper?: EWAModelWrapper
}

export default function DebugPanelDialog(props: DebugPanelDialogProps) {
    const {
        open,
        onClose,
        modelWrapper
    } = props

    return <Dialog open={open} maxWidth="md" fullWidth onClose={() => onClose()}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            Debug Panel
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DebugPanel model={modelWrapper?.model} />
        </DialogContent>
    </Dialog>
}
