import { EWAComponentType } from "../../../data/EWAComponentType";

function iconFactory(url: string) {
    return {
        url: url,
        x: 0,
        y: 0,
        anchorX: 256,
        anchorY: 256,
        width: 512,
        height: 512,
        mask: false,
    };
}

export function getIcon(obj: any, selectedFeatureIndices: Array<number>, modelWrapper: any) {
    const isSelected = selectedFeatureIndices?.some(i => modelWrapper.model.features[i] === obj)
    switch (obj.componentType as EWAComponentType) {
        case EWAComponentType.junction:
            return isSelected ? iconFactory("../../img/Hovered_Junction_256.png") : iconFactory("../../img/Junction_256.png");
        case EWAComponentType.tank:
            return isSelected ? iconFactory("../../img/Hovered_Tank_256.png") : iconFactory("../../img/Tank_256.png");
        case EWAComponentType.reservoir:
            return isSelected ? iconFactory("../../img/Hovered_Reservoir_256.png") : iconFactory("../../img/Reservoir_256.png");
        case EWAComponentType.spring:
            return isSelected ? iconFactory("../../img/Hovered_Spring_256.png") : iconFactory("../../img/Spring_256.png")
        case EWAComponentType.well:
            return isSelected ? iconFactory("../../img/Hovered_Well_256.png") : iconFactory("../../img/Well_256.png")
    }

    return ''
}
