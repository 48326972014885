import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";

class UpdateBooleanValueTaskOptionsForm extends UpdateValueTaskOptionsForm<boolean> {
  targetValueOrDefault(targetValue?: boolean) {
    return targetValue ?? false;
  }

  renderTargetValueField() {
    return <Box sx={{ display: 'flex', alignItems: 'center', height: '100%'}}>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox
            checked={this.state.targetValue}
            onChange={(e) => this.onTargetValueChange(e.target.checked)}
          />}
          label={this.props.t('challenge.editor.tasks.target_value')}
        />
        <FormHelperText>{this.props.t('challenge.editor.tasks.target_value_hint')}</FormHelperText>
      </FormControl>
    </Box>
  }
}

export default withTranslation('translation', { withRef: true })(UpdateBooleanValueTaskOptionsForm)
