/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for specifying the type of an EWAModel.
 */
export enum EWAModelType {
    CHALLENGE = 'challenge',
    TUTORIAL = 'tutorial',
    USER = 'user',
}