import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import UpdateTaskType from "../misc/UpdateTaskType";
import Task from "./base/Task";
import ChangeComponentVisibilityTaskOptions, { IChangeComponentVisibilityTaskOptions } from "./options/ChangeComponentVisibilityTaskOptions";
import i18n from "../../i18n";
import ComponentVisibilityChangedEvent from "../../events/events/ComponentVisibilityChangedEvent";
import { ewaComponentTypeText } from "../../data/EWAComponentType";

export default class ChangeComponentVisibilityTask extends Task {
  protected _type: string = 'ChangeComponentVisibilityTask';

  public constructor(options?: ChangeComponentVisibilityTaskOptions) {
    if (!options) {
      options = new ChangeComponentVisibilityTaskOptions();
    }

    super(options);

    this.onComponentVisibilityChanged = this.onComponentVisibilityChanged.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IChangeComponentVisibilityTaskOptions) {
    const options = new ChangeComponentVisibilityTaskOptions(plainOptions);
    return new ChangeComponentVisibilityTask(options);
  }

  protected onComponentVisibilityChanged(e: ComponentVisibilityChangedEvent) {
    const options = this._options as ChangeComponentVisibilityTaskOptions;

    if (options.componentType !== undefined && e.detail.componentType !== options.componentType) {
      return
    }

    if (options.updateTaskType === UpdateTaskType.ExactValue && e.detail.visible !==options.visible) {
      return
    }

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ChangeComponentVisibilityTaskOptions;

    let description = '';

    const updateTaskType = options.updateTaskType
    if (options.componentType !== undefined) {
      const componentType = ewaComponentTypeText(options.componentType)
      if (updateTaskType === UpdateTaskType.ExactValue) {
        description = i18n.t(`challenge.task.change_component_visibility.${(options.visible ? 'show' : 'hide')}`, { componentType })
      } else {
        description = i18n.t(`challenge.task.change_component_visibility.toggle`, { componentType })
      }
    } else {
      if (updateTaskType === UpdateTaskType.ExactValue) {
        description = i18n.t(`challenge.task.change_component_visibility.${(options.visible ? 'show' : 'hide')}_any`)
      } else {
        description = i18n.t('challenge.task.change_component_visibility.toggle_any')
      }
    }

    return description;
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ComponentVisibilityChanged, this.onComponentVisibilityChanged);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ComponentVisibilityChanged, this.onComponentVisibilityChanged);
  }

}
