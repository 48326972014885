import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { WellApply } from "../../views/forms/WellApply";
import LifetimeValueUpdateTask from "./base/LifetimeValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateLifetimeTaskOptions from "./options/UpdateLifetimeTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class WellUpdateLifetimeTask extends LifetimeValueUpdateTask<WellApply> {
  protected _type: string = 'WellUpdateLifetimeTask';
  protected componentName: string = "ewa_component_type.Well_one";
  protected fieldName: string = "model.components.lifetime";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number|number[]>) {
    const options = new UpdateLifetimeTaskOptions(plainOptions);
    return new WellUpdateLifetimeTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.WellApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.WellApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<WellApply>): ValuesPair<number|number[]> {
    return {
      newValue: args.apply.lifetime,
      oldValue: args.oldApply.lifetime
    }
  }
}
