import { EWAResult } from "../../api/client";
import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface SimulationNewResultEventArgs {
  model: EWAModel
  lastResult?: EWAResult
}

export default class SimulationNewResultEvent extends CustomEvent<SimulationNewResultEventArgs> {
  public constructor(detail: SimulationNewResultEventArgs) {
    super(EventType.SimulationNewResult, { detail })
  }
}
