import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ChallengeManager from "../../challenges/managers/ChallengeManager";

export interface ChallengeState {
  challengeId?: string
  taskGroupIndex?: number
  openTaskGroupDialog: boolean
  hasDescription: boolean
  showChallengePanel: boolean
  taskGroupCount?: number
  challengeCompleted: boolean
  challengeCompletedPanelOpen: boolean
}

export interface SetChallengeActionPayload {
  challengeId?: string
  taskGroupIndex?: number
  taskGroupCount?: number
  openTaskGroupDialog: boolean
}

const initialState: ChallengeState = {
  challengeId: undefined,
  taskGroupIndex: undefined,
  taskGroupCount: undefined,
  openTaskGroupDialog: false,
  hasDescription: false,
  showChallengePanel: false,
  challengeCompleted: false,
  challengeCompletedPanelOpen: false
}

const challengeSlice = createSlice({
  name: 'challenge',
  initialState: initialState,
  reducers: {
    setChallenge(state, action: PayloadAction<SetChallengeActionPayload>) {
      state.challengeId = action.payload.challengeId
      state.taskGroupIndex = action.payload.taskGroupIndex
      state.taskGroupCount = action.payload.taskGroupCount
      state.openTaskGroupDialog = action.payload.openTaskGroupDialog
      state.showChallengePanel = action.payload.challengeId !== undefined
      // todo: challenge may be completed?
      state.challengeCompleted = false
      state.challengeCompletedPanelOpen = false
    },
    setTaskGroupIndex(state, action: PayloadAction<number | undefined>) {
      const taskGroupIndex = action.payload;
      state.taskGroupCount = taskGroupIndex !== undefined ? taskGroupIndex + 1 : undefined;
      state.taskGroupIndex = taskGroupIndex;
    },
    setHasDescription(state, action: PayloadAction<boolean>) {
      state.hasDescription = action.payload;
    },
    setOpenTaskGroupDialog(state, action: PayloadAction<boolean>) {
      state.openTaskGroupDialog = action.payload
    },
    setShowChallengePanel(state, action: PayloadAction<boolean>) {
      state.showChallengePanel = state.challengeId !== undefined ? action.payload : false
    },
    setChallengeCompleted(state, action: PayloadAction<boolean>) {
      state.challengeCompleted = action.payload
      if (state.challengeCompleted) {
        state.challengeCompletedPanelOpen = true
      }
    },
    setChallengeCompletedPanelOpen(state, action: PayloadAction<boolean>) {
      state.challengeCompletedPanelOpen = action.payload
    },
    increaseTaskGroupIndex(state, action: Action) {
      if (state.taskGroupIndex === undefined ||
        state.taskGroupCount === undefined ||
        state.taskGroupIndex >= state.taskGroupCount - 1) {
        return;
      }

      state.taskGroupIndex = state.taskGroupIndex + 1
      state.hasDescription = ChallengeManager.getInstance().challenge?.taskGroups[state.taskGroupIndex]?.hasDescription as boolean
    },
    decreaseTaskGroupIndex(state, action: Action) {
      if (state.taskGroupIndex === undefined ||
        state.taskGroupCount === undefined ||
        state.taskGroupIndex === 0) {
        return;
      }

      state.taskGroupIndex = state.taskGroupIndex - 1
      state.hasDescription = ChallengeManager.getInstance().challenge?.taskGroups[state.taskGroupIndex]?.hasDescription as boolean
    }
  }
})

export const {
  setChallenge,
  setTaskGroupIndex,
  setOpenTaskGroupDialog,
  setShowChallengePanel,
  setHasDescription,
  increaseTaskGroupIndex,
  decreaseTaskGroupIndex,
  setChallengeCompleted,
  setChallengeCompletedPanelOpen
} = challengeSlice.actions;

export default challengeSlice.reducer;
