import { Box, Grid, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import imgBasicModel from '../../../assets/landing/about/basic_model.png'
import imgChallengeExample from '../../../assets/landing/about/challenge_example.png'
import imgSimResultExample from '../../../assets/landing/about/sim_result_example.png'
import Lightbox from "../../../widgets/Lightbox"

interface AboutRowProps {
    imgSrc: string
    imgAlt: string
    text: string
    textOnLeft?: boolean
}

function AboutRow(props: AboutRowProps) {
    const {
        imgSrc,
        imgAlt,
        text,
        textOnLeft
    } = props

    function renderText(textAlign: string = 'left') {
        return <Box
            display="flex"
            alignItems="center"
            height="100%"
            sx={{ textAlign }}
        >{text}</Box>
    }

    function renderImage() {
        return <Box
            display="flex"
            alignItems="center"
            height="100%"
        >
            <Lightbox
                src={imgSrc}
                alt={imgAlt}
            />
        </Box>
    }

    return <Grid item xs={12}>
        <Grid container columnSpacing={5}>
            <Grid item xs={6}>{textOnLeft ? renderText('right') : renderImage()}</Grid>
            <Grid item xs={6}>{textOnLeft ? renderImage() : renderText()}</Grid>
        </Grid>
    </Grid>
}

export default function About() {
    const { t } = useTranslation('landing')
    return <>
        <Typography variant='h3' sx={{ marginBottom: 5 }}>
            {t('sections.about.header')}
        </Typography>
        <Paper sx={{padding: 5, borderRadius: 3}}>
            <Grid container rowSpacing={5}>
                <AboutRow
                    imgSrc={imgBasicModel}
                    imgAlt="Basic EWA Model"
                    text={t('sections.about.paragraph_1')}
                />
                <AboutRow
                    imgSrc={imgChallengeExample}
                    imgAlt="Challenge Example"
                    text={t('sections.about.paragraph_2')}
                    textOnLeft
                />
                <AboutRow
                    imgSrc={imgSimResultExample}
                    imgAlt="Simulation Result Example"
                    text={t('sections.about.paragraph_3')}
                />
            </Grid>
        </Paper>
    </>
}
