import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import ChangeYearTask from "../../tasks/ChangeYearTask";

export default function CompleteChallengeTest(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new ChangeYearTask()
    ])
  ];

  return new Challenge(taskGroups, new ChallengeOptions({
    completionText: { en: "You have successfully completed the challenge!\n\nCongratulations."},
    targetTime: 2000, // 2s
    score: 500
  }));
}
