import { Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Container, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import DebugChallengeManager, { ChallengeFactory } from '../../util/DebugChallengeManager';
import ChallengeOptionsForm from "./ChallengeOptionsForm";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TaskGroupForm from "./task_group/TaskGroupForm";
import { PlainTaskGroup } from "../../TaskGroup";
import ChallengeImportDialog from "./ChallengeImportDialog";
import ChallengeExportDialog from "./ChallengeExportDialog";
import { PlainChallenge } from "../../Challenge";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import LocaleMenu from "./LocaleMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { clearChallenge, loadChallenge, loadChallengeFromLocalStorage } from "../../../store/slices/challengeEditorSlice";
import ChallengeMetaForm from "./ChallengeMetaForm";

export interface ChallengeEditorProps {
}

export default function ChallengeEditor(props: ChallengeEditorProps) {
  const challenges = DebugChallengeManager.getInstance().challenges;

  const [selectedChallengeFactory, setSelectedChallengeFactory] = useState<ChallengeFactory | undefined>(undefined)
  const [importOpen, setImportOpen] = useState<boolean>(false)
  const [exportOpen, setExportOpen] = useState<boolean>(false)
  const [exportContent, setExportContent] = useState<string>('')
  const [exportMinified, setExportMinified] = useState<boolean>(false)

  const dispatch = useDispatch()

  const challengeOptions = useSelector((state: RootState) => state.challengeEditor.challengeOptions)
  const taskGroups = useSelector((state: RootState) => state.challengeEditor.taskGroups)
  const id = useSelector((state: RootState) => state.challengeEditor.id)
  const requiredChallenges = useSelector((state: RootState) => state.challengeEditor.requiredChallenges)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(loadChallengeFromLocalStorage())
  }, [])

  function openExport() {
    const challenge: PlainChallenge = {
      options: { ...challengeOptions },
      taskGroups: [...taskGroups],
      id,
      requiredChallenges
    }

    const content = JSON.stringify(challenge, null, (exportMinified ? undefined : 2));

    setExportContent(content)
    setExportOpen(true)
  }

  function closeExport() {
    setExportOpen(false)
    setExportContent('')
  }

  function onImport(importContent: string) {
    try {
      const parsedContent = JSON.parse(importContent)
      const plain = (parsedContent.challenges !== undefined ? parsedContent.challenges : parsedContent) as PlainChallenge

      dispatch(loadChallenge({
        challengeOptions: plain.options,
        taskGroups: plain.taskGroups,
        id: plain.id,
        requiredChallenges: plain.requiredChallenges
      }))
    } catch {
      return false;
    }

    return true;
  }

  function loadDebugChallenge() {
    if (selectedChallengeFactory === undefined) return
    const challenge = selectedChallengeFactory.fn();

    const taskGroups: PlainTaskGroup[] = [];
    for (const taskGroup of challenge.taskGroups) {
      taskGroups.push(taskGroup.toJSON())
    }

    dispatch(loadChallenge({
      challengeOptions: challenge.options.toJSON(),
      taskGroups
    }))
  }

  function clearChallengeWrapper() {
    dispatch(clearChallenge())
  }

  function renderChallengeEditor() {
    return <>
      <ChallengeMetaForm />
      <ChallengeOptionsForm />
      <Divider />
      <TaskGroupForm />
    </>
  }


  return <Container>
    <Card sx={{ marginTop: '15px' }}>
      <CardHeader title={t('challenge.editor.title')}></CardHeader>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            options={challenges}
            renderInput={(params) => <TextField {...params} size="small" label={t('challenge.editor.example_challenges')} />}
            value={selectedChallengeFactory ?? null}
            getOptionLabel={option => option.text}
            isOptionEqualToValue={(option, value) => option.fn === value.fn}
            onChange={(e, value) => setSelectedChallengeFactory(value ?? undefined)}
            sx={{ flexGrow: 1 }}
          />
          <Button variant="contained" onClick={loadDebugChallenge} sx={{ height: '40px' }}>{t('general.load')}</Button>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          startIcon={<FileUploadIcon />}
          variant="contained"
          onClick={() => setImportOpen(true)}
        >{t('general.import')}</Button>
        <ButtonGroup sx={{ marginLeft: '8px' }}>
          <Button
            startIcon={<FileDownloadIcon />}
            variant="contained"
            onClick={() => openExport()}
          >{t('general.export')}</Button>
          <Button
            startIcon={(exportMinified ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)}
            variant="contained"
            onClick={() => setExportMinified(!exportMinified)}
          >{t('challenge.editor.minified')}</Button>
        </ButtonGroup>
        <Button startIcon={<ClearIcon />} variant="contained" onClick={() => clearChallengeWrapper()}>{t('general.clear')}</Button>
        <div style={{ flexGrow: 1 }}></div>
        <LocaleMenu />
      </CardActions>
      <Divider />
      <CardContent>
        {renderChallengeEditor()}
      </CardContent>
    </Card>
    <ChallengeImportDialog
      open={importOpen}
      onClose={() => setImportOpen(false)}
      onImport={onImport}
    />
    <ChallengeExportDialog
      open={exportOpen}
      content={exportContent}
      onClose={() => closeExport()}
    />
  </Container>
}

