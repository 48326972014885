import { ModifyChallengeMetaPayload, updateChallengeMeta, setChallengeMetaDirty } from "@/store/slices/challengeEditorSlice";
import { RootState } from "@/store/store";
import { Add, Delete, Save } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState, createRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function ChallengeMetaForm() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isDirty = useSelector((state: RootState) => state.challengeEditor.challengeMetaDirty)
    const setIsDirty = (isDirty: boolean) => dispatch(setChallengeMetaDirty(isDirty))

    const _id = useSelector((_: RootState) => _.challengeEditor.id)
    const [id, setId] = useState<string>(_id)
    const _requiredChallenges = useSelector((_: RootState) => _.challengeEditor.requiredChallenges)
    const [requiredChallenges, setRequiredChallenges] = useState<Set<string>>(new Set(_requiredChallenges))
    const requiredChallengeInputRef = createRef<HTMLInputElement>()

    useEffect(() => {
        setId(_id)
    }, [_id])

    useEffect(() => {
        setRequiredChallenges(new Set(_requiredChallenges))
    }, [_requiredChallenges])

    function handleSave() {
        const payload: ModifyChallengeMetaPayload = {}

        if (id !== '') {
            payload.id = id
        }

        payload.requiredChallenges = Array.from(requiredChallenges)

        dispatch(updateChallengeMeta(payload))
    }

    function addRequiredChallenge() {
        const value = requiredChallengeInputRef.current?.value

        if (!value) {
            return
        }

        requiredChallenges.add(value)

        setRequiredChallenges(new Set(requiredChallenges))
        setIsDirty(true)
        requiredChallengeInputRef.current.value = ''
    }

    function deleteRequiredChallenge(requiredChallenge: string) {
        if (!requiredChallenges.has(requiredChallenge)) {
            return
        }

        requiredChallenges.delete(requiredChallenge)
        setRequiredChallenges(new Set(requiredChallenges))
        setIsDirty(true)
    }

    function renderRequiredChallenges() {
        let requiredChallengesList = <></>

        if (requiredChallenges.size) {
            const items: JSX.Element[] = []
            for (const item of requiredChallenges) {
                // items.push(<ListItem key={item} secondaryAction={<IconButton edge="end"><Delete /></IconButton>}>{item}</ListItem>)
                items.push(<Chip label={item} key={item} onDelete={() => deleteRequiredChallenge(item)} deleteIcon={<Delete />} />)
            }
            requiredChallengesList = <Box display="flex" flexWrap="wrap" gap={1}>{items}</Box>
        }

        return <Box>
            <TextField
                InputProps={{
                    endAdornment: (<InputAdornment position="end"><IconButton edge="end" onClick={() => addRequiredChallenge()}><Add /></IconButton></InputAdornment>)
                }}
                variant="outlined"
                fullWidth
                label={t('challenge.editor.challenge_meta.required_challenges')}
                helperText={t('challenge.editor.challenge_meta.required_challenges_hint')}
                margin="normal"
                inputRef={requiredChallengeInputRef}
            />
            {requiredChallengesList}
        </Box>
    }

    return <Card variant="outlined">
        <CardHeader
            title={t('challenge.editor.challenge_meta.title')}
            action={<Button disabled={!isDirty} onClick={handleSave} variant="contained" title={t('general.save') as string} color="success"><Save /></Button>}
        />
        <CardContent>
            <TextField
                variant="outlined"
                fullWidth
                label={t('challenge.editor.challenge_meta.id')}
                helperText={t('challenge.editor.challenge_meta.id_hint')}
                value={id}
                onChange={(e) => {
                    setIsDirty(true)
                    setId(e.target.value)
                }}
                margin="normal"
            />
            {renderRequiredChallenges()}
        </CardContent>
    </Card>
}
