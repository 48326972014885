import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import PBVUpdateCommentTask from "../../tasks/PBVUpdateCommentTask";
import PBVUpdateDiameterTask from "../../tasks/PBVUpdateDiameterTask";
import PBVUpdateLifetimeTask from "../../tasks/PBVUpdateLifetimeTask";
import PBVUpdatePressureDropTask from "../../tasks/PBVUpdatePressureDropTask";
import PBVUpdateStatusTask from "../../tasks/PBVUpdateStatusTask";

export default function PBVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new PBVUpdateCommentTask(),
      new PBVUpdateDiameterTask(),
      new PBVUpdateLifetimeTask(),
      new PBVUpdatePressureDropTask(),
      new PBVUpdateStatusTask(),
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
