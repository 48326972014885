import TabPanel, { ariaTabProps } from "@/widgets/TabPanel"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FeatureProps } from "./Features"
import { useState } from 'react'
import TankImage from "@/assets/Tank_256.png";
import ReservoirImage from "@/assets/Reservoir_256.png";
import PipeImage from "@/assets/Pipe_256.png";
import PumpImage from "@/assets/Pump_256.png";
import ValveImage from "@/assets/Valve_256.png";
import JunctionImage from "@/assets/Junction_256.png";
import SpringImage from "@/assets/Spring_256.png";
import WellImage from "@/assets/Well_256.png";

export default function FeatureModel(props: FeatureProps) {
    const { index, value } = props
    const { t } = useTranslation('landing')

    const [tab, setTab] = useState(0)

    return <TabPanel value={value} index={index} idPrefix='features-'>
        <Box textAlign="justify">
            <Box component="p" whiteSpace="pre-line">{t('sections.features.model.intro')}</Box>
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                <Tab label={t('sections.features.model.points.header')} {...ariaTabProps(0, 'feature-model-')} />
                <Tab label={t('sections.features.model.links.header')} {...ariaTabProps(1, 'feature-model-')} />
            </Tabs>
            <TabPanel value={tab} index={0} idPrefix='feature-model-'>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.points.junctions.header')}
                        <img src={JunctionImage} height={24} width={24} alt={t('sections.features.model.points.junctions.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.points.junctions.text')}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.points.sources.header')}
                        <img src={WellImage} height={24} width={24} alt={t('sections.features.model.points.sources.wells') as string} />
                        <img src={SpringImage} height={24} width={24} alt={t('sections.features.model.points.sources.springs') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.points.sources.text')}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.points.reservoirs.header')}
                        <img src={ReservoirImage} height="24" width="24" alt={t('sections.features.model.points.reservoirs.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.points.reservoirs.text')}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.points.tanks.header')}
                        <img src={TankImage} height="24" width="24" alt={t('sections.features.model.points.tanks.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.points.tanks.text')}
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={1} idPrefix='feature-model-'>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.links.pipes.header')}
                        <img src={PipeImage} height="24" width="24" alt={t('sections.features.model.links.pipes.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.links.pipes.text')}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.links.pumps.header')}
                        <img src={PumpImage} height="24" width="24" alt={t('sections.features.model.links.pumps.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.links.pumps.text')}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" display="flex" alignItems="center" sx={{ gap: 1 }}>
                        {t('sections.features.model.links.valves.header')}
                        <img src={ValveImage} height="24" width="24" alt={t('sections.features.model.links.valves.header') as string} />
                    </Typography>
                    <Box component="p" whiteSpace="pre-line">
                        {t('sections.features.model.links.valves.text')}
                    </Box>
                    <ul>
                        {(['prv', 'psv', 'pbv', 'fcv', 'tcv', 'gpv'].map(_ => <li key={_}>{t(`sections.features.model.links.valves.${_}`)}</li>))}
                    </ul>
                </Box>
            </TabPanel>
        </Box>
    </TabPanel>
}
