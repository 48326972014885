import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType"
import componentIsValve from "./componentIsValve";
import { EWAModelWrapper } from "../EWAModelWrapper";
import getImageSource from "../util/getImageSource";

export interface ComponentImageProps {
    alt: string
    componentType: EWAComponentType | ValveComponentType
    modelWrapper: EWAModelWrapper
}

export default function ComponentImage(props: ComponentImageProps) {
    const {
        alt,
        componentType,
        modelWrapper
    } = props

    const image = getImageSource(componentType)
    const isValve = componentIsValve(componentType)
    const count = modelWrapper.model.getFeatureCount(componentType)

    return <span>
        <img src={image} height={24} width={24} alt={alt} />
        {isValve && <span style={{ position: 'absolute', left: '0', bottom: '0', fontSize: '8px' }}>{componentType}</span>}
        <span style={{ position: 'absolute', top: '0', right: '0', fontSize: '8px'}}>{count}</span>
    </span>
}
