import TabPanel from "@/widgets/TabPanel"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FeatureProps } from "./Features"

export default function FeatureChallenges(props: FeatureProps) {
    const { index, value } = props
    const { t } = useTranslation('landing')

    return <TabPanel value={value} index={index} idPrefix='features-'>
        <Box textAlign="justify">
            <Box component="p" whiteSpace="pre-line">{t('sections.features.challenges.intro')}</Box>
            <Box component="h4">{t('sections.features.challenges.challenge.header')}</Box>
            <Box component="p" whiteSpace="pre-line">{t('sections.features.challenges.challenge.intro')}</Box>
            <Box component="h4">{t('sections.features.challenges.task_group.header')}</Box>
            <Box component="p" whiteSpace="pre-line">{t('sections.features.challenges.task_group.intro')}</Box>
            <Box component="h4">{t('sections.features.challenges.task.header')}</Box>
            <Box component="p" whiteSpace="pre-line">{t('sections.features.challenges.task.intro')}</Box>
        </Box>
    </TabPanel>
}
