import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType"

export default function componentIsValve(componentType: EWAComponentType | ValveComponentType) {
    return ([
        ValveComponentType.pbv,
        ValveComponentType.psv,
        ValveComponentType.gpv,
        ValveComponentType.fcv,
        ValveComponentType.tcv,
        ValveComponentType.prv
    ] as (EWAComponentType | ValveComponentType)[]).includes(componentType)
}
