import EventType from "../EventType";
import TypedComponentEventArgs from "../args/TypedComponentEventArgsOptions";

export interface ComponentMovedEventArgs extends TypedComponentEventArgs {
  lengthDifferences?: [string, number][]
}

export default class ComponentMovedEvent extends CustomEvent<ComponentMovedEventArgs> {
  public constructor(detail: ComponentMovedEventArgs) {
    super(EventType.ComponentMoved, { detail })
  }
}
