import { useState } from "react";
import { ValvePBV } from "../../data/ValvePBV";
import { TextField } from "@mui/material";
import { PBVTimedProps } from "../../api/client";
import { findTimed, findComputedTimed } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { singleFormComputeIfFirstYear } from "./util";
import { SingleValveForm } from "./SingleValveForm";

export interface SingleValvePBVFormProps {
    valve: ValvePBV
}

export function SingleValvePBVForm(props: SingleValvePBVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))

    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<PBVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<PBVTimedProps>

    const [pressureDrop, setPressureDrop] = useState<string>(currentTimed.pressure_drop?.toString() ?? '');

    const { t } = useTranslation();

    function updateTimedState(timed: Partial<PBVTimedProps>) {
        setPressureDrop(timed.pressure_drop?.toString() ?? '')
    }

    function validate() {
        if (isFirstYear || pressureDrop !== '') {
            const parsedPressureDrop = parseFloatOrUndefined(pressureDrop)

            if (!validNumber(parsedPressureDrop) || parsedPressureDrop! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PBVTimedProps> {
        const timed: Partial<PBVTimedProps> = {}

        const applyPressureDrop = parseFloatOrUndefined(pressureDrop)
        if (applyPressureDrop !== undefined) {
            timed.pressure_drop = applyPressureDrop
        }

        return timed
    }

    return (<SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(pressureDrop)}
                onChange={(event) => setPressureDrop((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.pbv.pressure_drop')}
                helperText={isFirstYear ? undefined : fallbackTimed.pressure_drop?.toString()}
            />
        </>}></SingleValveForm>)
}
