import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ConsumptionMode, JunctionConditionProps, JunctionTimedProps } from "../../api/client";
import { Junction } from "../../data/Junction";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { JunctionApply } from "./JunctionApply";
import { BaseSinglePointForm, BaseSinglePointFormApply } from "./BaseSinglePointForm";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import textFromNumber from "../../utils/textFromNumber";
import { useFormContext } from "./FormContext";
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from "@mui/icons-material";
import { findCondition } from "../../utils/findCondition";

export interface SingleJunctionFormProps {
    junction: Junction
}

export function SingleJunctionForm(props: SingleJunctionFormProps) {
    const { junction } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = junction.data.lifetime as number|number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: junction,
        get: _ => _
    }, currentDate, {}) as Partial<JunctionTimedProps>
    const fallbackTimed = findComputedTimed({
        item: junction,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<JunctionTimedProps>


    const [demand, setDemand] = useState<string>(currentTimed.demand?.toString() ?? '');
    const [fireflow, setFireFlow] = useState<number | undefined>(findCondition({
        item: junction,
        get: _ => _.fireflow
    }, context.condition, undefined));
    const [scaleDemand, setScaleDemand] = useState<boolean|undefined>(currentTimed.scale_demand);
    const [mode, setMode] = useState<ConsumptionMode | undefined>(currentTimed.mode);

    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: junction,
            get: _ => _
        }, currentDate, {}) as Partial<JunctionTimedProps>

        setScaleDemand(timed.scale_demand)
        setDemand(timed.demand?.toString() ?? '')
        setMode(timed.mode)

        setFireFlow(findCondition({
            item: junction,
            get: _ => _.fireflow
        }, context.condition, undefined));

    }, [junction, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || demand !== '') {
            return validNumber(parseFloatOrUndefined(demand))
        }

        if (fireflow !== undefined) {
            return validNumber(fireflow)
        }

        return true
    }

    function handleApply(a: BaseSinglePointFormApply) {
        const timed: Partial<JunctionTimedProps> = {
            emittercoefficient: currentTimed.emittercoefficient,
            mode: mode
            // demand: parseFloatOrUndefined(demand),
            // scale_demand: scaleDemand
        }

        const applyDemand = parseFloatOrUndefined(demand)
        if (applyDemand !== undefined) {
            timed.demand = applyDemand
        }

        if (scaleDemand !== undefined) {
            timed.scale_demand = scaleDemand
        }

        const apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            latitude: a.latitude,
            longitude: a.longitude,
            elevation: a.elevation,
            strength: findProp({ item: junction, get: _ => _.strength }, undefined),
            conditional: {
                fireflow: fireflow
            } as JunctionConditionProps,
            timed: timed
        } as JunctionApply
        junction.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function fallbackScaleDemandIcon(fallbackScaleDemand?: boolean) {
        if (fallbackScaleDemand === true) {
            return <CheckBox />
        } else if (fallbackScaleDemand === false) {
            return <CheckBoxOutlineBlank />
        } else {
            return <IndeterminateCheckBox />
        }
    }

    return (<BaseSinglePointForm validate={validate} handleApply={handleApply} feature={junction} timedChildren={<>
        <TextField
            required
            type="number"
            value={demand}
            onChange={(event) => setDemand(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.junction.demand') as string}
            InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.demand?.toString()}
        />
        <FormControl>
            <FormControlLabel
                style={{ flexGrow: 1 }}
                control={<Checkbox
                    checked={scaleDemand === true}
                    indeterminate={scaleDemand === undefined}
                    onChange={() => {
                        if (scaleDemand === undefined) setScaleDemand(true)
                        else if (scaleDemand) setScaleDemand(false)
                        else setScaleDemand(undefined)
                    }}
                />}
                label={t('model.components.junction.scale_demand') as string}
            />
            {!isFirstYear && <FormHelperText>{fallbackScaleDemandIcon(fallbackTimed.scale_demand)}</FormHelperText>}
        </FormControl>
        {/* TODO: replace with proper menu for customer types*/}
        <FormControl fullWidth style={{ display: "none" }}>
            <InputLabel id="junction-mode">{t('model.components.junction.mode')}</InputLabel>
            <Select
                labelId="junction-mode-label"
                id="junction-mode"
                value={mode ?? ""}
                label="Junction Mode"
                onChange={(event, newValue) => setMode(event.target.value as ConsumptionMode)}
            >
                <MenuItem value=""></MenuItem>
                <MenuItem value={ConsumptionMode.CLASSIC}>{ConsumptionMode.CLASSIC}</MenuItem>
                <MenuItem value={ConsumptionMode.EWA}>{ConsumptionMode.EWA}</MenuItem>
            </Select>
        </FormControl>
    </>
    }
        conditionChildren={
            <TextField type="number" value={textFromNumber(fireflow)}
                onChange={(event) => setFireFlow(parseFloatOrUndefined(event.target.value))}
                style={{ flexGrow: 1 }} label={t('model.components.junction.fireflow') as string} InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }} />
        }>
    </BaseSinglePointForm>)
}
