import { useState } from "react";
import { ValveTCV } from "../../data/ValveTCV";
import { TextField } from "@mui/material";
import { TCVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValveTCVFormProps {
    valves: ValveTCV[]
}

export function MultiValveTCVForm(props: MultiValveTCVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [headlossCoefficient, setHeadlossCoefficient] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.headloss_coefficient
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackHeadlossCoefficient, setFallbackHeadlossCoefficient] = useState<number | undefined>(findTimedMulti({
        items: valves,
        get: _ => _.headloss_coefficient
    }, context.currentDate, undefined));

    const { t } = useTranslation();

    function updateTimedState() {
        setHeadlossCoefficient(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.headloss_coefficient
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackHeadlossCoefficient(findComputedTimedMulti({
            items: valves,
            get: _ => _.headloss_coefficient
        }, currentDate, undefined))
    }

    function validate() {
        if (isFirstYear || headlossCoefficient !== '') {
            const parsedHeadlossCoefficient = parseFloatOrUndefined(headlossCoefficient)

            if (!validNumber(parsedHeadlossCoefficient) || parsedHeadlossCoefficient! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<TCVTimedProps> {
        const timed: Partial<TCVTimedProps> = {}

        const applyHeadlossCoefficient = parseFloatOrUndefined(headlossCoefficient)
        if (applyHeadlossCoefficient !== undefined) {
            timed.headloss_coefficient = applyHeadlossCoefficient
        }

        return timed
    }

    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(headlossCoefficient)}
                onChange={(event) => setHeadlossCoefficient((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.tcv.headloss_coefficient')}
                helperText={isFirstYear ? undefined : fallbackHeadlossCoefficient?.toString()}
            />
        </>}></MultiValveForm>
}
