import { Fade, Paper, Popper, useTheme } from "@mui/material";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { usePopupState, bindPopper, PopupState } from "material-ui-popup-state/hooks";
import ComponentAddButton from "./ComponentAddButton";
import ComponentLassoButton from "./ComponentLassoButton";
import ComponentMenuButton from "./ComponentMenuButton";
import { EWAModelWrapper } from "../EWAModelWrapper";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface ComponentSubMenuProps {
    selectedComponentButton: EWAComponentType | ValveComponentType | null
    editLassoMode: EWAComponentType | ValveComponentType | null
    editCreateMode: EWAComponentType | ValveComponentType | null
    setComponentAddMode: (_: EWAComponentType | ValveComponentType) => void
    setComponentLassoMode: (_: EWAComponentType | ValveComponentType) => void
    economyEnabled: boolean
    componentType: EWAComponentType|ValveComponentType
    parentPopupState: PopupState,
    modelWrapper: EWAModelWrapper
}

export default function ComponentSubMenu(props: ComponentSubMenuProps) {
    const {
        selectedComponentButton,
        editCreateMode,
        editLassoMode,
        economyEnabled,
        componentType,
        parentPopupState,
        modelWrapper
    } = props
    const popupState = usePopupState({ variant: 'popper', popupId: `${parentPopupState.popupId}-${componentType}`, parentPopupState})
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    function setComponentAddMode(componentType: EWAComponentType|ValveComponentType) {
        popupState.toggle()
        props.setComponentAddMode(componentType)
    }

    function setComponentLassoMode(componentType: EWAComponentType|ValveComponentType) {
        popupState.toggle()
        props.setComponentLassoMode(componentType)
    }

    return <>
        <ComponentMenuButton
            economyEnabled={economyEnabled}
            editCreateMode={editCreateMode}
            editLassoMode={editLassoMode}
            componentType={componentType}
            popupState={popupState}
            selectedComponentButton={selectedComponentButton}
            modelWrapper={modelWrapper}
        />
        <Popper {...bindPopper(popupState)} placement={"right"} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper sx={{ backgroundColor }}>
                        <ComponentAddButton
                            componentType={componentType}
                            setComponentAddMode={setComponentAddMode}
                            economyEnabled={economyEnabled}
                        />
                        <ComponentLassoButton
                            componentType={componentType}
                            setComponentLassoMode={setComponentLassoMode}
                        />
                    </Paper>
                </Fade>
            )}
        </Popper>
    </>
}
