import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskGroup from "../../TaskGroup";
import ChangeComponentVisibilityTask from "../../tasks/ChangeComponentVisibilityTask";
import ChangeComponentVisibilityTaskOptions from "../../tasks/options/ChangeComponentVisibilityTaskOptions";

export default function ChangeComponentVisibility(): Challenge {
  const taskGroups = [
    new TaskGroup([
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.Any
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.Any,
        componentType: EWAComponentType.junction
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        visible: true
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        visible: false
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.junction,
        visible: true
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.junction,
        visible: false
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.tank
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.reservoir
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.pipe
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.pump
      })),
      new ChangeComponentVisibilityTask(new ChangeComponentVisibilityTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        componentType: EWAComponentType.valve
      })),
    ])
  ];

  return new Challenge(taskGroups);
}
