import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { EWAComponentType } from "../../../../data/EWAComponentType";
import { IOpenEditDialogTaskOptions } from "../../../tasks/options/OpenEditDialogTaskOptions";
import YearSelect from "./common_inputs/YearSelect";
import ComponentTypeSelect from "./common_inputs/ComponentTypeSelect";
import IdInput from "./common_inputs/IdInput";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  id?: string
  year?: number
  componentType?: EWAComponentType
}

class OpenEditDialogTaskOptionsForm extends TaskOptionsExtrasForm<State, IOpenEditDialogTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IOpenEditDialogTaskOptions>) {
    super(props);

    this.renderIdField = this.renderIdField.bind(this)
    this.renderYearField = this.renderYearField.bind(this)
    this.renderComponentTypeField = this.renderComponentTypeField.bind(this)
  }

  stateFromProps(props: TaskSubformPropsAlias<IOpenEditDialogTaskOptions>): State {
    return {
      id: props.options.id,
      year: props.options.year,
      componentType: props.options.componentType
    };
  }

  public extractOptions() {
    return {
      id: this.state.id,
      year: this.state.year,
      componentType: this.state.componentType
    }
  }

  renderIdField() {
    return <IdInput value={this.state.id} onChange={(_) => this.setState({id:_})}/>
  }

  renderYearField() {
    return <YearSelect year={this.state.year} onYearChange={(_?: number) => this.setState({ year: _ })} />
  }

  renderComponentTypeField() {
    return <ComponentTypeSelect
      componentType={this.state.componentType}
      onChangeComponentType={(_?: EWAComponentType) => this.setState({ componentType: _ })}
    />
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderIdField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderYearField()}</Grid>
      <Grid item xs={12}>{this.renderComponentTypeField()}</Grid>
    </Grid>;
  }
}

export default withTranslation('translation', { withRef: true })(OpenEditDialogTaskOptionsForm)
