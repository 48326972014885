import ComponentMovedEvent from "../../events/events/ComponentMovedEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import ComponentTaskOptions, { IComponentTaskOptions } from "./options/ComponentTaskOptions";
import i18n from "../../i18n";

export default class MoveComponentTask extends Task {
  protected _type: string = 'MoveComponentTask';
  public get optionYear(): string | undefined {
    const year = (this._options as ComponentTaskOptions).year;

    return year !== undefined ? year.toString() : year;
  }

  public constructor(options?: ComponentTaskOptions) {
    if (!options) {
      options = new ComponentTaskOptions();
    }

    super(options);

    this.onComponentMoved = this.onComponentMoved.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IComponentTaskOptions) {
    const options = new ComponentTaskOptions(plainOptions);
    return new MoveComponentTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ComponentMoved, this.onComponentMoved);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ComponentMoved, this.onComponentMoved);
  }

  public get description(): string {
    const options = this._options as ComponentTaskOptions;

    const componentType = options.componentType === undefined ? i18n.t('ewa_component_type.any') : i18n.t(`ewa_component_type.${options.componentType}_other`)

    return i18n.t('challenge.task.move_component', {
      component: componentType
    })
  }

  protected onComponentMoved(e: ComponentMovedEvent) {
    const options = this._options as ComponentTaskOptions;
    if (options.componentType !== undefined && e.detail.type !== options.componentType)
      return;

    if (options.year !== undefined && e.detail.year !== options.year)
      return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }
}
