import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, SnackbarCloseReason, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
  onClose: () => void
  onImport: (content: string) => boolean
  open: boolean
}

interface State {
  snackbarOpen: boolean
  importSuccess: boolean
  content: string
}

class ChallengeImportDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      importSuccess: false,
      content: ''
    };

    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }

  handleClose() {
    this.setState({
      content: ''
    });

    this.props.onClose();
  }

  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({
      content: e.target.value
    });
  }

  onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) return;

    const reader = new FileReader();

    reader.onload = (event) => {
      const content: string | ArrayBuffer = event.target?.result ?? '';

      if (content instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        this.setState({
          content: decoder.decode(content)
        });
      } else {
        this.setState({
          content
        });
      }
    };

    reader.readAsText(e.target.files[0]);
  }

  handleImport() {
    const success = this.props.onImport(this.state.content);

    this.setState({
      importSuccess: success,
      snackbarOpen: true
    });
  }

  closeSnackbar(event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false
    });
  }

  render() {
    const { open, t } = this.props;
    const { content, snackbarOpen, importSuccess } = this.state;

    return <>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>{t('challenge.editor.import.title')}</span>
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField fullWidth multiline rows={20} value={content} onChange={this.onChange} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" component="label" startIcon={<FileUploadIcon />} sx={{ marginRight: '8px' }}>
            {t('general.upload')} <input hidden accept="application/json" type="file" onChange={this.onFileChange} />
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} onClick={this.handleImport}>{t('general.import')}</Button>
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={() => this.setState({ content: '' })}>{t('general.clear')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={this.closeSnackbar}>
        <Alert
          severity={importSuccess ? 'success' : 'error'}
          onClose={(event) => this.closeSnackbar(event)}
        >
          {importSuccess ? "Successfully imported JSON." : "Import failed."}
        </Alert>
      </Snackbar>
    </>
  }
}

export default withTranslation()(ChallengeImportDialog)
