import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import logo from "@/assets/logo.png";

export interface IntroProps {

}

export default function Intro(props: IntroProps) {
    const { t } = useTranslation('landing')
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 224px)">
        <Box textAlign="center">
            <Box>
            <img src={logo} alt="EWA Logo" className="responsive-img" style={{ maxHeight: '400px' }} />
            </Box>
            <Typography variant="h1">
                {t('sections.intro.header')}
            </Typography>
            <Typography variant="h5">{t('sections.intro.subheader')}</Typography>
        </Box>
    </Box>
}
