import { useTranslation } from "react-i18next";
import ModelList from "./ModelList"
import { FilteredModelsProps } from "./Models"
import { useState } from 'react'
import { NewModelDialog } from "./common/NewModelDialog";
import { EWAUploadDialog } from "./common/EWAUploadDialog";
import { FileUpload, NoteAdd } from "@mui/icons-material";
import { Box, Button, ButtonGroup } from "@mui/material";
import { EWAModelType } from "@/api/client";

export default function TutorialModels(props: FilteredModelsProps) {
    const {
        isLoading,
        models,
        selection,
        setSelection,
        state,
        setState,
        accessibleModels,
        challengeToModelName,
        fetchModels
    } = props

    const [uploadEWADialogOpen, setUploadEWADialogOpen] = useState(false);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const { t } = useTranslation()

    return <>
        <NewModelDialog
            open={newDialogOpen}
            setOpen={setNewDialogOpen}
            setSelection={setSelection}
            fetchModels={fetchModels}
            type={EWAModelType.TUTORIAL}
        />
        <EWAUploadDialog
            open={uploadEWADialogOpen}
            setOpen={setUploadEWADialogOpen}
            setSelection={setSelection}
            fetchModels={fetchModels}
            type={EWAModelType.TUTORIAL}
        />
        <ModelList
            isLoading={isLoading}
            models={models}
            selection={selection}
            setSelection={setSelection}
            state={state}
            setState={setState}
            accessibleModels={accessibleModels}
            challengeToModelName={challengeToModelName}
            fetchModels={fetchModels}
            actions={<Box sx={{ width: '100%', minHeight: 44, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <ButtonGroup>
                    <Button
                        title={t('home.model_chooser.btns.create') as string}
                        onClick={() => setNewDialogOpen(true)}
                        variant="contained"
                    >
                        <NoteAdd />
                    </Button>
                    <Button
                        title={t('home.model_chooser.btns.upload_ewa') as string}
                        onClick={() => setUploadEWADialogOpen(true)}
                        variant="contained"
                    >
                        <FileUpload />
                    </Button>
                </ButtonGroup>
            </Box>}
        />
    </>
}
