import { ReactNode, useEffect, useMemo, useState } from 'react'
import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MRT_Cell, MRT_TableInstance } from 'material-react-table';
import { ComponentTableProps, TableFeature, filterAndMapComponents } from "./Table";
import { PumpTimedProps, PumpConditionProps } from '../../../api/client';
import { useTranslation } from 'react-i18next';
import { EWAComponentType } from '../../../data/EWAComponentType';
import { minYear } from '../../../utils/constants';
import { Pump } from '../../../data/Pump';
import parseFloatOrUndefined from '../../../utils/parseFloatOrUndefined';
import { PumpApply } from '../../forms/PumpApply';
import { EWAModelWrapper } from '../EWAModelWrapper';
import validNumber from '../../../utils/validNumber';
import RowActions from './RowActions';
import { findTimed } from '../../../utils/findTimed';

type PumpTableFeature = TableFeature<PumpTimedProps, PumpConditionProps>

interface ValidationErrors {
  power?: string
}

let isFirstYear = true
let currentDate = minYear

function validateNumber(value: string): boolean {
  if (isFirstYear || value !== '') {
    const parsed = parseFloatOrUndefined(value)
    if (!validNumber(parsed)) {
      return false
    }
  }
  return true
}

export function PumpTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props;
  currentDate = props.currentDate
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })

  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<PumpTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
  }, [currentDate])

  const headCurveOptions = useMemo(() => {
    return data.curves.map((_) => {
      return {
        text: _.id,
        value: _.id
      }
    })
  }, [data.curves])

  const pipeStatusOptions = useMemo(() => [
    {
      text: t('model.components.pipe.status.OPEN'),
      value: 'OPEN'
    },
    {
      text: t('model.components.pipe.status.CLOSED'),
      value: 'CLOSED'
    },
  ], [i18n.language])

  const columns = useMemo<MRT_ColumnDef<PumpTableFeature>[]>(
    () => {
      return [
        {
          header: 'ID',
          accessorKey: 'id'
        },
        {
          header: `${t('model.components.pump.power')} (kW)`,
          accessorKey: 'timedProps.power',
          accessorFn: (row) => row.timedProps.power ?? 0,
          Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          muiTableBodyCellEditTextFieldProps: {
            error: !!validationErrors.power,
            helperText: validationErrors.power,
            type: 'number',
            onChange(event) {
              const value = event.target.value
              let error: string | undefined = undefined
              if (!validateNumber(value)) {
                error = t('validation.invalid_value') as string
              }
              setValidationErrors((prev) => ({ ...prev, power: error }))
            }
          }
        },
        {
          header: t('model.components.pump.head_curve') as string,
          accessorKey: 'timedProps.head_curve',
          accessorFn: (row) => row.timedProps.head_curve ?? '',
          filterVariant: 'select',
          filterSelectOptions: headCurveOptions,
          editVariant: 'select',
          editSelectOptions: [
            { text: t('general.unset'), value: ''},
            ...headCurveOptions
          ]
        },
        {
          header: t('model.components.pipe.status.label') as string,
          accessorKey: 'conditionProps.status',
          accessorFn: (row) => row.conditionProps.status ?? '',
          filterVariant: 'select',
          filterSelectOptions: pipeStatusOptions,
          Cell: ({ cell }) => renderStatus(cell.getValue<string | undefined>()),
          align: 'center',
          editVariant: 'select',
          editSelectOptions: pipeStatusOptions
        }
      ]
    },
    [i18n.language, headCurveOptions, pipeStatusOptions]
  )

  const tableData = useMemo<PumpTableFeature[]>(() => {
    const tableData = filterAndMapComponents<PumpTimedProps, PumpConditionProps>(
      data.features,
      EWAComponentType.pump,
      currentDate,
      { speed: 0 },
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow (props: {
    row: MRT_Row<PumpTableFeature>,
    table: MRT_TableInstance<PumpTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow (props: {
    exitEditingMode: () => void,
    row: MRT_Row<PumpTableFeature>,
    table: MRT_TableInstance<PumpTableFeature>,
    values: Record<string, any>
  }) {
    const {values, row, exitEditingMode} = props
    // first validate values
    if (values['id'] === '' ||!validateNumber(values['timedProps.power'])) {
      return
    }

    // create the Apply object
    const pump = row.original.feature as Pump

    const currentTimed = findTimed({
      item: pump,
      get: _ => _
    }, currentDate, {}) as Partial<PumpTimedProps>

    const timed: Partial<PumpTimedProps> = {
      speed: currentTimed.speed,
      setting: currentTimed.setting
    }

    const power = parseFloatOrUndefined(values['timedProps.power'])
    if (power !== undefined) {
      timed.power = power
    }

    const headCurve = values['timedProps.head_curve']
    if (headCurve !== '') {
      timed.head_curve = headCurve
    }

    const apply: PumpApply = {
      id: values['id'],
      comment: pump.comment,
      lifetime: pump.data.lifetime,
      conditional: {
        status: values['conditionProps.status']
      },
      timed
    }

    pump.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    exitEditingMode()
    setValidationErrors({})
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  function renderStatus(status?: string) {
    if (status === undefined) return

    return status !== '' ? t(`model.components.pipe.status.${status}`) : ''
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<PumpTableFeature>;
      row: MRT_Row<PumpTableFeature>;
      table: MRT_TableInstance<PumpTableFeature>;
    }): ReactNode => <RowActions<PumpTableFeature>
     {...props}
     onSaveRow={onSaveRow}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
