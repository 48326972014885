import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { Junction } from "../../data/Junction";
import { JunctionApply } from "./JunctionApply";
import { useEffect, useState } from "react";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { findConditionMulti } from "../../utils/findConditionMulti";
import { JunctionConditionProps, JunctionTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import textFromNumber from "../../utils/textFromNumber";
import { useFormContext } from "./FormContext";
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from "@mui/icons-material";
import { multiFormComputeIfFirstYear } from "./util";

export interface MultiJunctionFormProps {
    junctions: Junction[]
}

export function MultiJunctionForm(props: MultiJunctionFormProps) {
    const { junctions } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(junctions, currentDate))
    const [demand, setDemand] = useState<string>(findTimedMulti({
        items: junctions,
        get: (props) => props.demand
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackDemand, setFallbackDemand] = useState<number | undefined>(findComputedTimedMulti({
        items: junctions,
        get: _ => _.demand
    }, currentDate, undefined))
    const [scaleDemand, setScaleDemand] = useState<boolean | undefined>(findTimedMulti({
        items: junctions,
        get: (props) => props.scale_demand
    }, currentDate, undefined));
    const [fallbackScaleDemand, setFallbackScaleDemand] = useState<boolean | undefined>(findComputedTimedMulti({
        items: junctions,
        get: _ => _.scale_demand
    }, currentDate, undefined))
    const [fireflow, setFireFlow] = useState<number | undefined>(findConditionMulti({
        items: junctions,
        get: (props) => props.fireflow
    }, context.condition, undefined));
    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(junctions, currentDate)
        setIsFirstYear(newIsFirstYear)
        setFallbackDemand(findComputedTimedMulti({
            items: junctions,
            get: _ => _.demand
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setFallbackScaleDemand(findComputedTimedMulti({
            items: junctions,
            get: _ => _.scale_demand
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))

        setDemand(findTimedIfDefinedMulti({
            items: junctions,
            get: (props) => props.demand
        }, currentDate, undefined)?.toString() ?? '')
        setScaleDemand(findTimedIfDefinedMulti({
            items: junctions,
            get: (props) => props.scale_demand
        }, currentDate, undefined));
        setFireFlow(findConditionMulti({
            items: junctions,
            get: (props) => props.fireflow
        }, context.condition, undefined));
    }, [junctions, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || demand !== '') {
            return validNumber(parseFloatOrUndefined(demand))
        }

        if (fireflow !== undefined) {
            return validNumber(fireflow)
        }

        return true
    }


    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<JunctionTimedProps> = {}
        const applyDemand = parseFloatOrUndefined(demand)
        if (applyDemand !== undefined) {
            timed.demand = applyDemand
        }
        if (scaleDemand !== undefined) {
            timed.scale_demand = scaleDemand
        }

        for (const junction of junctions) {
            const geometry = junction._geometry
            const apply = {
                id: junction.id,
                comment: junction.comment,
                lifetime: a.lifetime,
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
                elevation: geometry.coordinates[2],
                strength: findProp({
                    item: junction,
                    get: (props) => props.strength
                }, undefined),
                conditional: {
                    fireflow: fireflow
                } as JunctionConditionProps,
                timed: timed
            } as JunctionApply
            junction.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function fallbackScaleDemandIcon(fallbackScaleDemand?: boolean) {
        if (fallbackScaleDemand === true) {
            return <CheckBox />
        } else if (fallbackScaleDemand === false) {
            return <CheckBoxOutlineBlank />
        } else {
            return <IndeterminateCheckBox />
        }
    }

    return <BaseMultiForm handleApply={handleApply}
        features={junctions}
        validate={validate}
        timedChildren={<>
            <TextField
                type="number"
                value={demand}
                onChange={(event) => setDemand((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.junction.demand') as string}
                InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackDemand?.toString()}
            />
            <FormControl>
                <FormControlLabel
                    style={{ flexGrow: 1 }}
                    control={<Checkbox
                        checked={scaleDemand === true}
                        indeterminate={scaleDemand === undefined}
                        onChange={() => {
                            if (scaleDemand === undefined) setScaleDemand(true)
                            else if (scaleDemand) setScaleDemand(false)
                            else setScaleDemand(undefined)
                        }}
                    />}
                    label={t('model.components.junction.scale_demand') as string}
                />
                {!isFirstYear && <FormHelperText>{fallbackScaleDemandIcon(fallbackScaleDemand)}</FormHelperText>}
            </FormControl>
        </>
        }
        conditionChildren={
            <TextField type="number" value={textFromNumber(fireflow)}
                onChange={(event) => setFireFlow(parseFloatOrUndefined(event.target.value))}
                style={{ flexGrow: 1 }} label={t('model.components.junction.fireflow') as string} InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }} />
        }>
    </BaseMultiForm>
}
