import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import FCVUpdateCommentTask from "../../tasks/FCVUpdateCommentTask";
import FCVUpdateDiameterTask from "../../tasks/FCVUpdateDiameterTask";
import FCVUpdateLifetimeTask from "../../tasks/FCVUpdateLifetimeTask";
import FCVUpdateMaximumFlowTask from "../../tasks/FCVUpdateMaximumFlowTask";
import FCVUpdateStatusTask from "../../tasks/FCVUpdateStatusTask";

export default function FCVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new FCVUpdateCommentTask(),
      new FCVUpdateDiameterTask(),
      new FCVUpdateLifetimeTask(),
      new FCVUpdateMaximumFlowTask(),
      new FCVUpdateStatusTask(),
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
