import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface TabChangedEventArgs {
  tab: number
  model: EWAModel
}

export default class TabChangedEvent extends CustomEvent<TabChangedEventArgs> {
  public constructor( detail: TabChangedEventArgs) {
    super(EventType.TabChanged, { detail })
  }
}
