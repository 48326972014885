import i18n from "../i18n"

enum ComparisonMode {
    lt = 'lt',
    lte = 'lte',
    eq = 'eq',
    neq = 'neq',
    gte = 'gte',
    gt = 'gt'
}

export default ComparisonMode

export function comparisonModeToHumanString (mode: ComparisonMode) {
    return i18n.t(`comparison_mode.${mode}`)
}

export function comparisonModeToSymbol (mode: ComparisonMode) {
    switch (mode) {
        case ComparisonMode.lt:
            return '<'
        case ComparisonMode.lte:
            return '≤'
        case ComparisonMode.eq:
            return '='
        case ComparisonMode.neq:
            return '≠'
        case ComparisonMode.gte:
            return '≥'
        case ComparisonMode.gt:
            return '>'
    }
}

export function compare<T> (mode: ComparisonMode, a: T, b:T) {
    switch (mode) {
        case ComparisonMode.lt:
            return a < b
        case ComparisonMode.lte:
            return a <= b
        case ComparisonMode.neq:
            return a !== b
        case ComparisonMode.gte:
            return a >= b
        case ComparisonMode.gt:
            return a > b
        case ComparisonMode.eq:
        default:
            return a === b
    }
}
