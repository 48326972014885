/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PipeMaterial {
    AZ = 'AZ',
    BET = 'BET',
    FZ = 'FZ',
    GFK = 'GFK',
    GG = 'GG',
    GGG = 'GGG',
    PE = 'PE',
    PP = 'PP',
    PVC = 'PVC',
    SO = 'SO',
    ST = 'ST',
    U = 'U',
    VB = 'VB',
}