import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ChallengeManager from "../managers/ChallengeManager";
import CloseIcon from '@mui/icons-material/Close'
import { setOpenTaskGroupDialog } from "../../store/slices/challengeSlice";
import rehypeSanitize from "rehype-sanitize";
import MDEditor from "@uiw/react-md-editor";
import { useTranslation } from "react-i18next";
import TaskGroup from "../TaskGroup";

export interface TaskGroupDialogProps {
}

export default function TaskGroupDialog(props: TaskGroupDialogProps) {
  const { t, i18n } = useTranslation()
  const [description, setDescription] = useState<string>("")
  const [descriptionIndex, setDescriptionIndex] = useState<number>(0)
  const [descriptionCount, setDescriptionCount] = useState<number>(0)
  const [title, setTitle] = useState<string>(t('general.description') as string)

  const challengeId: string | undefined = useSelector(
    (state: RootState) => state.challenge.challengeId
  )

  const taskGroupIndex: number | undefined = useSelector(
    (state: RootState) => state.challenge.taskGroupIndex
  )

  const open: boolean = useSelector(
    (state: RootState) => state.challenge.openTaskGroupDialog
  )

  const dispatch = useDispatch()

  function clearContent() {
    setTitle(t('general.description') as string)
    setDescription("")
    setDescriptionIndex(0)
    setDescriptionCount(0)
  }

  useEffect(() => {
    if (taskGroupIndex === undefined) {
      clearContent();
      return;
    }

    const taskGroup: TaskGroup | undefined = ChallengeManager.getInstance().challenge?.taskGroups[taskGroupIndex]
    if (taskGroup === undefined || !taskGroup.hasDescription) {
      clearContent();
      return;
    }

    setDescriptionIndex(0)
    setDescriptionCount(taskGroup?.options.description.length ?? 0)
    setTitle(taskGroup.name)
  }, [taskGroupIndex, challengeId, dispatch])

  useEffect(() => {
    if (taskGroupIndex !== undefined) {
      const taskGroup: TaskGroup | undefined = ChallengeManager.getInstance().challenge?.taskGroups[taskGroupIndex]
      setDescription(taskGroup?.descriptionPage(descriptionIndex) ?? "")
      setTitle(taskGroup?.name ?? "")
    }
  }, [descriptionIndex, taskGroupIndex, i18n.language])

  useEffect(() => {
    setDescriptionIndex(0)
  }, [open])

  function handleClose() {
    dispatch(setOpenTaskGroupDialog(false))
  }

  function onPaginationChange(event: React.ChangeEvent<unknown>, page: number) {
    setDescriptionIndex(page - 1)
  }

  const markDownStyle = {
    "--color-canvas-default": "white",
    "--color-fg-default": "black",
    padding: "12px",
    borderRadius: "4px"
  } as React.CSSProperties

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <MDEditor.Markdown
            source={description}
            rehypePlugins={[[rehypeSanitize]]}
            style={markDownStyle}
          />
        </DialogContentText>
      </DialogContent>
      {descriptionCount > 1 && <DialogActions sx={{ justifyContent: 'center' }}>
        <Pagination count={descriptionCount} onChange={onPaginationChange} />
      </DialogActions>}
    </Dialog>
  )
}
