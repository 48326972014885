import ComparisonMode from "../../../utils/ComparisonMode";
import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import PerformanceIndicatorTaskOptions from "../../tasks/options/PerformanceIndicatorTaskOptions";
import PIResilienceIndexTask from "../../tasks/PIResilienceIndexTask";

export default function PIResilienceIndex(): Challenge {
  return new Challenge([
    new TaskGroup([
      new PIResilienceIndexTask(new PerformanceIndicatorTaskOptions({
        threshold: 0.5,
        scenario: 'unfavorable',
        year: 2035,
        comparisonMode: ComparisonMode.lt
      }))
    ])
  ])
}
