import { cloneDeep } from "lodash";
import { ElevationService, FCVModel, GPVModel, LineString, PBVModel, PRVModel, PSVModel, PipeModel, PumpModel, TCVModel } from "../api/client";
import * as turf from "@turf/turf"
import { BaseFeature } from "./BaseFeature";
import { BaseLineStringGeometry } from "./BaseLineGeometry";
import { BasePointFeature } from "./BasePointFeature";
import { EWAComponentType } from "./EWAComponentType";
import { EWAModel } from "./EWAModel";
import { ModelViewMode } from "./ModelViewMode";

export type LinkModel = PipeModel|PumpModel|GPVModel|FCVModel|TCVModel|PRVModel|PSVModel|PBVModel

export abstract class BaseLinestringFeature<T, U> extends BaseFeature<BaseLineStringGeometry, T, U> {
    constructor(collection: EWAModel, data: LinkModel, visibleTo: number, currentDate: number) {
        super(collection, data, visibleTo, currentDate);

        this.endnode = data.endnode;
        this.startnode = data.startnode;
        this._geometry = {
            type: 'LineString',
            coordinates: this.positionsFromModel(data)
        } as BaseLineStringGeometry
    }

    startnode: string
    endnode: string

    public delete() {
        let index = this.collection.findAnyComponentIndex(this.id);
        this.collection.features.splice(index, 1);
        this.collection.decreaseFeatureCounterForDeletedComponent(this);
    }

    public positionsFromModel(data: any): number[][] {
        let coordinates = new Array<number[]>();
        for (let i = 0; i < data.geometry.coordinates.length; i++) {
            let point = [data.geometry.coordinates[i][0], data.geometry.coordinates[i][1], data.geometry.coordinates[i][2]];
            coordinates.push(point)
        }
        return coordinates;
    }

    public updateNodes() {
        const start = this.collection.findAnyComponent(this.startnode) as BasePointFeature<any, any>;
        const end = this.collection.findAnyComponent(this.endnode) as BasePointFeature<any, any>;

        const newCoordinates: number[][] = [
            ...this._geometry.coordinates
        ]
        newCoordinates[0] = [...start._geometry.coordinates]
        newCoordinates[newCoordinates.length - 1] = [...end._geometry.coordinates]

        this._geometry = {
            type: "LineString",
            coordinates: newCoordinates
        }
    }

    public get geometry() {
        let value = this._geometry
        let coordinates = value.coordinates;
        if (this.collection?.viewMode === ModelViewMode.TwoD) {
            let coords = new Array<number[]>();
            for (let i = 0; i < coordinates.length; i++) {
                let point = [coordinates[i][0], coordinates[i][1]];
                coords.push(point)
            }
            coordinates = coords;
        }
        return {
            "type": "LineString",
            "coordinates": coordinates,
            "realCoordinates": value.coordinates
        }
    }

    public addPosition(coordinates: number[][], positionIndex: number, isAdd: boolean) {
        if (coordinates.length <= 2 || positionIndex === 0 || positionIndex === coordinates.length - 1) {
            return
        }

        const currentCoordinatesItem = this._geometry
        // retrieve old preceding/following coordinates from whats currently stored to presere the z-coordinate
        const beginCoordinates = currentCoordinatesItem.coordinates.slice(0, positionIndex)
        const endCoordinates = currentCoordinatesItem.coordinates.slice(positionIndex + (isAdd ? 0 : 1))

        const newCoordinates: number[][] = []
        newCoordinates.push(...beginCoordinates)
        newCoordinates.push(coordinates[positionIndex])
        newCoordinates.push(...endCoordinates)

        const value = cloneDeep(currentCoordinatesItem);
        value.coordinates = newCoordinates;
        this._geometry = value

        if (isAdd) {
            this.updateZCoordinates(coordinates, positionIndex)
        }
    }

    public async updateZCoordinates(coordinates: number[][], positionIndex: number) {
        const coordinates3d = await ElevationService.getElevationV1GetElevationsPost({
            type: "LineString",
            coordinates: coordinates
        } as LineString) as LineString

        const newCoordinates = coordinates3d.coordinates
        const newValue = cloneDeep(this._geometry)

        const oldCoordinates = newValue.coordinates
        newValue.coordinates = newCoordinates
        newValue.coordinates[0][2] = oldCoordinates[0][2]
        newValue.coordinates[newCoordinates.length - 1][2] = oldCoordinates[oldCoordinates.length - 1][2]

        this._geometry = newValue
    }

    public updateLength() {
        if (this.componentType === EWAComponentType.pipe) {
            const newLength = turf.length(turf.lineString(this._geometry.coordinates), { units: 'meters' })

            this.data.properties.length = newLength
        }
    }

    public updateStartNode(nodeCoordinates: number[]) {
        const coordinates = [...this._geometry.coordinates]
        coordinates[0] = [...nodeCoordinates]

        this._geometry = {
            type: 'LineString',
            coordinates
        }
    }

    public updateEndNode(nodeCoordinates: number[]) {
        const coordinates = [...this._geometry.coordinates]
        coordinates[coordinates.length - 1] = [...nodeCoordinates]

        this._geometry = {
            type: 'LineString',
            coordinates
        }
    }
}
