import { ToggleButton } from "@mui/material";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { PopupState, bindTrigger } from "material-ui-popup-state/hooks";
import useToolboxStyles from "../util/useToolboxStyles";
import ComponentLassoImage from "./ComponentLassoImage";
import ComponentAddImage from "./ComponentAddImage";
import i18n from "../../../i18n";
import ComponentImage from "./ComponentImage";
import { EWAModelWrapper } from "../EWAModelWrapper";


export function componentButtonTitle(componentType: EWAComponentType | ValveComponentType): string {
    switch (componentType) {
        case EWAComponentType.junction:
        case EWAComponentType.spring:
        case EWAComponentType.well:
        case EWAComponentType.pipe:
        case EWAComponentType.pump:
        case EWAComponentType.reservoir:
        case EWAComponentType.tank:
        case EWAComponentType.valve:
            return i18n.t(`ewa_component_type.${componentType}_other`)
        case ValveComponentType.fcv:
        case ValveComponentType.tcv:
        case ValveComponentType.gpv:
        case ValveComponentType.prv:
        case ValveComponentType.psv:
        case ValveComponentType.pbv:
            return i18n.t(`valve_component_type.${componentType}`)
    }
}

export interface ComponentMenuButtonProps {
    editLassoMode: EWAComponentType | ValveComponentType | null
    editCreateMode: EWAComponentType | ValveComponentType | null
    popupState: PopupState
    economyEnabled: boolean
    componentType: EWAComponentType | ValveComponentType
    selectedComponentButton: EWAComponentType | ValveComponentType | null
    modelWrapper: EWAModelWrapper
}

export default function ComponentMenuButton(props: ComponentMenuButtonProps) {
    const {
        editCreateMode,
        editLassoMode,
        economyEnabled,
        componentType,
        popupState,
        selectedComponentButton,
        modelWrapper
    } = props

    const classes = useToolboxStyles();
    const menuIsSelected = selectedComponentButton === componentType
    const title = componentButtonTitle(componentType)

    function menuButtonImage() {
        if (editCreateMode === componentType) {
            return <ComponentAddImage
                modelWrapper={modelWrapper}
                economyEnabled={economyEnabled}
                alt={title}
                componentType={componentType}
            />
        }

        if (editLassoMode === componentType) {
            return <ComponentLassoImage
                modelWrapper={modelWrapper}
                alt={title}
                componentType={componentType}
            />
        }

        return <ComponentImage
            alt={title}
            componentType={componentType}
            modelWrapper={modelWrapper}
        />
    }


    return <ToggleButton
        value={componentType}
        classes={{ selected: classes.selected }}
        selected={menuIsSelected}
        {...bindTrigger(popupState)}
        style={{ padding: '11px' }}
        title={title}
    >
        <span>{menuButtonImage()}</span>
    </ToggleButton>
}
