import ComparisonMode from "../../../utils/ComparisonMode";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskGroup from "../../TaskGroup";
import JunctionUpdateDemandTask from "../../tasks/JunctionUpdateDemandTask";
import JunctionUpdateElevationTask from "../../tasks/JunctionUpdateElevationTask";
import JunctionUpdateFireflowTask from "../../tasks/JunctionUpdateFireflowTask";
import UpdateValueTaskOptions from "../../tasks/options/UpdateValueTaskOptions";
import ReservoirUpdateHeadTask from "../../tasks/ReservoirUpdateHeadTask";
import TankUpdateDiameterTask from "../../tasks/TankUpdateDiameterTask";
import TankUpdateInitlevelTask from "../../tasks/TankUpdateInitlevelTask";

export default function ComparisonModeNumberUpdateTasks(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new JunctionUpdateDemandTask(new UpdateValueTaskOptions<number>({ targetValue: 30, updateTaskType: UpdateTaskType.ExactValue })),
      new JunctionUpdateElevationTask(new UpdateValueTaskOptions<number>({ targetValue: 60, updateTaskType: UpdateTaskType.ExactValue, comparisonMode: ComparisonMode.neq })),
      new JunctionUpdateFireflowTask(new UpdateValueTaskOptions<number>({ targetValue: 10, updateTaskType: UpdateTaskType.ExactValue, comparisonMode: ComparisonMode.gt })),
      new TankUpdateDiameterTask(new UpdateValueTaskOptions<number>({ targetValue: 40, updateTaskType: UpdateTaskType.ExactValue, comparisonMode: ComparisonMode.lte })),
      new TankUpdateInitlevelTask(new UpdateValueTaskOptions<number>({ targetValue: 20, updateTaskType: UpdateTaskType.Relative, comparisonMode: ComparisonMode.gte })),
      new ReservoirUpdateHeadTask(new UpdateValueTaskOptions<number>({ targetValue: 50, updateTaskType: UpdateTaskType.RelativeFactor, comparisonMode: ComparisonMode.lt })),
    ])
  ];

  const options = new ChallengeOptions({});

  return new Challenge(taskGroups, options);
}
