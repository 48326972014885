import ComponentDeletedEvent from "../../events/events/ComponentDeletedEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import ComponentTaskOptions, { IComponentTaskOptions } from "./options/ComponentTaskOptions";
import { EWAComponentType } from "../../data/EWAComponentType";
import i18n from "../../i18n";

export default class DeleteComponentTask extends Task {
  protected _type: string = 'DeleteComponentTask';

  public get optionYear(): string | undefined {
    const year = (this._options as ComponentTaskOptions).year;

    return year !== undefined ? year.toString() : year;
  }

  public constructor(options?: ComponentTaskOptions) {
    if (!options) {
      options = new ComponentTaskOptions();
    }

    super(options);

    this.onComponentDeleted = this.onComponentDeleted.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IComponentTaskOptions) {
    const options = new ComponentTaskOptions(plainOptions);
    return new DeleteComponentTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ComponentDeleted, this.onComponentDeleted);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ComponentDeleted, this.onComponentDeleted);
  }


  public get description(): string {
    const options = this._options as ComponentTaskOptions;

    let subType = '';
    if (options.componentType === EWAComponentType.valve && options.valveType !== undefined) {
      subType = ` (${i18n.t(`valve_component_type.${options.valveType}`)})`;
    }

    const componentType = options.componentType === undefined ? i18n.t('ewa_component_type.any') : i18n.t(`ewa_component_type.${options.componentType}_other`)
    return i18n.t('challenge.task.delete_component', {
      component: componentType,
      subType: subType
    })
  }

  protected onComponentDeleted(e: ComponentDeletedEvent) {
    const options = this._options as ComponentTaskOptions;
    if (options.componentType !== undefined && e.detail.type !== options.componentType)
      return;

    if (options.year !== undefined && e.detail.year !== options.year)
      return;


    if (options.componentType === EWAComponentType.valve) {
      if (options.valveType !== undefined && options.valveType !== e.detail.valveType) {
        return;
      }
    }

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }
}
