import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";
import { PipeMaterial } from "@/api/client";
import PipeMaterialSelect from "./common_inputs/PipeMaterialSelect";

class UpdatePipeMaterialValueTaskOptionsForm extends UpdateValueTaskOptionsForm<PipeMaterial|undefined> {
  targetValueOrDefault(targetValue?: PipeMaterial) {
    return targetValue ?? undefined;
  }

  renderTargetValueField() {
    return <PipeMaterialSelect value={this.state.targetValue} setValue={this.onTargetValueChange} />
  }
}

export default withTranslation('translation', { withRef: true })(UpdatePipeMaterialValueTaskOptionsForm)
