import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValveFCVApply } from "../../views/forms/ValveFCVApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class FCVUpdateMaximumFlowTask extends NumberValueUpdateTask<ValveFCVApply> {
  protected _type: string = 'FCVUpdateMaximumFlowTask';
  protected componentName: string = "valve_component_type.FCV";
  protected fieldName: string = "model.components.valve.fcv.maximum_flow";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new FCVUpdateMaximumFlowTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValveFCVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValveFCVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValveFCVApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.maximum_flow,
      oldValue: args.oldApply.timed.maximum_flow
    };
  }
}
