import { ReactNode } from "react";
import { BaseLinestringFeature } from "../../data/BaseLinestringFeature";
import { BaseSingleForm, BaseSingleFormApply } from "./BaseSingleForm";

export interface BaseSingleLinestringFormApply extends BaseSingleFormApply {

}

export interface BaseSingleLinestringFormProps<T, U> {
    feature: BaseLinestringFeature<T, U>
    children?: ReactNode;
    timedChildren?: ReactNode;
    conditionChildren?: ReactNode;
    handleApply: (apply: BaseSingleLinestringFormApply) => void;
    validate: () => boolean;
}

export function BaseSingleLinestringForm<T, U>(props: BaseSingleLinestringFormProps<T, U>) {
    const { feature, conditionChildren, timedChildren, handleApply, validate } = props;

    function doApply(apply: BaseSingleLinestringFormApply) {
        handleApply({
            "id": apply.id,
            "comment": apply.comment,
            "lifetime": apply.lifetime,
        } as BaseSingleLinestringFormApply);
    }

    return <BaseSingleForm validate={validate} handleApply={doApply} feature={feature} conditionChildren={conditionChildren} timedChildren={timedChildren}>
        {props.children}
    </BaseSingleForm>
}
