import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { PipeApply } from "../../views/forms/PipeApply";
import StringValueUpdateTask from "./base/StringValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateStringTaskOptions from "./options/UpdateStringTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class PipeUpdateCommentTask extends StringValueUpdateTask<PipeApply> {
  protected _type: string = 'PipeUpdateCommentTask';
  protected componentName: string = "ewa_component_type.Pipe_one";
  protected fieldName: string = "general.comment";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<string>) {
    const options = new UpdateStringTaskOptions(plainOptions);
    return new PipeUpdateCommentTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.PipeApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.PipeApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<PipeApply>): ValuesPair<string> {
    return {
      newValue: args.apply.comment,
      oldValue: args.oldApply.comment
    }
  }
}
