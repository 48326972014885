import { Close, FileUpload, Save } from "@mui/icons-material"
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { useSnackbar } from "notistack"
import { createRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ChallengeModel, EWAModelDTO, ModellingService } from "../../../api/client"
import Challenge, { PlainChallenge } from "../../Challenge"
import EventManager from "@/events/EventManager"

export interface ChallengeUploadDialogProps {
    open: boolean
    onClose: () => void
    model: EWAModelDTO
    setModel: (_: EWAModelDTO) => void
}

export default function ChallengeUploadDialog(props: ChallengeUploadDialogProps) {
    const {
        open,
        onClose,
        model,
        setModel
    } = props

    const { t, i18n } = useTranslation()
    const fileInputRef = createRef<HTMLInputElement>()
    const [error, setError] = useState<boolean>(false)
    const [errorText, setErrorText] = useState<string>('')
    const [challenge, setChallenge] = useState<Challenge | undefined>(undefined)

    const [loading, setLoading] = useState<boolean>(false)
    const [validChallenge, setValidChallenge] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    function resetFeedback() {
        setError(false)
        setErrorText('')
        setChallenge(undefined)
        setValidChallenge(false)
    }

    useEffect(() => {
        if (open) {
            resetFeedback()
        }
    }, [open])

    async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)
        resetFeedback()

        if (!e.target.files?.length) return;

        const file = e.target.files[0]

        try {
            const plainChallenge = JSON.parse(await file.text()) as PlainChallenge
            const challenge = Challenge.deserialize(plainChallenge)
            setChallenge(challenge)
            setValidChallenge(true)
        } catch (e: any) {
            console.error(e)
            setError(true)
            setErrorText(t('challenge.home.upload_challenge.validation_failed') as string)
        }

        setLoading(false)
    }

    function challengeName() {
        const names = challenge?.options.name ?? {}

        return names[i18n.language] ?? (names.en ?? '')
    }

    function oldChallengeHint() {
        if (!model.challenge) return

        return <Alert severity="warning" sx={{ marginBottom: 1 }}>
            {t('challenge.home.upload_challenge.old_challenge_hint')}
        </Alert>
    }

    async function onSave() {
        if (challenge === undefined) return

        setLoading(true)

        try {
            const challengeModel = challenge.toJSON() as ChallengeModel
            const challengeMetaData = await ModellingService.modelUploadChallengeV1ModelsModelIdChallengePut(model.id, challengeModel)

            const newModel: EWAModelDTO = {
                ...model,
                challenge: challengeMetaData
            }

            setModel(newModel)

            EventManager.getInstance().onHomeChallengeUploaded({
                modelId: model.id,
                challengeMetaData
            })

            enqueueSnackbar(t('challenge.home.upload_challenge.upload_succeeded'), {
                variant: 'success'
            })
            onClose()
        } catch (e: any) {
            enqueueSnackbar(t('challenge.home.upload_challenge.upload_failed'), {
                variant: 'error'
            })
        }
        setLoading(false)
    }

    return <>
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>{t('challenge.home.upload_challenge.title')}</span>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            {loading && <LinearProgress />}
            <DialogContent>
                {oldChallengeHint()}
                <div>
                    <Button variant="contained" component="label" startIcon={<FileUpload />} sx={{ marginRight: '8px' }}>
                        {t('challenge.home.upload_challenge.file')} <input
                            ref={fileInputRef}
                            hidden
                            accept="application/json"
                            type="file"
                            onChange={onFileChange}
                        />
                    </Button>
                </div>
                {validChallenge && <div>
                    <p>{challengeName()}</p>
                </div>}
                {error && <Alert sx={{ marginTop: 1 }} severity="error">{errorText}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!validChallenge}
                    variant="contained"
                    color="success"
                    startIcon={<Save />}
                    onClick={onSave}
                >{t('general.save')}</Button>
            </DialogActions>
        </Dialog>
    </>
}
