import { Box, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from "@mui/material";
import { EWAModel } from "../../../data/EWAModel";
import { BaseFeature } from "../../../data/BaseFeature"
import { EWAComponentType } from "../../../data/EWAComponentType";
import JunctionImage from "../../../assets/Junction_256.png";
import WellImage from "../../../assets/Well_256.png";
import SpringImage from "../../../assets/Spring_256.png";
import TankImage from "../../../assets/Tank_256.png";
import ReservoirImage from "../../../assets/Reservoir_256.png";
import PipeImage from "../../../assets/Pipe_256.png";
import PumpImage from "../../../assets/Pump_256.png";
import ValveImage from "../../../assets/Valve_256.png";
import useToolboxStyles from "../util/useToolboxStyles";
import { useTranslation } from "react-i18next";
import { findComputedTimed } from "../../../utils/findTimed";
import { findCondition } from "../../../utils/findCondition";
import { JunctionTable } from "./JunctionTable";
import { ValveTable } from "./ValveTable";
import { PumpTable } from "./PumpTable";
import { ReservoirTable } from "./ReservoirTable";
import { TankTable } from "./TankTable";
import { isFunction } from "../../../utils/isFunction";
import PipeTable from "./PipeTable";
import { useMemo } from "react"
import { MRT_Localization } from "material-react-table"
import { MRT_Localization_EN } from "material-react-table/locales/en"
import { MRT_Localization_DE } from "material-react-table/locales/de"
import { WellTable } from "./WellTable";
import { SpringTable } from "./SpringTable";
import { EWAModelWrapper } from "../EWAModelWrapper";

export interface ComponentTableProps {
  data: EWAModel
  setModelWrapper: (_: EWAModelWrapper) => void
  currentDate: number
  currentCondition?: string
  onFeatureEdit: (id: string) => void
  localization: MRT_Localization
}

export interface TableProps {
  data: EWAModel;
  setModelWrapper: (_: EWAModelWrapper) => void
  tableComponentType: EWAComponentType,
  setTableComponentType: (_: EWAComponentType) => void;
  selectedFeatureIndices: number[]
  setSelectedFeatureIndices: (_: number[]) => void
  currentDate: number
  currentCondition?: string
  openEditDialog: () => void
  width: number
  height: number
}

export interface TableFeature<T, C> {
  id: string
  componentType: EWAComponentType
  subType?: string
  timedProps: T
  conditionProps: C
  feature: BaseFeature<any, C, Partial<T>>
}

function extractTimedProps<T>(feature: BaseFeature<any, any, any>, currentDate: number, fallback: T): T {
  return findComputedTimed({
    item: feature,
    get: _ => _
  }, currentDate, fallback)
}

function extractConditionalProps<C>(feature: BaseFeature<any, any, any>, currentCondition: string | undefined, fallback: C,): C {
  if (currentCondition === undefined) return ({} as C)

  return findCondition({ item: feature, get: _ => _ }, currentCondition, fallback)
}

export function filterAndMapComponents<T, C>(
  features: BaseFeature<any, C, Partial<T>>[],
  componentType: EWAComponentType,
  currentDate: number,
  timedFallback: T | ((feature: BaseFeature<any, any, any>) => T),
  currentCondition: string | undefined,
  conditionalFallback: C
): TableFeature<T, C>[] {
  const components: TableFeature<T, C>[] = []

  const timedFallbackIsFunction = isFunction(timedFallback)

  for (const feature of features) {
    if (feature.componentType !== componentType) {
      continue
    }

    const lifetime: [number, number] = feature.data.lifetime as [number, number]
    if (currentDate < lifetime[0] || currentDate > lifetime[1]) {
      continue
    }

    let subType = undefined
    if (feature.componentType === EWAComponentType.valve) {
      subType = feature.valveType
    }

    const component = {
      id: feature.data.id,
      componentType: feature.componentType,
      timedProps: extractTimedProps<T>(feature, currentDate, ((timedFallbackIsFunction) ? timedFallback(feature) : timedFallback)),
      conditionProps: extractConditionalProps<C>(feature, currentCondition, conditionalFallback),
      subType: subType,
      feature
    }

    components.push(component)
  }

  return components
}

export function Table(props: TableProps) {
  const toggleClasses = useToolboxStyles();
  const {
    data,
    setModelWrapper,
    tableComponentType,
    setTableComponentType,
    setSelectedFeatureIndices,
    currentDate,
    currentCondition,
    openEditDialog,
    width,
    height
  } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme()

  const localization = useMemo<MRT_Localization>(() => {
    switch (i18n.language) {
      case 'de':
        return MRT_Localization_DE
      default:
        return MRT_Localization_EN
    }
  }, [i18n.language])

  function renderComponentChooserButton(value: EWAComponentType, image: string, title: string) {
    return (
      <Tooltip placement="right" title={title}>
        <ToggleButton classes={{ selected: toggleClasses.selected }} value={value} sx={{ borderRadius: 0 }}>
          <img width={24} height={24} src={image} alt={title} />
        </ToggleButton>
      </Tooltip>
    );
  }

  function onFeatureEdit(id: string) {
    let selectedIndex: number | undefined = undefined
    for (let i = 0; i < data.features.length; i++) {
      if (id === data.features[i].id) {
        selectedIndex = i;
      }
    }
    if (selectedIndex !== undefined) {
      setSelectedFeatureIndices([selectedIndex]);
      openEditDialog()
    }
  }

  function renderComponentTable(width: number, height: number) {
    const props: ComponentTableProps = {
      data,
      setModelWrapper,
      currentDate,
      currentCondition,
      onFeatureEdit,
      localization
    }

    switch (tableComponentType) {
      case EWAComponentType.junction:
        return <JunctionTable {...props} />
      case EWAComponentType.well:
        return <WellTable {...props} />
      case EWAComponentType.spring:
        return <SpringTable {...props} />
      case EWAComponentType.tank:
        return <TankTable {...props} />
      case EWAComponentType.reservoir:
        return <ReservoirTable {...props} />
      case EWAComponentType.pipe:
        return <PipeTable {...props} />
      case EWAComponentType.pump:
        return <PumpTable {...props} />
      case EWAComponentType.valve:
        return <ValveTable {...props} />
    }
  }

  function onComponentButtonChange(value: EWAComponentType | null) {
    if (value !== null) {
      setTableComponentType(value)
    }
  }

  return (<Box sx={{ display: "flex", width, height }}>
    <div>
      <ToggleButtonGroup
        exclusive
        value={tableComponentType}
        orientation={"vertical"}
        onChange={(event: any, value: EWAComponentType | null) => { onComponentButtonChange(value) }}
        sx={{ backgroundColor: theme.palette.darkBlue.main }}
      >
        {renderComponentChooserButton(EWAComponentType.junction, JunctionImage, t('model.controls.table_component_type.junction'))}
        {renderComponentChooserButton(EWAComponentType.well, WellImage, t('model.controls.table_component_type.well'))}
        {renderComponentChooserButton(EWAComponentType.spring, SpringImage, t('model.controls.table_component_type.spring'))}
        {renderComponentChooserButton(EWAComponentType.tank, TankImage, t('model.controls.table_component_type.tank'))}
        {renderComponentChooserButton(EWAComponentType.reservoir, ReservoirImage, t('model.controls.table_component_type.reservoir'))}
        {renderComponentChooserButton(EWAComponentType.pipe, PipeImage, t('model.controls.table_component_type.pipe'))}
        {renderComponentChooserButton(EWAComponentType.pump, PumpImage, t('model.controls.table_component_type.pump'))}
        {renderComponentChooserButton(EWAComponentType.valve, ValveImage, t('model.controls.table_component_type.valve'))}
      </ToggleButtonGroup>
    </div>
    <div style={{ flexGrow: 1, overflow: 'auto' }}>
      {renderComponentTable(0, 0)}
    </div>
  </Box>);
}
