import { EWAComponentType } from "@/data/EWAComponentType";
import { EWAModelWrapper } from "../EWAModelWrapper";
import Papa from 'papaparse'
import { importPipeMetaData, exportPipeMetaData, PipeMetaData } from "./PipeMetaData";

export function exportComponentMetaData(ewaModelWrapper: EWAModelWrapper, componentType: EWAComponentType) {
    const data = getData(ewaModelWrapper, componentType)

    const csv = Papa.unparse(data, {
        delimiter: ','
    })

    return csv
}

function getData(ewaModelWrapper: EWAModelWrapper, componentType: EWAComponentType) {
    switch (componentType) {
        case EWAComponentType.pipe:
            return exportPipeMetaData(ewaModelWrapper)
        default:
            return {
                fields: [],
                data: []
            }
    }
}

export function importComponentMetaData(ewaModelWrapper: EWAModelWrapper, componentType: EWAComponentType, rawData: string, onComplete: () => void) {
    Papa.parse(rawData, {
        header: true,
        complete: (results) => {
            importData(ewaModelWrapper, componentType, results.data)
            onComplete()
        }
    })

    // importData(ewaModelWrapper, componentType, data.data)
}

function importData(ewaModelWrapper: EWAModelWrapper, componentType: EWAComponentType, data: unknown[]) {
    switch (componentType) {
        case EWAComponentType.pipe:
            importPipeMetaData(ewaModelWrapper, data as PipeMetaData[])
            break
    }
}

