import { ReactNode, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MaterialReactTableProps, MRT_Cell, MRT_TableInstance } from 'material-react-table';
import { useTranslation } from "react-i18next";
import { ComponentTableProps, filterAndMapComponents, TableFeature } from "./Table";
import { IndeterminateCheckBox, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { SourceConditionProps, SourceTimedProps } from "../../../api/client";
import { EWAComponentType } from "../../../data/EWAComponentType";
import parseFloatOrUndefined from "../../../utils/parseFloatOrUndefined";
import validNumber from "../../../utils/validNumber";
import { minYear } from "../../../utils/constants";
import { Well } from "../../../data/Well";
import { WellApply } from "../../forms/WellApply";
import { EWAModelWrapper } from "../EWAModelWrapper";
import RowActions from "./RowActions";

type WellTableFeature = TableFeature<SourceTimedProps, SourceConditionProps>

interface ValidationErrors {
  inflow?: string
}

let isFirstYear = true
let currentDate = minYear
function validateRequiredPositive(value: string): boolean {
  if (isFirstYear || value !== '') {
    const parsed = parseFloatOrUndefined(value)
    if (!validNumber(parsed) || parsed! < 0) {
      return false
    }
  }
  return true
}

export function WellTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props;
  currentDate = props.currentDate
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })
  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<WellTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
  }, [currentDate])

  const columns = useMemo<MRT_ColumnDef<WellTableFeature>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id'
      },
      {
        header: `${t('model.components.well.inflow')} (L/s)`,
        accessorKey: 'timedProps.inflow',
        Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.inflow,
          helperText: validationErrors.inflow,
          type: 'number',
          onChange(event) {
            const value = event.target.value
            let error: string | undefined = undefined
            if (!validateRequiredPositive(value)) {
              error = t('validation.invalid_value') as string
            }
            setValidationErrors((prev) => ({ ...prev, inflow: error }))
          }
        }
      },
      {
        header: t('model.components.well.scale_inflow') as string,
        accessorKey: 'timedProps.scale_inflow',
        accessorFn: (row) => row.timedProps.scale_inflow ?? false,
        align: 'center',
        Cell: ({ cell }) => renderScaleInflow(cell.getValue<boolean | undefined>()),
        filterVariant: 'checkbox',
        editSelectOptions: [
          { text: t('general.unset'), value: '' },
          { text: t('general.enabled'), value: true },
          { text: t('general.disabled'), value: false }
        ],
        editVariant: 'select'
      },
    ],
    [i18n.language, validationErrors]
  )

  const tableData = useMemo<WellTableFeature[]>(() => {
    const tableData = filterAndMapComponents<SourceTimedProps, SourceConditionProps>(
      data.features,
      EWAComponentType.well,
      currentDate,
      { inflow: 0 },
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow (props: {
    row: MRT_Row<WellTableFeature>,
    table: MRT_TableInstance<WellTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow (props: {
    exitEditingMode: () => void,
    row: MRT_Row<WellTableFeature>,
    table: MRT_TableInstance<WellTableFeature>,
    values: Record<string, any>
  }) {
    const {values, row, exitEditingMode} = props
    // first validate values
    if (values['id'] === '' || !validateRequiredPositive(values['timedProps.inflow'])) {
      return
    }

    // create the Apply object
    const spring = row.original.feature as Well

    const timed: Partial<SourceTimedProps> = {
    }

    const scaleInflow = values['timedProps.scale_inflow']
    if (scaleInflow !== '') {
      timed.scale_inflow = scaleInflow
    }

    const inflow = parseFloatOrUndefined(values['timedProps.inflow'])
    if (inflow !== undefined) {
      timed.inflow = inflow
    }

    const apply: WellApply = {
      id: values['id'],
      comment: spring.comment,
      lifetime: spring.data.lifetime,
      longitude: spring._geometry.coordinates[0],
      latitude: spring._geometry.coordinates[1],
      elevation: spring._geometry.coordinates[2],
      strength: spring.data.properties.strength,
      conditional: {
      },
      timed
    }

    spring.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    exitEditingMode()
    setValidationErrors({})
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  function renderScaleInflow(value?: boolean) {
    return (value === undefined ? <IndeterminateCheckBox /> : (value ? <CheckBox /> : <CheckBoxOutlineBlank />))
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<WellTableFeature>;
      row: MRT_Row<WellTableFeature>;
      table: MRT_TableInstance<WellTableFeature>;
    }): ReactNode => <RowActions<WellTableFeature>
     {...props}
     onSaveRow={onSaveRow}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
