import { ReactNode, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { type MRT_ColumnDef, MRT_Row, MRT_TableInstance, MRT_Cell } from 'material-react-table';
import { useTranslation } from "react-i18next";
import { ComponentTableProps, filterAndMapComponents, TableFeature } from "./Table";
import { IndeterminateCheckBox, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { JunctionConditionProps, JunctionTimedProps } from "../../../api/client";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { minYear } from "../../../utils/constants";
import parseFloatOrUndefined from "../../../utils/parseFloatOrUndefined";
import validNumber from "../../../utils/validNumber";
import { Junction } from "../../../data/Junction";
import { JunctionApply } from "../../forms/JunctionApply";
import { EWAModelWrapper } from "../EWAModelWrapper";
import RowActions from "./RowActions";
import { findTimed } from "../../../utils/findTimed";

type JunctionTableFeature = TableFeature<JunctionTimedProps, JunctionConditionProps>

interface ValidationErrors {
  demand?: string
  fireflow?: string
}

let isFirstYear = true
let currentDate = minYear
function validateDemand(value: string) {
  if ((isFirstYear || value !== '') && !validNumber(parseFloatOrUndefined(value))) {
    return false
  }

  return true
}

function validateFireflow(value: string) {
  if (value !== '' && !validNumber(parseFloat(value))) {
    return false
  }

  return true
}

export function JunctionTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props;
  currentDate = props.currentDate
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })
  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<JunctionTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
    setValidationErrors({})
  }, [currentDate])

  const columns: MRT_ColumnDef<JunctionTableFeature>[] = useMemo<MRT_ColumnDef<JunctionTableFeature>[]>(() => [
    {
      header: 'ID',
      accessorKey: 'id'
    },
    {
      header: `${t('model.components.junction.demand')} (L/s)`,
      accessorKey: 'timedProps.demand',
      Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
      filterVariant: 'range',
      filterFn: 'betweenInclusive',
      muiTableBodyCellEditTextFieldProps: {
        error: !!validationErrors.demand,
        helperText: validationErrors.demand,
        type: 'number',
        onChange(event) {
          const value = event.target.value
          let error: string | undefined = undefined
          if (!validateDemand(value)) {
            error = t('validation.invalid_value') as string
          }
          setValidationErrors((prev) => ({ ...prev, demand: error }))
        }
      }
    },
    {
      header: t('model.components.junction.scale_demand') as string,
      accessorKey: 'timedProps.scale_demand',
      accessorFn: (row) => row.timedProps.scale_demand ?? false,
      Cell: ({ cell }) => renderScaleDemand(cell.getValue<boolean | undefined>()),
      filterVariant: 'checkbox',
      editSelectOptions: [
        { text: t('general.unset'), value: '' },
        { text: t('general.enabled'), value: true },
        { text: t('general.disabled'), value: false }
      ],
      editVariant: 'select'
    },
    {
      header: `${t('model.components.junction.fireflow')} (L/s)`,
      accessorKey: 'conditionProps.fireflow',
      accessorFn: (row) => row.conditionProps.fireflow ?? 0,
      Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
      filterVariant: 'range',
      filterFn: 'betweenInclusive',
      muiTableBodyCellEditTextFieldProps: {
        error: !!validationErrors.fireflow,
        helperText: validationErrors.fireflow,
        type: 'number',
        onChange(event) {
          const value = event.target.value

          let error: string | undefined = undefined
          if (!validateFireflow(value)) {
            error = t('validation.invalid_value') as string
          }

          setValidationErrors((prev) => ({ ...prev, fireflow: error }))
        }
      }
    },
  ],
    [i18n.language, validationErrors]
  )

  const tableData = useMemo<JunctionTableFeature[]>(() => {
    const tableData = filterAndMapComponents<JunctionTimedProps, JunctionConditionProps>(
      data.features,
      EWAComponentType.junction,
      currentDate,
      { demand: 0 },
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow (props: {
    row: MRT_Row<JunctionTableFeature>,
    table: MRT_TableInstance<JunctionTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow (props: {
    exitEditingMode: () => void,
    row: MRT_Row<JunctionTableFeature>,
    table: MRT_TableInstance<JunctionTableFeature>,
    values: Record<string, any>
  }) {
    const {values, row, exitEditingMode} = props
    // first validate values
    if (values['id'] === '' || !validateDemand(values['timedProps.demand']) || !validateFireflow(values['conditionProps.fireflow'])) {
      return
    }

    // create the Apply object
    const junction = row.original.feature as Junction

    const currentTimed = findTimed({
      item: junction,
      get: _ => _
    }, currentDate, {}) as Partial<JunctionTimedProps>

    const timed: Partial<JunctionTimedProps> = {
      emittercoefficient: currentTimed.emittercoefficient,
      mode: currentTimed.mode
    }

    const scaleDemand = values['timedProps.scale_demand']
    if (scaleDemand !== '') {
      timed.scale_demand = scaleDemand
    }

    const demand = parseFloatOrUndefined(values['timedProps.demand'])
    if (demand !== undefined) {
      timed.demand = demand
    }

    const apply: JunctionApply = {
      id: values['id'],
      comment: junction.comment,
      lifetime: junction.data.lifetime,
      longitude: junction._geometry.coordinates[0],
      latitude: junction._geometry.coordinates[1],
      elevation: junction._geometry.coordinates[2],
      strength: junction.data.properties.strength,
      conditional: {
        fireflow: parseFloatOrUndefined(values['conditionProps.fireflow'])
      },
      timed
    }

    junction.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    setValidationErrors({})
    exitEditingMode()
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  function renderScaleDemand(value?: boolean) {
    return (value === undefined ? <IndeterminateCheckBox /> : (value ? <CheckBox /> : <CheckBoxOutlineBlank />))
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<JunctionTableFeature>;
      row: MRT_Row<JunctionTableFeature>;
      table: MRT_TableInstance<JunctionTableFeature>;
    }): ReactNode => <RowActions<JunctionTableFeature>
     {...props}
     onSaveRow={onSaveRow}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
