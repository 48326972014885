import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import YearChangedEvent from "../../events/events/YearChangedEvent";
import UpdateTaskType from "../misc/UpdateTaskType";
import Task from "./base/Task";
import ChangeYearTaskOptions, { IChangeYearTaskOptions } from "./options/ChangeYearTaskOptions";
import i18n from "../../i18n";

export default class ChangeYearTask extends Task {
  protected _type: string = 'ChangeYearTask';

  public constructor(options?: ChangeYearTaskOptions) {
    if (!options) {
      options = new ChangeYearTaskOptions();
    }

    super(options);

    this.onYearChanged = this.onYearChanged.bind(this);

  }

  public static fromPlainTaskOptions(plainOptions: IChangeYearTaskOptions) {
    const options = new ChangeYearTaskOptions(plainOptions);
    return new ChangeYearTask(options);
  }

  protected onYearChanged(e: YearChangedEvent) {
    let shouldUpdate = false;
    const options = this._options as ChangeYearTaskOptions;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        if (e.detail.year === options.year) {
          shouldUpdate = true;
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }

    if (!shouldUpdate) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ChangeYearTaskOptions;

    let description = '';
    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        description = i18n.t('challenge.task.change_year.exact_value', { year: options.year })
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.change_year.any')
        break;
    }

    return description;
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.YearChanged, this.onYearChanged);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.YearChanged, this.onYearChanged);
  }

}
