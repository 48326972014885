import { BaseFeature } from "../data/BaseFeature";

export interface MultiTimedGetter<T extends BaseFeature<any, any, U>, U> {
    items: T[]
    get: (_: U) => any
}

export function findTimedMulti(multiTimedGetter: MultiTimedGetter<any, any>, currentDate: number|undefined, alternative: any): any {
    const finder = (item: any, currentDate:number|undefined) => item._timedProperties.findValue(currentDate)
    return internalFinder(multiTimedGetter, currentDate, alternative, finder)
}

export function findComputedTimedMulti(multiTimedGetter: MultiTimedGetter<any, any>, currentDate: number|undefined, alternative: any): any {
    const finder = (item: any, currentDate:number|undefined) => item._timedProperties.findComputedValue(currentDate)
    return internalFinder(multiTimedGetter, currentDate, alternative, finder)
}

export function findTimedIfDefinedMulti(multiTimedGetter: MultiTimedGetter<any, any>, currentDate: number|undefined, alternative: any): any {
    const finder = (item: any, currentDate:number|undefined) => item._timedProperties.findValueIfDefined(currentDate)
    return internalFinder(multiTimedGetter, currentDate, alternative, finder)
}

function internalFinder(multiTimedGetter: MultiTimedGetter<any, any>, currentDate: number|undefined, alternative: any, finder: ((item: any, currentDate:number|undefined) => any)) {
    if (currentDate === undefined) return alternative

    const values = new Array<any>();
    for (let i = 0; i < multiTimedGetter.items.length; i++) {
        const value = finder(multiTimedGetter.items[i], currentDate)
        values.push(value !== undefined ? multiTimedGetter.get(value) : undefined)
    }

    if (new Set(values).size === 1) {
        return values[0];
    }

    return alternative;
}
