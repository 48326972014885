import { Button, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ChallengeOptions, { IChallengeOptions } from "../../ChallengeOptions";
import SaveIcon from "@mui/icons-material/Save";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { BudgetKey, defaultPrices } from "../../managers/EconomyManager";
import { useTranslation } from "react-i18next";
import { LocalizableText } from "../../LocalizableText";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setChallengeOptions, setChallengeOptionsDirty } from "../../../store/slices/challengeEditorSlice";

interface ChallengeOptionsFormProps {
}

interface LocalizableState {
  name: string
  completionText: string
}

const ewaComponentTypes = Object.values(EWAComponentType)
const valveComponentTypes = Object.values(ValveComponentType)
const combinedComponentTypes = [...ewaComponentTypes, ...valveComponentTypes]

export default function ChallengeOptionsForm(props: ChallengeOptionsFormProps) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const locale = useSelector((state: RootState) => state.challengeEditor.locale)
  const options = useSelector((state: RootState) => state.challengeEditor.challengeOptions)

  const isDirty = useSelector((state: RootState) => state.challengeEditor.challengeOptionsDirty)
  const setIsDirty = (isDirty: boolean) => dispatch(setChallengeOptionsDirty(isDirty))

  const [name, setName] = useState<string>('')
  const [completionText, setCompletionText] = useState<string>('')
  const [economyBudget, setEconomyBudget] = useState<number | undefined>(undefined)
  const [economyEnabled, setEconomyEnabled] = useState<boolean>(false)
  const [score, setScore] = useState<number | undefined>(undefined)
  const [targetTime, setTargetTime] = useState<number | undefined>(undefined)

  const priceRefs: Map<BudgetKey, React.RefObject<HTMLInputElement>> = useMemo(() => {
    const map = new Map<BudgetKey, React.RefObject<HTMLInputElement>>()
    for (const componentType of combinedComponentTypes) {
      map.set(componentType, React.createRef())
    }
    return map
  }, [])

  const priceMap = useMemo(() => {
    return new Map(options?.priceMap)
  }, [options?.priceMap])

  useEffect(() => {
    for (const componentType of combinedComponentTypes) {
      const priceRef = priceRefs.get(componentType)

      if (!priceRef?.current) continue

      priceRef.current.value = priceMap.get(componentType)?.toString() ?? ''
    }
  }, [priceMap])

  function localizedStateFromProps(): LocalizableState {
    const result: LocalizableState = {
      name: (options?.name ?? {})[locale] ?? '',
      completionText: (options?.completionText ?? {})[locale] ?? ''
    }

    return result
  }

  function stateFromProps() {
    const localizedState = localizedStateFromProps()
    setName(localizedState.name)
    setCompletionText(localizedState.completionText)
    setEconomyBudget(options?.economyBudget)
    setEconomyEnabled(options?.economyEnabled ?? false)
    setScore(options?.score)

    let targetTime = options?.targetTime
    if (targetTime !== undefined) {
      targetTime /= 1000
    }
    setTargetTime(targetTime)
  }

  useEffect(() => {
    stateFromProps()
  }, [options, locale])

  function packState() {
    const fullName: LocalizableText = {
      ...(options?.name),
      [locale]: name
    }

    const fullCompletionText: LocalizableText = {
      ...(options?.completionText)
    }

    if (completionText === '') {
      delete fullCompletionText[locale]
    } else {
      fullCompletionText[locale] = completionText
    }

    return {
      name: fullName,
      completionText: fullCompletionText,
      economyBudget,
      economyEnabled,
      score,
      targetTime
    }
  }

  function handleSave() {
    const newOptions: IChallengeOptions = {
      ...options,
      ...packState()
    };

    // convert target time to ms
    if (newOptions.targetTime !== undefined) {
      newOptions.targetTime *= 1000
    }

    const priceMap: [BudgetKey, number][] = []
    for (const componentType of combinedComponentTypes) {
      const priceRef = priceRefs.get(componentType);

      if (!priceRef?.current) {
        continue
      }

      const price = Number.parseFloat(priceRef.current.value)

      if (!isNaN(price)) {
        priceMap.push([
          componentType, price
        ])
      }
    }
    newOptions.priceMap = priceMap

    dispatch(setChallengeOptions(newOptions))
  }

  function renderMedalTimes() {
    const medalTimes = ChallengeOptions.computeMedalTimes(targetTime ?? 0);
    if (medalTimes === undefined) return;
    const items = [];
    let i = 0;
    for (const medalTime of medalTimes) {
      items.push(<li key={i++}>{medalTime.text} - {medalTime.time}s</li>)
    }
    return <>
      <span>{t('challenge.editor.challenge_options.medal_times')}:</span>
      <ul>
        {items}
      </ul>
    </>
  }

  function onEconomyBudgetChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number.parseFloat(e.target.value)

    setEconomyBudget(isNaN(value) ? undefined : value)
    setIsDirty(true)
  }

  function onScoreChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number.parseFloat(e.target.value)

    setScore(isNaN(value) ? undefined : value)
    setIsDirty(true)
  }

  function onTargetTimeChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number.parseFloat(e.target.value)

    setTargetTime(isNaN(value) ? undefined : value)
    setIsDirty(true)
  }

  function renderEconomyOptions() {
    let renderedIfEconomyEnabled = undefined

    if (economyEnabled) {
      renderedIfEconomyEnabled = <>
        <TextField
          variant="outlined"
          fullWidth
          type="number"
          label={t('challenge.editor.challenge_options.economy_budget')}
          helperText={t('challenge.editor.challenge_options.economy_budget_hint')}
          value={economyBudget?.toString() ?? ''}
          onChange={onEconomyBudgetChange}
        />
        {renderPrices()}
      </>
    }

    return <>
      <FormControl
        fullWidth
        variant="standard"
        margin="none"
      >
        <FormControlLabel
          control={<Checkbox checked={economyEnabled} onChange={(e) => {
            setEconomyEnabled(e.target.checked)
            setIsDirty(true)
          }} />}
          label={t('challenge.editor.challenge_options.economy_enabled')}
        />
        <FormHelperText>{t('challenge.editor.challenge_options.economy_enabled_hint')}</FormHelperText>
      </FormControl>
      {renderedIfEconomyEnabled}
    </>
  }

  function createComponentTextField(componentType: BudgetKey, label: string) {
    const defaultPrice = defaultPrices.get(componentType) ?? '-'
    const helperText = `Default: ${defaultPrice}`
    return <Grid key={componentType} item xs={6} sm={4} sx={{ padding: '8px' }}>
      <TextField
        variant="outlined"
        fullWidth
        label={label}
        type="number"
        helperText={helperText}
        defaultValue={priceMap.get(componentType)}
        inputRef={priceRefs.get(componentType)}
        onChange={() => {
          setIsDirty(true)
        }}
      />
    </Grid>
  }

  function renderPrices() {
    const items = []

    for (const ewaComponentType of ewaComponentTypes) {
      const label = t(`ewa_component_type.${ewaComponentType}`, { count: 0 })
      items.push(createComponentTextField(ewaComponentType, label))
    }
    for (const valveComponentType of valveComponentTypes) {
      const label = t(`valve_component_type.${valveComponentType}`)
      items.push(createComponentTextField(valveComponentType, label))
    }

    return <div style={{ marginTop: '10px' }}>
      <Typography variant="h6">{t('challenge.editor.challenge_options.alternative_component_prices')}</Typography>
      <Typography variant="body2">{t('challenge.editor.challenge_options.alternative_component_prices_hint')}</Typography>
      <Grid container>
        {items}
      </Grid>
    </div>
  }

  return <Card variant="outlined">
    <CardHeader
      title={t('challenge.editor.challenge_options.title')}
      action={<Button disabled={!isDirty} onClick={handleSave} variant="contained" title={t('general.save') as string} color="success"><SaveIcon /></Button>}
    />
    <CardContent>
      <TextField
        variant="outlined"
        fullWidth
        label={t('general.name')}
        helperText={t('challenge.editor.challenge_options.name_hint')}
        value={name}
        onChange={(e) => {
          setIsDirty(true)
          setName(e.target.value)
        }}
        margin="normal"
      />
      <TextField
        variant="outlined"
        multiline
        margin="normal"
        minRows={5}
        maxRows={5}
        label={t('challenge.editor.challenge_options.completion_text')}
        helperText={t('challenge.editor.challenge_options.completion_text_hint')}
        fullWidth
        value={completionText}
        onChange={(e) => {
          setIsDirty(true)
          setCompletionText(e.target.value)
        }}
      />
      <TextField
        variant="outlined"
        fullWidth
        type="number"
        margin="normal"
        label={t('challenge.editor.challenge_options.score')}
        helperText={t('challenge.editor.challenge_options.score_hint')}
        value={score?.toString() ?? ''}
        onChange={onScoreChange}
      />
      <TextField
        variant="outlined"
        fullWidth
        type="number"
        margin="normal"
        label={t('challenge.editor.challenge_options.target_time')}
        helperText={t('challenge.editor.challenge_options.target_time_hint')}
        value={targetTime?.toString() ?? ''}
        onChange={onTargetTimeChange}
      />
      {renderMedalTimes()}
      {renderEconomyOptions()}
    </CardContent>
  </Card>
}
