
enum TaskTypeString {
  // general tool
  ChangeYearTask = 'ChangeYearTask',
  ChangeTabTask = 'ChangeTabTask',
  ChangeOrthoLayerTask = 'ChangeOrthoLayerTask',
  OpenEditDialogTask = 'OpenEditDialogTask',
  OpenSimulationResultsDialogTask = 'OpenSimulationResultsDialogTask',
  SaveModelTask = 'SaveModelTask',
  ShowResultsTask = 'ShowResultsTask',
  // misc
  StayWithinBudgetTask = 'StayWithinBudgetTask',
  // simulation
  StartSimulationTask = 'StartSimulationTask',
  WaitForSimulationTask = 'WaitForSimulationTask',
  PIResilienceIndexTask = 'PIResilienceIndexTask',
  PIAveragePipeAgeTask = 'PIAveragePipeAgeTask',
  PIInflowDemandRatioTask = 'PIInflowDemandRatioTask',
  PIUnsatisfiedNodesCountTask = 'PIUnsatisfiedNodesCountTask',
  PIUnsatisfiedNodesDetailValueTask = 'PIUnsatisfiedNodesDetailValueTask',
  PIUnsatisfiedNodesRelativeCountTask = 'PIUnsatisfiedNodesRelativeCountTask',
  // Operating Condition
  CreateOperatingConditionTask = 'CreateOperatingConditionTask',
  ChangeOperatingConditionTask = 'ChangeOperatingConditionTask',
  DeleteOperatingConditionTask = 'DeleteOperatingConditionTask',
  // general component tasks
  CreateComponentTask = 'CreateComponentTask',
  DeleteComponentTask = 'DeleteComponentTask',
  MoveComponentTask = 'MoveComponentTask',
  ChangeComponentVisibilityTask = 'ChangeComponentVisibilityTask',
  // junction tasks
  JunctionUpdateCommentTask = 'JunctionUpdateCommentTask',
  JunctionUpdateDemandTask = 'JunctionUpdateDemandTask',
  JunctionUpdateElevationTask = 'JunctionUpdateElevationTask',
  JunctionUpdateFireflowTask = 'JunctionUpdateFireflowTask',
  JunctionUpdateLifetimeTask = 'JunctionUpdateLifetimeTask',
  JunctionUpdateModeTask = 'JunctionUpdateModeTask',
  JunctionUpdateScaleDemandTask = 'JunctionUpdateScaleDemandTask',
  // well tasks
  WellUpdateInflowTask = 'WellUpdateInflowTask',
  WellUpdateCommentTask = 'WellUpdateCommentTask',
  WellUpdateElevationTask = 'WellUpdateElevationTask',
  WellUpdateLifetimeTask = 'WellUpdateLifetimeTask',
  // spring tasks
  SpringUpdateInflowTask = 'SpringUpdateInflowTask',
  SpringUpdateCommentTask = 'SpringUpdateCommentTask',
  SpringUpdateElevationTask = 'SpringUpdateElevationTask',
  SpringUpdateLifetimeTask = 'SpringUpdateLifetimeTask',
  // pipe tasks
  PipeUpdateCommentTask = 'PipeUpdateCommentTask',
  PipeUpdateDiameterTask = 'PipeUpdateDiameterTask',
  PipeUpdateLengthTask = 'PipeUpdateLengthTask',
  PipeUpdateLifetimeTask = 'PipeUpdateLifetimeTask',
  PipeUpdateMinorlossTask = 'PipeUpdateMinorlossTask',
  PipeUpdateRoughnessTask = 'PipeUpdateRoughnessTask',
  PipeUpdateStatusTask = 'PipeUpdateStatusTask',
  PipeUpdateConstructionYearTask = 'PipeUpdateConstructionYearTask',
  PipeUpdateMaterialTask = 'PipeUpdateMaterialTask',
  // pump tasks
  PumpUpdateCommentTask = 'PumpUpdateCommentTask',
  PumpUpdateHeadCurveTask = 'PumpUpdateHeadCurveTask',
  PumpUpdateLifetimeTask = 'PumpUpdateLifetimeTask',
  PumpUpdatePowerTask = 'PumpUpdatePowerTask',
  PumpUpdateStatusTask = 'PumpUpdateStatusTask',
  // reservoir tasks
  ReservoirUpdateCommentTask = 'ReservoirUpdateCommentTask',
  ReservoirUpdateElevationTask = 'ReservoirUpdateElevationTask',
  ReservoirUpdateHeadTask = 'ReservoirUpdateHeadTask',
  ReservoirUpdateLifetimeTask = 'ReservoirUpdateLifetimeTask',
  // tank tasks
  TankUpdateCommentTask = 'TankUpdateCommentTask',
  TankUpdateDiameterTask = 'TankUpdateDiameterTask',
  TankUpdateElevationTask = 'TankUpdateElevationTask',
  TankUpdateInitlevelTask = 'TankUpdateInitlevelTask',
  TankUpdateLifetimeTask = 'TankUpdateLifetimeTask',
  // valve tasks
  // FCV
  FCVUpdateCommentTask = 'FCVUpdateCommentTask',
  FCVUpdateDiameterTask = 'FCVUpdateDiameterTask',
  FCVUpdateLifetimeTask = 'FCVUpdateLifetimeTask',
  FCVUpdateMaximumFlowTask = 'FCVUpdateMaximumFlowTask',
  FCVUpdateStatusTask = 'FCVUpdateStatusTask',
  // GPV
  GPVUpdateCommentTask = 'GPVUpdateCommentTask',
  GPVUpdateDiameterTask = 'GPVUpdateDiameterTask',
  GPVUpdateHeadCurveTask = 'GPVUpdateHeadCurveTask',
  GPVUpdateLifetimeTask = 'GPVUpdateLifetimeTask',
  GPVUpdateStatusTask = 'GPVUpdateStatusTask',
  // PBV
  PBVUpdateCommentTask = 'PBVUpdateCommentTask',
  PBVUpdateDiameterTask = 'PBVUpdateDiameterTask',
  PBVUpdateLifetimeTask = 'PBVUpdateLifetimeTask',
  PBVUpdatePressureDropTask = 'PBVUpdatePressureDropTask',
  PBVUpdateStatusTask = 'PBVUpdateStatusTask',
  // PRV
  PRVUpdateCommentTask = 'PRVUpdateCommentTask',
  PRVUpdateDiameterTask = 'PRVUpdateDiameterTask',
  PRVUpdateLifetimeTask = 'PRVUpdateLifetimeTask',
  PRVUpdateMaximumPressureTask = 'PRVUpdateMaximumPressureTask',
  PRVUpdateStatusTask = 'PRVUpdateStatusTask',
  // PSV
  PSVUpdateCommentTask = 'PSVUpdateCommentTask',
  PSVUpdateDiameterTask = 'PSVUpdateDiameterTask',
  PSVUpdateLifetimeTask = 'PSVUpdateLifetimeTask',
  PSVUpdatePressureLimitTask = 'PSVUpdatePressureLimitTask',
  PSVUpdateStatusTask = 'PSVUpdateStatusTask',
  // TCV
  TCVUpdateCommentTask = 'TCVUpdateCommentTask',
  TCVUpdateDiameterTask = 'TCVUpdateDiameterTask',
  TCVUpdateHeadlossCoefficientTask = 'TCVUpdateHeadlossCoefficientTask',
  TCVUpdateLifetimeTask = 'TCVUpdateLifetimeTask',
  TCVUpdateStatusTask = 'TCVUpdateStatusTask'
}

export default TaskTypeString;
