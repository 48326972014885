import EventType from "../EventType";

export interface EconomyBudgetChangedEventArgs {
  oldValue: number;
  newValue: number;
}

export default class EconomyBudgetChangedEvent extends CustomEvent<EconomyBudgetChangedEventArgs> {
  public constructor(detail: EconomyBudgetChangedEventArgs) {
    super(EventType.EconomyBudgetChanged, { detail })
  }
}
