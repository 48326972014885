import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import OperatingConditionChangedEvent from "../../events/events/OperatingConditionChangedEvent";
import UpdateTaskType from "../misc/UpdateTaskType";
import Task from "./base/Task";
import ChangeOperatingConditionTaskOptions, { IChangeOperatingConditionTaskOptions } from "./options/ChangeOperatingConditionTaskOptions";
import i18n from "../../i18n";

export default class ChangeOperatingConditionTask extends Task {
  protected _type: string = 'ChangeOperatingConditionTask';

  public constructor(options?: ChangeOperatingConditionTaskOptions) {
    if (!options) {
      options = new ChangeOperatingConditionTaskOptions();
    }

    super(options);

    this.onOperatingConditionChanged = this.onOperatingConditionChanged.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IChangeOperatingConditionTaskOptions) {
    const options = new ChangeOperatingConditionTaskOptions(plainOptions);
    return new ChangeOperatingConditionTask(options);
  }

  protected onOperatingConditionChanged(e: OperatingConditionChangedEvent) {
    let shouldUpdate = false;
    const options = this._options as ChangeOperatingConditionTaskOptions;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        const operatingCondition = e.detail.model.conditions.find(item => item.id === e.detail.newValue)
        if (operatingCondition !== undefined && operatingCondition.type === options.type) {
          shouldUpdate = true;
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }

    if (!shouldUpdate) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ChangeOperatingConditionTaskOptions;

    let description = '';
    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        const translatedType = i18n.t(`operating_condition_type.${options.type}`)
        description = i18n.t('challenge.task.change_operating_condition.exact_value', { type: translatedType });
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.change_operating_condition.any');
        break;
    }

    return description;
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.OperatingConditionChanged, this.onOperatingConditionChanged);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.OperatingConditionChanged, this.onOperatingConditionChanged);
  }

}
