import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import OpenSimulationResultsDialogTask from "../../tasks/OpenSimulationResultsDialog";

export default function OpenSimulationResultsDialogTasks(): Challenge {
    const taskGroups: TaskGroup[] = [
        new TaskGroup([
            new OpenSimulationResultsDialogTask(),
        ])
    ]

    return new Challenge(taskGroups, new ChallengeOptions({
        name: { en: 'OpenSimulationResultsDialogTasks' }
    }))
}
