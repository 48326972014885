import { OperatingConditionType } from "../../../api/client";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IChangeOperatingConditionTaskOptions extends ITaskOptions {
  updateTaskType?: UpdateTaskType
  type?: OperatingConditionType
}

export default class ChangeOperatingConditionTaskOptions extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public type?: OperatingConditionType;

  public constructor(options: IChangeOperatingConditionTaskOptions = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.type = options.type;

    this.constrainUpdateTaskType();
  }

  protected constrainUpdateTaskType() {
    if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
      throw new Error("Relative/Relative Factor task types not allowed");
    }
  }

  public static fromPlain(plainOptions?: IChangeOperatingConditionTaskOptions) {
    return new ChangeOperatingConditionTaskOptions(plainOptions);
  }

  public toJSON(): IChangeOperatingConditionTaskOptions {
    return {
      ...super.toJSON(),
      updateTaskType: this.updateTaskType,
      type: this.type
    }
  }
}
