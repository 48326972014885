import EventType from "../EventType";

export interface EconomyEnabledChangedEventArgs {
  oldValue: boolean;
  newValue: boolean;
}

export default class EconomyEnabledChangedEvent extends CustomEvent<EconomyEnabledChangedEventArgs> {
  public constructor(detail: EconomyEnabledChangedEventArgs) {
    super(EventType.EconomyEnabledChanged, { detail })
  }
}
