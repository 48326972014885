import { useState } from "react";
import { ValvePSV } from "../../data/ValvePSV";
import { TextField } from "@mui/material";
import { PSVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValvePSVFormProps {
    valves: ValvePSV[]
}

export function MultiValvePSVForm(props: MultiValvePSVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [pressureLimit, setPressureLimit] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.pressure_limit
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackPressureLimit, setFallbackPressureLimit] = useState<number | undefined>(findTimedMulti({
        items: valves,
        get: _ => _.pressure_limit
    }, context.currentDate, undefined));

    const { t } = useTranslation();

    function updateTimedState() {
        setPressureLimit(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.pressure_limit
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackPressureLimit(findComputedTimedMulti({
            items: valves,
            get: _ => _.pressure_limit
        }, currentDate, undefined))
    }

    function validate() {
        if (isFirstYear || pressureLimit !== '') {
            const parsedPressureLimit = parseFloatOrUndefined(pressureLimit)

            if (!validNumber(parsedPressureLimit) || parsedPressureLimit! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PSVTimedProps> {
        const timed: Partial<PSVTimedProps> = {}

        const applyPressureLimit = parseFloatOrUndefined(pressureLimit)
        if (applyPressureLimit !== undefined) {
            timed.pressure_limit = applyPressureLimit
        }

        return timed
    }

    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <TextField
                type="number"
                required
                value={(pressureLimit)}
                onChange={(event) => setPressureLimit((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.psv.pressure_limit')}
                helperText={isFirstYear ? undefined : fallbackPressureLimit?.toString()}
            />
        </>}></MultiValveForm>
}
