/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumeration of state types.
 */
export enum StateType {
    SCHEDULED = 'SCHEDULED',
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}