import { EWAResult } from "../../../api/client";
import { EWAResultType } from "../simulation/EWAResultType";

/**
 * Returns a record (keys: node IDs, values: weights) with weights for heatmap plotting
 *
 * @param result - EWAResult to extract weights from
 * @param year - year to extract
 * @param operatingCondition - currently selected operating condition
 * @param resultType - type of EWAResult to return
 *
 * @returns record with weights
 */
export default function getResultWeight(result: EWAResult, year: number, operatingCondition: string | undefined, resultType: EWAResultType = EWAResultType.UnsatisfiedNodes) {
    let values: number[] = [];
    if (operatingCondition !== undefined && result.timed_results !== undefined) {
        let entries = Object.entries(result.timed_results);
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i];
            let single_year = parseFloat(entry[0]);
            if (single_year !== year) {
                continue;
            }
            let data = entry[1];
            for (let j = 0; j < data.length; j++) {
                let dataEntry = data[j];
                if (dataEntry.name === resultType && dataEntry.operating_condition === operatingCondition && dataEntry.details !== undefined) {
                    values.push(dataEntry.details);
                }
            }
        }
    }
    return values
}
