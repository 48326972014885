import { AppBar, Box, IconButton, Link, Menu, MenuItem, Toolbar, useMediaQuery, } from "@mui/material";
import logo from "@/assets/logo.png";
import React from "react";
import { AppBarLinkButton } from "@/widgets/Buttons";
import { Link as RouterLink, useHref } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@/api/auth";
import { Logout, Settings } from "@mui/icons-material";
import LanguageSelect from "./LanguageSelect";
import UserSettings from "./UserSettings";

export interface EwaToolAppBarProps {
    portalContainerCallbackRef?: (node: any) => void
}

export function EwaToolAppBar(props: EwaToolAppBarProps) {
    const {
        portalContainerCallbackRef
    } = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const [userSettingsOpen, setUserSettingsOpen] = React.useState<boolean>(false)
    const open = Boolean(anchorEl);
    const keycloak = useKeycloak()
    const mediaQuery1k = useMediaQuery('(max-width:1000px)')
    const homeButtonRoute = useHref('/tool')
    const wrapperMarginTop = mediaQuery1k ? '124.5px' : '64px'
    function handleClose() {
        setAnchorEl(null);
    };

    function handleLogout() {
        if (keycloak === undefined) return

        keycloak.logout({
            redirectUri: window.location.origin + '/'
        })
    }

    function renderLeft() {
        return <Box display='flex' alignItems={"center"}>
            <AppBarLinkButton to={homeButtonRoute} edge={"start"} label={"Home"} icon={<img src={logo} alt={"logo"} width={64} height={64}></img>}></AppBarLinkButton>
            <Link
                component={RouterLink}
                underline="none"
                variant="h5"
                to={homeButtonRoute}
                sx={{
                    marginLeft: "10px",
                    color: "white !important"
                }}
            >
                EWA
            </Link>
        </Box>
    }

    function renderRight() {
        return <>
            <LanguageSelect />
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => setUserSettingsOpen(true)}><Settings />{t('app_bar.user_settings')}</MenuItem>
                <MenuItem onClick={handleLogout}><Logout />{t('app_bar.logout')}</MenuItem>
            </Menu>
            {userSettingsOpen && <UserSettings onClose={() => setUserSettingsOpen(false)} />}
        </>
    }

    function renderCenter() {
        return <div ref={portalContainerCallbackRef} style={{ flexGrow: 1 }}></div>
    }

    function renderContent() {
        if (mediaQuery1k) {
            return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{renderLeft()}</Box>
                    <Box>{renderRight()}</Box>
                </Box>
                <Box>
                    {renderCenter()}
                </Box>
            </Box>
        }

        return <>
            {renderLeft()}
            {renderCenter()}
            {renderRight()}
        </>
    }

    return <Box sx={{ marginTop: wrapperMarginTop }}>
        <AppBar elevation={0} sx={{
            backgroundColor: "#09253E !important",
            flexDirection: "row"
        }}>
            <Toolbar sx={{ width: '100%' }}>
                {renderContent()}
            </Toolbar>
        </AppBar>
    </Box>;
}
