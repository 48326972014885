import { LocalizableText } from "./LocalizableText";
import EconomyManager, { BudgetKey } from "./managers/EconomyManager";

export interface IChallengeOptions {
  name?: LocalizableText
  completionText?: LocalizableText
  economyEnabled?: boolean
  economyBudget?: number
  priceMap?: [BudgetKey, number][]
  score?: number
  targetTime?: number
}

export type MedalTime = {
  text: string,
  color: string,
  time: number
}

export default class ChallengeOptions {
  public name: LocalizableText;
  public completionText: LocalizableText;
  public economyEnabled: boolean = false;
  public economyBudget: number = 0;
  public priceMap: Map<BudgetKey, number>;
  public score: number = 0;
  public targetTime: number = 0;

  public constructor(options: IChallengeOptions = {}) {
    this.name = options.name ?? { en: ""};
    this.completionText = options.completionText ?? {};

    this.economyBudget = options.economyBudget ?? 0;
    this.economyEnabled = options.economyEnabled ?? false;
    this.score = options.score ?? 0;
    this.targetTime = options.targetTime ?? 0;
    this.priceMap = new Map(options.priceMap ?? []);
  }

  public static computeMedalTimes(targetTime: number) : MedalTime[]|undefined {
    if (targetTime === 0) return undefined;

    const medals: MedalTime[] = [
      { text: 'Gold', time: Math.round(targetTime * 1), color: '#FFD700' },
      { text: 'Silver', time: Math.round(targetTime * 1.2), color: '#C0C0C0' },
      { text: 'Bronze', time: Math.round(targetTime * 1.4), color: '#CD7F32' }
    ];

    return medals;
  }

  public get medals(): MedalTime[]|undefined {
    return ChallengeOptions.computeMedalTimes(this.targetTime);
  }

  public activate () {
    if (this.economyEnabled) {
      EconomyManager.getInstance().budget = this.economyBudget;
      EconomyManager.getInstance().enabled = true;
    }
  }

  public deactivate () {
    if (this.economyEnabled) {
      EconomyManager.getInstance().enabled = false;
      EconomyManager.getInstance().budget = 0;
    }
  }

  public toJSON(): IChallengeOptions {
    return {
      name: this.name,
      economyEnabled: this.economyEnabled,
      economyBudget: this.economyBudget,
      priceMap: Array.from(this.priceMap),
      score: this.score,
      targetTime: this.targetTime,
      completionText: this.completionText
    }
  }
}
