import { EWAComponentType } from "../../../data/EWAComponentType";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IChangeComponentVisibilityTaskOptions extends ITaskOptions {
  componentType?: EWAComponentType
  visible?: boolean
  updateTaskType?: UpdateTaskType
}

export default class ChangeComponentVisibilityTaskOptions extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public componentType?: EWAComponentType
  public visible: boolean

  public constructor(options: IChangeComponentVisibilityTaskOptions = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.componentType = options.componentType;

    this.visible = options.visible ?? true
    this.constrainUpdateTaskType();
  }

  protected constrainUpdateTaskType() {
    if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
      throw new Error("Relative/Relative Factor task types not allowed");
    }
  }

  public static fromPlain(plainOptions?: IChangeComponentVisibilityTaskOptions) {
    return new ChangeComponentVisibilityTaskOptions(plainOptions);
  }

  public toJSON(): IChangeComponentVisibilityTaskOptions {
    return {
      ...super.toJSON(),
      componentType: this.componentType,
      visible: this.visible,
      updateTaskType: this.updateTaskType
    }
  }
}
