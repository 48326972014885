import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface ModelSavedEventArgs {
  model: EWAModel
}

export default class ModelSavedEvent extends CustomEvent<ModelSavedEventArgs> {
  public constructor(detail: ModelSavedEventArgs) {
    super(EventType.ModelSaved, { detail })
  }
}
