import { OperatingConditionType } from "../../../api/client";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface ICreateDeleteOperatingConditionTaskOptions extends ITaskOptions {
  type?: OperatingConditionType
}

export default class CreateDeleteOperatingConditionTaskOptions extends TaskOptions {
  public type?: OperatingConditionType

  public constructor(options: ICreateDeleteOperatingConditionTaskOptions = {}) {
    super(options);

    this.type = options.type;
  }

  public static fromPlain(plainOptions?: ICreateDeleteOperatingConditionTaskOptions) {
    return new CreateDeleteOperatingConditionTaskOptions(plainOptions);
  }

  public toJSON(): ICreateDeleteOperatingConditionTaskOptions {
    return {
      ...super.toJSON(),
      type: this.type
    }
  }
}
