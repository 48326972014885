import { useEffect, useState } from "react";
import { ReservoirConditionProps, ReservoirTimedProps } from "../../api/client";
import { Reservoir } from "../../data/Reservoir";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { ReservoirApply } from "./ReservoirApply";
import { TextField, InputAdornment } from "@mui/material";
import { BaseSinglePointForm, BaseSinglePointFormApply } from "./BaseSinglePointForm";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";

export interface SingleReservoirFormProps {
    reservoir: Reservoir
}

export function SingleReservoirForm(props: SingleReservoirFormProps) {
    const { reservoir } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = reservoir.data.lifetime as number | number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: reservoir,
        get: _ => _
    }, currentDate, {}) as Partial<ReservoirTimedProps>
    const fallbackTimed = findComputedTimed({
        item: reservoir,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<ReservoirTimedProps>

    const [head, setHead] = useState<string>(currentTimed.head?.toString() ?? '');
    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: reservoir,
            get: _ => _
        }, currentDate, {}) as Partial<ReservoirTimedProps>

        setHead(timed.head?.toString() ?? '');
    }, [reservoir, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || head !== '') {
            const parsedHead = parseFloatOrUndefined(head)
            if (!validNumber(parsedHead) || parsedHead! <= 0) {
                return false
            }
        }

        return true
    }
    function handleApply(a: BaseSinglePointFormApply) {
        const timed: Partial<ReservoirTimedProps> = {}
        const applyHead = parseFloatOrUndefined(head)
        if (applyHead !== undefined) {
            timed.head = applyHead
        }

        let apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            latitude: a.latitude,
            longitude: a.longitude,
            elevation: a.elevation,
            strength: findProp({ item: reservoir, get: _ => _.strength }, undefined),
            conditional: {} as ReservoirConditionProps,
            timed: timed
        } as ReservoirApply
        reservoir.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return (<BaseSinglePointForm validate={validate} handleApply={handleApply} feature={reservoir} timedChildren={<>
        <TextField
            required
            type="number"
            value={head}
            onChange={(event) => setHead(event.target.value)}
            style={{ flexGrow: 1 }}
            label={t('model.components.reservoir.head')}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.head?.toString()}
        />
    </>}>
    </BaseSinglePointForm>)
}
