import React from "react";
import MDEditor, { commands, ICommand } from '@uiw/react-md-editor';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Tabs } from "@mui/material";
import rehypeSanitize from "rehype-sanitize";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
  ref: React.RefObject<TaskGroupDescriptionEdtor>
  locale: string
  description: string
  descriptionIndex: number
  saveDescription: (description: string) => void
  tabs: JSX.Element[]
  tabIndex: number
  onTabChange: (value: number) => void
}

export interface TaskGroupDescriptionEdtorState {
  description: string
  changed: boolean
}

class TaskGroupDescriptionEdtor extends React.Component<Props, TaskGroupDescriptionEdtorState>
{
  protected extraCommands: ICommand[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      description: props.description,
      changed: false
    }

    this.handleSave = this.handleSave.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onTabChange = this.onTabChange.bind(this)

    this.extraCommands = [
      commands.fullscreen
    ]
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<TaskGroupDescriptionEdtorState>, snapshot?: any): void {
    if (this.props.descriptionIndex !== prevProps.descriptionIndex ||
      this.props.locale !== prevProps.locale ||
      this.props.description !== prevProps.description
      ) {
      this.setState({
        description: this.props.description
      })
    }
  }

  onTabChange(tab: number) {
    this.handleSave()
    this.props.onTabChange(tab)
  }

  handleSave() {
    this.props.saveDescription(this.state.description)

    this.setState({
      changed: false
    })
  }

  onDescriptionChange(description?: string) {
    this.setState({
      description: description ?? '',
      changed: true
    })
  }

  render() {
    const { description, changed } = this.state
    const {
      tabIndex,
      tabs
    } = this.props
    const height = 'calc(100vh - 235px)'
    return <div data-color-mode="light">
      <div style={{ display: 'flex', margin: '1rem 0' }}>
        <Button
          disabled={!changed}
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={this.handleSave}
          sx={{ marginBottom: '10px', marginTop: '10px' }}
          color="success"
        >{this.props.t('challenge.editor.task_group_options.save_page')}</Button>
        <Tabs value={tabIndex} onChange={(e, value) => this.onTabChange(value)}>
          {tabs}
        </Tabs>
      </div>
      <MDEditor
        height={height}
        value={description}
        onChange={this.onDescriptionChange}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
        }}
        textareaProps={{spellCheck: 'true'}}
      />
    </div>
  }
}

export default withTranslation('translation', { withRef: true })(TaskGroupDescriptionEdtor)
