import { useTranslation } from "react-i18next"
import { OperatingConditionType } from "../../../../../api/client"
import { useMemo } from "react"
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useDispatch } from "react-redux"
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice"

export interface OperatingConditionTypeSelectProps {
    value?: OperatingConditionType
    onChange: (_?: OperatingConditionType) => void
}

export default function OperatingConditionTypeSelect(props: OperatingConditionTypeSelectProps) {
    const {
        value
    } = props

    const { t, i18n } = useTranslation()

    const dispatch = useDispatch()

    const items = useMemo(() => {
        const items: JSX.Element[] = [
            <MenuItem key='' value=''><i>{t('general.any')}</i></MenuItem>
        ]

        for (const value of Object.values(OperatingConditionType)) {
            items.push(<MenuItem
                key={value}
                value={value}
            >
                {t(`operating_condition_type.${value}`)}
            </MenuItem>)
        }

        return items
    }, [i18n.language])

    function onChange(e: SelectChangeEvent) {
        const value = e.target.value
        props.onChange(value === '' ? undefined : value as OperatingConditionType)

        dispatch(setTaskOptionsDirty(true))
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="type">{t('operating_condition_type.label')}</InputLabel>
        <Select id="type" onChange={onChange} value={value ?? ''} label={t('operating_condition_type.label')}>
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.operating_condition_type_hint')}</FormHelperText>
    </FormControl>
}
