import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export type TaskItemOptionalFieldProps = {
  prefix: string
  translatePrefix: boolean
  text: string
  valueTranslationPrefix?: string
}

export default function TaskItemOptionalFied(props: TaskItemOptionalFieldProps) {
  const {
    translatePrefix,
    prefix,
    text,
    valueTranslationPrefix
  } = props
  const {t} = useTranslation()
  const translatedPrefix = translatePrefix ? t(prefix) : prefix
  const value = valueTranslationPrefix !== undefined ? t(`${valueTranslationPrefix}.${text}`) : text

  return <Typography variant="body2">{translatedPrefix}: {value}</Typography>
}
