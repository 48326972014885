import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export interface PipeStatusSelectProps {
    status: string
    setStatus: (value: SetStateAction<string>) => void
}

export default function PipeStatusSelect(props: PipeStatusSelectProps) {
    const {status, setStatus} = props
    const {t} = useTranslation();
    return <FormControl fullWidth>
    <InputLabel id="pipe-status-label">{ t('model.components.pipe.status.label') }</InputLabel>
    <Select
        labelId="pipe-status-label"
        id="pipe-status"
        value={status}
        label={ t('model.components.pipe.status.label') }
        onChange={(event, newValue) => setStatus(event.target.value)}
    >
        <MenuItem value={"OPEN"}>{ t('model.components.pipe.status.OPEN') }</MenuItem>
        <MenuItem value={"CLOSED"}>{ t('model.components.pipe.status.CLOSED') }</MenuItem>
    </Select>
</FormControl>
}
