import { FormHelperText, Slider, TextField } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseFeature } from "../../data/BaseFeature";
import { findProp } from "../../utils/findProp";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { BaseForm } from "./BaseForm";
import { useFormContext } from "./FormContext";

export interface BaseSingleFormApply {
    id: string
    comment: string
    lifetime: number | number[]
}

export interface BaseSingleFormProps {
    feature: BaseFeature<any, any, any>
    children?: ReactNode;
    timedChildren?: ReactNode;
    conditionChildren?: ReactNode;
    handleApply: (apply: BaseSingleFormApply) => void;
    validate: () => boolean;
}

export function BaseSingleForm(props: BaseSingleFormProps) {
    const { feature, handleApply, validate } = props;
    const formContext = useFormContext();
    const [id, setId] = useState<string>(feature.id);
    const [comment, setComment] = useState<string>(findProp({
        item: feature,
        get: _ => _.comment
    }, ''));
    const [lifeTime, setLifeTime] = useState<number | number[]>([feature.visibleFrom, feature.visibleTo]);
    const { t } = useTranslation()

    function handleDelete() {
        if (feature instanceof BaseFeature)
            feature.delete();

        formContext.setModelWrapper(new EWAModelWrapper(formContext.modelWrapper!.model));
        formContext.setSelectedFeatureIndices([])
    };

    function doApply() {
        handleApply({
            "id": id,
            "comment": comment,
            "lifetime": lifeTime
        } as BaseSingleFormApply)
    }

    useEffect(() => {
        setId(feature.id);
        setComment(feature.properties.comment !== undefined ? feature.properties.comment : '')
        setLifeTime([feature.visibleFrom, feature.visibleTo]);
    }, [feature])

    return (<BaseForm features={[feature]} handleApply={doApply} validate={validate} handleDelete={handleDelete}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px"
        }}>
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                <TextField value={id} onChange={(event) => setId(event.target.value)}
                    style={{ flexGrow: 1, marginBottom: "10px" }} label="Id" />
                <TextField value={comment} onChange={(event) => setComment(event.target.value)}
                    style={{ flexGrow: 1, marginBottom: "10px" }} label={t('general.comment') as string} />
                {props.children !== undefined && props.children}
            </div>
        </div>
        {props.conditionChildren !== undefined && <>
            <FormHelperText>{t('model.components.operating_condition_props')}</FormHelperText>
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "20px",
                marginRight: "20px"
            }}>{props.conditionChildren}</div>
        </>}
        {props.timedChildren !== undefined && <>
            <FormHelperText>{t('model.components.time_props')}</FormHelperText>
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "20px",
                marginRight: "20px"
            }}>{props.timedChildren}</div>
        </>}
        <div style={{ marginLeft: "40px", marginRight: "40px", marginBottom: "10px", display: "none" }}>
            <Slider style={{ display: "none" }}
                getAriaLabel={() => t('model.components.lifetime')}
                value={lifeTime}
                onChange={(event, newValue) => setLifeTime(newValue)}
                valueLabelDisplay="on"
                min={formContext.minDate}
                max={formContext.maxDate}
                marks={[
                    {
                        value: formContext.minDate!,
                        label: formContext.minDate!,
                    },
                    {
                        value: formContext.maxDate!,
                        label: formContext.maxDate!,
                    },
                ]}
            />
        </div>
    </BaseForm>
    )
}
