enum EventType {
  ComponentCreated = "COMPONENT_CREATD",
  ComponentDeleted = "COMPONENT_DELETED",
  ComponentMoved = "COMPONENT_MOVED",
  ComponentVisibilityChanged = "COMPONENT_VISIBILITY_CHANGED",
  JunctionApply = "JUNCTION_APPLY",
  SpringApply = "SPRING_APPLY",
  WellApply = "WELL_APPLY",
  ReservoirApply = "RESERVOIR_APPLY",
  TankApply = "TANK_APPLY",
  PipeApply = "PIPE_APPLY",
  PumpApply = "PUMP_APPLY",
  ValveFCVApply = "VALVE_FCV_APPLY",
  ValveGPVApply = "VALVE_GPV_APPLY",
  ValvePBVApply = "VALVE_PBV_APPLY",
  ValvePRVApply = "VALVE_PRV_APPLY",
  ValvePSVApply = "VALVE_PSV_APPLY",
  ValveTCVApply = "VALVE_TCV_APPLY",
  ActiveChallengeChanged = "ACTIVE_CHALLENGE_CHANGED",
  EconomyEnabledChanged = "ECONOMY_ENABLED_CHANGED",
  EconomyBudgetChanged = "ECONOMY_BUDGET_CHANGED",
  YearChanged = "YEAR_CHANGED",
  OperatingConditionChanged = "OPERATING_CONDITION_CHANGED",
  OperatingConditionCreated = "OPERATING_CONDITION_CREATED",
  OperatingConditionDeleted = "OPERATING_CONDITION_DELETED",
  ModelSaved = "MODEL_SAVED",
  SimulationStarted = "SIMULATION_STARTED",
  SimulationNewResult = "SIMULATION_NEW_RESULT",
  ResultsShown = "RESULTS_SHOWN",
  ChallengeCompleted = "CHALLENGE_COMPLETED",
  TabChanged = "TAB_CHANGED",
  EditDialogOpened = "EDIT_DIALOG_OPENED",
  SimulationResultsDialogOpened = "SIMULATION_RESULTS_DIALOG_OPENED",
  OrthoLayerChanged = "ORTHO_LAYER_CHANGED",
  HomeChallengeUploaded = "HOME_CHALLENGE_UPLOADED",
  HomeChallengeDeleted = "HOME_CHALLENGE_DELETED"
}

export default EventType;
