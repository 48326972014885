import { OperatingConditionType } from "../../api/client";
import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface OperatingConditionCreatedEventArgs {
  id: string
  type: OperatingConditionType
  model: EWAModel
}

export default class OperatingConditionCreatedEvent extends CustomEvent<OperatingConditionCreatedEventArgs> {
  public constructor(detail: OperatingConditionCreatedEventArgs) {
    super(EventType.OperatingConditionCreated, { detail })
  }
}
