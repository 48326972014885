import { Box, Paper, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { ariaTabProps } from '@/widgets/TabPanel';
import EwaThemeProvider from '@/EwaThemeProvider';
import FeatureOverview from './FeatureOverview';
import FeatureModel from './FeatureModel';
import FeatureSimulations from './FeatureSimulations';
import FeatureChallenges from './FeatureChallenges';

export interface FeaturesProps {

}

export interface FeatureProps {
    value: number
    index: number
}

export default function Features(props: FeaturesProps) {
    const { t } = useTranslation('landing')
    const theme = useTheme()
    const [tab, setTab] = useState(0)

    return <>
        <Typography variant='h3'>{t('sections.features.header')}</Typography>
        <EwaThemeProvider mode='light'>
            <Paper sx={{ padding: 3, borderRadius: 3, backgroundColor: theme.palette.grey[50] }}>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    <Tab label={t('sections.features.overview.header')} {...ariaTabProps(0, 'features-')} />
                    <Tab label={t('sections.features.model.header')} {...ariaTabProps(1, 'features-')} />
                    <Tab label={t('sections.features.simulations.header')}  {...ariaTabProps(2, 'features-')} />
                    <Tab label={t('sections.features.challenges.header')}  {...ariaTabProps(3, 'features-')} />
                </Tabs>
                <Box sx={{ width: '100%' }}>
                    <FeatureOverview value={tab} index={0} />
                    <FeatureModel value={tab} index={1} />
                    <FeatureSimulations value={tab} index={2} />
                    <FeatureChallenges value={tab} index={3} />
                </Box>
            </Paper>
        </EwaThemeProvider>
    </>
}
