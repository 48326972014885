import { Tank } from "../../data/Tank";
import { TankApply } from "./TankApply";
import { useEffect, useState } from "react";
import { TankConditionProps, TankTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { TextField, InputAdornment } from "@mui/material";
import { BaseSinglePointForm, BaseSinglePointFormApply } from "./BaseSinglePointForm";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";

export interface SingleTankFormProps {
    tank: Tank
}

export function SingleTankForm(props: SingleTankFormProps) {
    const { tank } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = tank.data.lifetime as number | number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: tank,
        get: _ => _
    }, currentDate, {}) as Partial<TankTimedProps>
    const fallbackTimed = findComputedTimed({
        item: tank,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<TankTimedProps>

    const [initLevel, setInitLevel] = useState<string>(currentTimed.initlevel?.toString() ?? '');
    const [diameter, setDiameter] = useState<string>(currentTimed.diameter?.toString() ?? '');

    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: tank,
            get: _ => _
        }, currentDate, {}) as Partial<TankTimedProps>

        setInitLevel(timed.initlevel?.toString() ?? '')
        setDiameter(timed.diameter?.toString() ?? '')
    }, [tank, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || initLevel !== '') {
            const parsedInitLevel = parseFloatOrUndefined(initLevel)
            if (!validNumber(parsedInitLevel) || parsedInitLevel! <= 0) {
                return false
            }
        }

        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        return true
    }

    function handleApply(a: BaseSinglePointFormApply) {
        const timed: Partial<TankTimedProps> = {}

        const applyInitLevel = parseFloatOrUndefined(initLevel)
        if (applyInitLevel !== undefined) {
            timed.initlevel = applyInitLevel
        }

        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }

        let apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            latitude: a.latitude,
            longitude: a.longitude,
            elevation: a.elevation,
            strength: findProp({ item: tank, get: _ => _.strength }, undefined),
            conditional: {} as TankConditionProps,
            timed: timed
        } as TankApply
        tank.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return (<BaseSinglePointForm validate={validate} handleApply={handleApply} feature={tank} timedChildren={<>
        <TextField
            required
            type="number"
            value={initLevel}
            onChange={(event) => setInitLevel(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.tank.initlevel')}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.initlevel?.toString()}
        />
        <TextField
            required
            type="number"
            value={diameter}
            onChange={(event) => setDiameter(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.tank.diameter')}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.diameter?.toString()}
        />
    </>}>
    </BaseSinglePointForm>)
}
