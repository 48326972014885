import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import ReservoirUpdateCommentTask from "../../tasks/ReservoirUpdateCommentTask";
import ReservoirUpdateElevationTask from "../../tasks/ReservoirUpdateElevationTask";
import ReservoirUpdateHeadTask from "../../tasks/ReservoirUpdateHeadTask";
import ReservoirUpdateLifetimeTask from "../../tasks/ReservoirUpdateLifetimeTask";

export default function ReservoirUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new ReservoirUpdateCommentTask(),
      new ReservoirUpdateElevationTask(),
      new ReservoirUpdateHeadTask(),
      new ReservoirUpdateLifetimeTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
