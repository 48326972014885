import { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { FCVTimedProps } from "../../api/client";
import { findTimed, findComputedTimed } from "../../utils/findTimed";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { SingleValveForm } from "./SingleValveForm";
import { singleFormComputeIfFirstYear } from "./util";
import { useTranslation } from "react-i18next";
import { ValveFCV } from "../../data/ValveFCV";

export interface SingleValveFCVFormProps {
    valve: ValveFCV
}

export function SingleValveFCVForm(props: SingleValveFCVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))
    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<FCVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<FCVTimedProps>

    const [maximumFlow, setMaximumFlow] = useState<string>(currentTimed.maximum_flow?.toString() ?? '');

    const { t } = useTranslation();

    function updateTimedState(timed: Partial<FCVTimedProps>) {
        setMaximumFlow(timed.maximum_flow?.toString() ?? '')
    }

    function validate() {
        if (isFirstYear || maximumFlow !== '') {
            const parsedMaximumFlow = parseFloatOrUndefined(maximumFlow)
            if (!validNumber(parsedMaximumFlow) || parsedMaximumFlow! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<FCVTimedProps> {
        const timed: Partial<FCVTimedProps> = {}
        const applyMaximumFlow = parseFloatOrUndefined(maximumFlow)
        if (applyMaximumFlow !== undefined) {
            timed.maximum_flow = applyMaximumFlow
        }
        return timed
    }

    return (<SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(maximumFlow)}
                onChange={(event) => setMaximumFlow((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.fcv.maximum_flow')}
                InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackTimed.maximum_flow?.toString()}
            />
        </>
        }></SingleValveForm>)
}
