import { OperatingConditionType } from "../../../../api/client";
import { ICreateDeleteOperatingConditionTaskOptions } from "../../../tasks/options/CreateDeleteOperatingConditionTaskOptions";
import { withTranslation } from "react-i18next";
import OperatingConditionTypeSelect from "./common_inputs/OperatingConditionTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  type?: OperatingConditionType
}

class CreateDeleteOperatingConditionTaskOptionsForm extends TaskOptionsExtrasForm<State, ICreateDeleteOperatingConditionTaskOptions> {
  constructor(props: TaskSubformPropsAlias<ICreateDeleteOperatingConditionTaskOptions>) {
    super(props);

    this.renderSelect = this.renderSelect.bind(this)
  }

  stateFromProps(props: TaskSubformPropsAlias<ICreateDeleteOperatingConditionTaskOptions>): State {
    return {
      type: props.options.type
    }
  }

  public extractOptions() {
    return {
      type: this.state.type
    }
  }

  renderSelect() {
    return <OperatingConditionTypeSelect value={this.state.type} onChange={(_) => this.setState({ type: _ })} />
  }

  render() {
    return <>
      {this.renderSelect()}
    </>;
  }
}

export default withTranslation('translation', { withRef: true })(CreateDeleteOperatingConditionTaskOptionsForm)
