import { Autocomplete, Button, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react'
import ChallengeManager from '../../managers/ChallengeManager';
import DebugChallengeManager, { ChallengeFactory } from '../../util/DebugChallengeManager';

interface Props {

}

interface State {
  selectedChallengeFactory?: ChallengeFactory
}

class LoadSaveChallenge extends React.Component<Props, State> {
  protected challenges = DebugChallengeManager.getInstance().challenges;
  protected get challengeCount() {
    return this.challenges.length
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      selectedChallengeFactory: undefined
    };

    this.onLoadChallenge = this.onLoadChallenge.bind(this);
  }

  onNoChallenge() {
    ChallengeManager.getInstance().challenge = undefined;
  }

  onLoadChallenge() {
    if (this.state.selectedChallengeFactory === undefined) return

    const challenge = this.state.selectedChallengeFactory.fn()
    challenge.options.name.en = this.state.selectedChallengeFactory.text;
    ChallengeManager.getInstance().challenge = challenge;
  }

  onReset() {
    ChallengeManager.getInstance().reset();
  }


  render() {
    const challengeSelectOptions = [];
    for (let i = 0; i < this.challengeCount; i++) {
      challengeSelectOptions.push(<MenuItem value={i} key={i}>{this.challenges[i].text}</MenuItem>);
    }

    return <div>
      <h3>Challenges</h3>
      <Autocomplete
        options={this.challenges}
        renderInput={(params) => <TextField {...params} label="Example Challenges" />}
        value={this.state.selectedChallengeFactory ?? null}
        getOptionLabel={option => option.text}
        isOptionEqualToValue={(option, value) => option.fn === value.fn}
        onChange={(e, value) => this.setState({
          selectedChallengeFactory: value ?? undefined
        })}
        sx={{marginBottom: '5px'}}
      />
      <div>
        <Button variant="outlined" onClick={this.onNoChallenge}>No Challenge</Button>
        <Button variant="outlined" onClick={this.onLoadChallenge}>Load</Button>
        <Button variant="outlined" onClick={this.onReset}>Reset</Button>
      </div>
    </div>
  }
}

export default LoadSaveChallenge;
