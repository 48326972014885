import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from "react";
import ComparisonMode, { comparisonModeToHumanString } from "../../../../../utils/ComparisonMode";
import { useDispatch } from "react-redux";
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice";

export interface ComparisonModeSelectProps {
    value: ComparisonMode
    onValueChange: (_: ComparisonMode) => void
    onlyEquality?: boolean
}

export default function ComparisonModeSelect(props: ComparisonModeSelectProps) {
    const {
        value,
        onValueChange,
    } = props

    // ensure that by default its false
    const onlyEquality = props.onlyEquality ?? false

    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()

    const items = useMemo(() => {
        const keys = [ComparisonMode.eq, ComparisonMode.neq]
        if (!onlyEquality) {
            keys.push(ComparisonMode.gt,ComparisonMode.gte, ComparisonMode.lt, ComparisonMode.lte)
        }

        const items: JSX.Element[] = []
        for (const key of keys) {
            items.push(<MenuItem
                key={key}
                value={key}
            >
                {comparisonModeToHumanString(key as ComparisonMode)}
            </MenuItem>)
        }

        return items
    }, [onlyEquality, i18n.language])

    function onChange(e: SelectChangeEvent<string>) {
        onValueChange(e.target.value as ComparisonMode)
        dispatch(setTaskOptionsDirty(true))
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="comparisonMode">{t('challenge.editor.tasks.comparison_mode')}</InputLabel>
        <Select
            id="comparisonMode"
            value={value}
            onChange={onChange}
            label={t('challenge.editor.tasks.comparison_mode')}
        >
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.comparison_mode_hint')}</FormHelperText>
    </FormControl>
}
