import { VerySimpleTimedItem } from "./VerySimpleTimedItem";
import { cloneDeep } from "lodash";

//TODO Should be VeryEfficientTimeSeries
export class AdaptedTimeSeries<T> {
    private readonly _items: VerySimpleTimedItem<T>[] = [];
    private _computedItems: VerySimpleTimedItem<T>[] = [];

    public findValue(t: number): T | undefined {
        let index = this.findIndex(t);
        if (index !== -1) {
            return this._items[index].value
        }
    }

    public findValueIfDefined(t: number): T|undefined {
        let index = this.findIndexIfDefined(t);
        if (index !== -1) {
            return this._items[index].value
        }
    }

    public findComputedValue(t: number):T|undefined {
        let index = this.findComputedIndex(t)
        if (index !== -1) {
            return this._computedItems[index].value
        }
    }

    public getAllItems() {
        return this._items;
    }

    public items() {
        let items: { [id: string]: any; } = {};
        for (let i = 0; i < this._items.length; i++) {
            items[this._items[i].time.toString()] = this._items[i].value;
        }
        return items
    }

    public pointgeometries(): object[] {
        let geometries = []
        for (let i = 0; i < this._items.length; i++) {
            let coordinates = [...(this._items[i].value as any).coordinates]
            coordinates.push(this._items[i].time)
            geometries.push(
                {
                    coordinates: coordinates,
                    type: "Point"
                }
            )
        }
        return geometries;
    }

    public linegeometries(): object[] {
        let geometries = []
        for (let i = 0; i < this._items.length; i++) {
            // let coordinates_before=(this._items[i].value as any).coordinates
            let coordinates = cloneDeep((this._items[i].value as any).coordinates)
            let lines = []
            for (let j = 0; j < coordinates.length; j++) {
                let linecoords = coordinates[j]
                linecoords.push(this._items[i].time)
                lines.push(linecoords)
            }
            geometries.push(
                {
                    coordinates: lines,
                    type: "LineString"
                }
            )
        }
        return geometries;
    }

    public setItem(time: number, value: T) {
        let index = this._items.findIndex(item => item.time === time);
        if (index === -1) {
            this._items.push(new VerySimpleTimedItem(time, value));
        } else {
            this._items[index] = new VerySimpleTimedItem(time, value);
        }
        this._items.sort((a, b) => a.time < b.time ? -1 : a.time > b.time ? 1 : 0);
    }

    /**
     * fill up _computeItems based on values in _items
     */
    public fillComputedItems () {
        const computedItems: VerySimpleTimedItem<T>[] = []

        for (let i = 0; i < this._items.length; i++) {
            if (i === 0) {
                computedItems.push({ ...this._items[i]})
            } else {
                computedItems.push({
                    time: this._items[i].time,
                    value: {
                        ...computedItems[i-1].value,
                        ...this._items[i].value
                    }
                })
            }
        }

        this._computedItems = computedItems
    }

    public getFirstValue(): any {
        return this._items[0].value;
    }

    public getFirstTime(): any {
        return this._items[0].time;
    }

    public getLastValue(): any {
        return this._items[this._items.length - 1].value;
    }

    public getLastTime(): any {
        return this._items[this._items.length - 1].time;
    }

    public hasValue(currentTime: number): boolean {
        for (let i = 0; i < this._items.length; i++) {
            if (this._items[i].time === currentTime) return true;
        }
        return false;
    }

    public getValue(currentTime: number): T {
        if (currentTime < this.getFirstTime()) {
            return this.getFirstValue();
        } else if (currentTime > this.getLastTime()) {
            return this.getLastValue()
        } else {
            return this.findValue(currentTime)!;
        }
    }

    public getFirstComputedValue(): any {
        return this._items[0].value;
    }

    public getLastComputedValue(): any {
        return this._items[this._items.length - 1].value;
    }

    public getComputedValue(currentTime: number): T {
        if (currentTime < this.getFirstTime()) {
            return this.getFirstComputedValue();
        } else if (currentTime > this.getLastTime()) {
            return this.getLastComputedValue()
        } else {
            return this.findComputedValue(currentTime)!;
        }
    }

    public findIndex(t: number): number {
        let ret = -1;
        for (let i = this._items.length - 1; i >= 0; i--) {
            if (this._items[i].time <= t) {
                return i;
            }
        }
        return ret;
    }

    public findIndexIfDefined(t: number): number {
        let ret = -1;
        for (let i = this._items.length - 1; i >= 0; i--) {
            if (this._items[i].time === t) {
                return i;
            }
        }
        return ret;
    }

    public findComputedIndex(t: number): number {
        let ret = -1;
        for (let i = this._computedItems.length - 1; i >= 0; i--) {
            if (this._computedItems[i].time <= t) {
                return i;
            }
        }
        return ret;
    }
}
