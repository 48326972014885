import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { SpringApply } from "../../views/forms/SpringApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class SpringUpdateInflowTask extends NumberValueUpdateTask<SpringApply> {
  protected _type: string = 'SpringUpdateInflowTask';
  protected componentName: string = "ewa_component_type.Spring_one";
  protected fieldName: string = "model.components.spring.output";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new SpringUpdateInflowTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.SpringApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.SpringApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<SpringApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.inflow,
      oldValue: args.oldApply.timed.inflow
    }
  }
}
