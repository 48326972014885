import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Box, Slider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { EWAModel } from "../../data/EWAModel";
import EventManager from "../../events/EventManager";
import { EditForm } from "../forms/EditForm";
import { FormContext, FormContextProps } from "../forms/FormContext";
import { EWAModelWrapper } from "./EWAModelWrapper";

export interface FeatureEditDialogProps {
  onClose: () => void
  model?: EWAModel,
  minDate: number,
  maxDate: number
  setSelectedFeatureIndices: (_: number[]) => void
  modelWrapper: EWAModelWrapper | undefined
  setModelWrapper: (_: EWAModelWrapper) => void
  condition: string | undefined
  currentDate: number
  setCurrentDate: (_: number) => void
  selectedFeatureIndices?: number[]
}

export default function FeatureEditDialog(props: FeatureEditDialogProps) {
  const {
    modelWrapper,
    onClose,
    selectedFeatureIndices,
    minDate,
    maxDate
  } = props

  const headerPortalContainerRef = useRef<any>()
  const footerPortalContainerRef = useRef<any>()
  const [lifeTimeMin, setLifeTimeMin] = useState<number>(minDate)
  const [lifeTimeMax, setLifeTimeMax] = useState<number>(maxDate)

  useEffect(() => {
    const model = modelWrapper?.model!

    EventManager.getInstance().onEditDialogOpened({
      model: model,
      year: model.currentDate,
      selectedFeatureIndices: selectedFeatureIndices ?? []
    })
  }, [])

  useEffect(() => {
    let min = minDate
    let max = maxDate

    if (selectedFeatureIndices !== undefined) {
      for (const selectedFeatureIndex of selectedFeatureIndices) {
        const feature = modelWrapper?.model.features[selectedFeatureIndex]
        if (feature === undefined) continue

        const lifetime = feature.data.lifetime ?? [minDate, maxDate]
        min = Math.max(min, lifetime[0])
        max = Math.min(max, lifetime[1])
      }
    }

    setLifeTimeMin(min)
    setLifeTimeMax(max)
  }, [selectedFeatureIndices])

  function renderFormContext() {
    const value: FormContextProps = {
      headerPortalContainerRef,
      footerPortalContainerRef,
      onDelete: () => {
        onClose()
      },
      ...props
    }
    return <FormContext.Provider value={value}>
      <EditForm />
    </FormContext.Provider>
  }

  function renderYearSlider() {
    if (lifeTimeMax === lifeTimeMin) return

    const {
      currentDate,
      setCurrentDate
    } = props

    const marks = []
    for (let i = minDate; i <= maxDate; i += 10) {
      marks.push({
        value: i,
        label: i
      })
    }

    return <Slider
      value={currentDate}
      onChange={(event, newValue) => setCurrentDate(newValue as number)}
      step={null}
      marks={marks}
      min={lifeTimeMin}
      max={lifeTimeMax}
    />
  }

  function renderContent() {
    return <>
      <div>
        {renderFormContext()}
      </div>
      <Box sx={{ marginTop: 2 }}>
        {renderYearSlider()}
      </Box>
    </>
  }

  function dialogOnClose(reason: string) {
    if (reason === "escapeKeyDown") {
      onClose()
    }
  }

  return <Dialog open maxWidth="md" fullWidth onClose={(e, reason) => dialogOnClose(reason)}>
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div ref={headerPortalContainerRef}></div>
      <IconButton aria-label="close" onClick={() => onClose()}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {renderContent()}
    </DialogContent>
    <DialogActions><div ref={footerPortalContainerRef}></div></DialogActions>
  </Dialog>
}
