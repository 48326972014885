import EventManager from "../../events/EventManager";
import SimulationResultsDialogOpenedEvent from "../../events/events/SimulationResultsDialogOpened";
import EventType from "../../events/EventType";
import i18n from "../../i18n";
import Task from "./base/Task";
import TaskOptions, { ITaskOptions } from "./options/TaskOptions";

export default class OpenSimulationResultsDialogTask extends Task {
    protected _type: string = 'OpenSimulationResultsDialogTask';

    public constructor(options?: TaskOptions) {
        super(options)

        this.onOpen = this.onOpen.bind(this)
    }

    public get description(): string {
        return i18n.t('challenge.task.open_simulation_results_dialog')
    }

    protected onOpen(e: SimulationResultsDialogOpenedEvent) {
        this.currentCount++;
        this.checkCompletion();
        this.triggerTaskViewUpdate();
    }

    public activate() {
        super.activate();
        EventManager.getInstance().attachListener(EventType.SimulationResultsDialogOpened, this.onOpen);
    }

    public deactivate() {
        super.deactivate();
        EventManager.getInstance().detachListener(EventType.SimulationResultsDialogOpened, this.onOpen);
    }

    public static fromPlainTaskOptions(plainOptions: ITaskOptions) {
        const options = new TaskOptions(plainOptions);
        return new OpenSimulationResultsDialogTask(options);
    }
}
