import ComponentApplyEvent, { ComponentApplyEventArgs } from "../../../events/events/ComponentApplyEvent";
import UpdateValueTaskOptions from "../options/UpdateValueTaskOptions";
import Task from "./Task";

export interface ValuesPair<V> {
  newValue?: V
  oldValue?: V
}

export default abstract class ValueUpdateTask<A, V> extends Task {
  protected abstract componentName: string;
  protected abstract fieldName: string;

  public constructor( options?: UpdateValueTaskOptions<V>) {
    if (options === undefined) {
      options = new UpdateValueTaskOptions<V>();
    }
    super(options);

    this.onValueUpdate = this.onValueUpdate.bind(this);
  }

  public get optionYear(): string | undefined {
    const year = (this._options as UpdateValueTaskOptions<V>).year;

    return year !== undefined ? year.toString() : year;
  }

  public get optionId(): string | undefined {
    const id = (this._options as UpdateValueTaskOptions<V>).id;

    return id;
  }

  public get optionTargetValue(): V|undefined {
    return (this._options as UpdateValueTaskOptions<V>).targetValue
  }

  protected abstract determineUpdate(e: ComponentApplyEventArgs<A>): boolean;
  protected abstract retrieveValues(args: ComponentApplyEventArgs<A>): ValuesPair<V>;

  protected onValueUpdate(e: ComponentApplyEvent<A>) {
    if (this.determineUpdate(e.detail)) {
      this.currentCount++;
      this.checkCompletion();
      this.triggerTaskViewUpdate();
    }
  }
}
