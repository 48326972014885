import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import UpdateTaskType from "../misc/UpdateTaskType";
import Task from "./base/Task";
import ChangeOrthoLayerTaskOptions, { IChangeOrthoLayerTaskOptions } from "./options/ChangeOrthoLayerTaskOptions";
import i18n from "../../i18n";
import OrthoLayerChangedEvent from "../../events/events/OrthoLayerChangedEvent";
import { orthoLayerText } from "../../data/OrthoLayer";

export default class ChangeOrthoLayerTask extends Task {
  protected _type: string = 'ChangeOrthoLayerTask';

  public constructor(options?: ChangeOrthoLayerTaskOptions) {
    if (!options) {
      options = new ChangeOrthoLayerTaskOptions();
    }

    super(options);

    this.onOrthoLayerChanged = this.onOrthoLayerChanged.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IChangeOrthoLayerTaskOptions) {
    const options = new ChangeOrthoLayerTaskOptions(plainOptions);
    return new ChangeOrthoLayerTask(options);
  }

  protected onOrthoLayerChanged(e: OrthoLayerChangedEvent) {
    let shouldUpdate = false;
    const options = this._options as ChangeOrthoLayerTaskOptions;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        if (e.detail.newOrthoLayer === options.orthoLayer) {
          shouldUpdate = true;
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }

    if (!shouldUpdate) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ChangeOrthoLayerTaskOptions;

    let description = '';
    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        description = i18n.t('challenge.task.change_ortho_layer.exact_value', { layer: orthoLayerText(options.orthoLayer) })
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.change_ortho_layer.any')
        break;
    }

    return description;
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.OrthoLayerChanged, this.onOrthoLayerChanged);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.OrthoLayerChanged, this.onOrthoLayerChanged);
  }

}
