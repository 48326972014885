import EventManager from "../../events/EventManager";
import EditDialogOpenedEvent from "../../events/events/EditDialogOpened";
import EventType from "../../events/EventType";
import i18n from "../../i18n";
import Task from "./base/Task";
import OpenEditDialogTaskOptions, { IOpenEditDialogTaskOptions } from "./options/OpenEditDialogTaskOptions";

export default class OpenEditDialogTask extends Task {
    protected _type: string = 'OpenEditDialogTask';

    public constructor(options?: OpenEditDialogTaskOptions) {
        if (!options) {
            options = new OpenEditDialogTaskOptions()
        }

        super(options)

        this.onOpen = this.onOpen.bind(this)
    }

    public get optionYear(): string | undefined {
        return (this._options as OpenEditDialogTaskOptions).year?.toString();
    }

    public get optionType(): string | undefined {
        const type = (this._options as OpenEditDialogTaskOptions).componentType;

        if (type !== undefined) {
            return i18n.t(`ewa_component_type.${type}`, { count: 0 }) as string
        }

        return undefined
    }

    public get optionId(): string | undefined {
        return (this._options as OpenEditDialogTaskOptions).id;
    }

    public get description(): string {
        return i18n.t('challenge.task.open_edit_dialog')
    }

    protected onOpen(e: EditDialogOpenedEvent) {
        const options = this._options as OpenEditDialogTaskOptions

        if (options.year !== undefined && e.detail.year !== options.year) {
            return
        }

        // check if configured id is among selected features
        const checkId = options.id !== undefined
        const checkType = options.componentType !== undefined
        if ([checkId, checkType].includes(true)) {
            const features = e.detail.model.features

            let idCounter = 0
            let componentTypeCounter = 0
            for (const i of e.detail.selectedFeatureIndices) {
                const feature = features[i]

                if (checkId && feature.id === options.id) idCounter++
                if (checkType && feature.componentType === options.componentType) componentTypeCounter++

            }

            if (checkId && idCounter === 0) return
            if (checkType && componentTypeCounter === 0) return
        }

        this.currentCount++;
        this.checkCompletion();
        this.triggerTaskViewUpdate();
    }

    public activate() {
        super.activate();
        EventManager.getInstance().attachListener(EventType.EditDialogOpened, this.onOpen);
    }

    public deactivate() {
        super.deactivate();
        EventManager.getInstance().detachListener(EventType.EditDialogOpened, this.onOpen);
    }

    public static fromPlainTaskOptions(plainOptions: IOpenEditDialogTaskOptions) {
        const options = new OpenEditDialogTaskOptions(plainOptions);
        return new OpenEditDialogTask(options);
    }
}
