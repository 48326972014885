import ComparisonMode from "../../../utils/ComparisonMode";
import UpdateTaskType from "../../misc/UpdateTaskType";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";

export default class UpdateValueTaskWithoutRelativeOptions<T> extends UpdateValueTaskOptions<T> {
    public constructor(options: IUpdateValueTaskOptions<T> = {}) {
        super(options);
    }

    protected constrainUpdateTaskType(): void {
        if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
            throw new Error("Relative/Relative Factor task types not allowed");
        }
    }

    protected constrainComparisonMode(): void {
        if (![ComparisonMode.eq, ComparisonMode.neq].includes(this.comparisonMode)) {
            throw new Error("Only equal/not equal comparison modes allowed");
        }
    }
}
