import { Typography } from "@mui/material"
import Task from "../tasks/base/Task"

export interface TaskItemDescriptionProps {
    task: Task
}

export default function TaskItemDescription(props: TaskItemDescriptionProps) {
    const {
        task
    } = props

    return <Typography variant="body2">{task.description}</Typography>
}
