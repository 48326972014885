import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValveFCVApply } from "../../views/forms/ValveFCVApply";
import StringValueUpdateTask from "./base/StringValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateStringTaskOptions from "./options/UpdateStringTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class FCVUpdateCommentTask extends StringValueUpdateTask<ValveFCVApply> {
  protected _type: string = 'FCVUpdateCommentTask';
  protected componentName: string = "valve_component_type.FCV";
  protected fieldName: string = "general.comment";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<string>) {
    const options = new UpdateStringTaskOptions(plainOptions);
    return new FCVUpdateCommentTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValveFCVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValveFCVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValveFCVApply>): ValuesPair<string> {
    return {
      newValue: args.apply.comment,
      oldValue: args.oldApply.comment
    }
  }
}
