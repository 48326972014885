import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EWAModelWrapper } from "../EWAModelWrapper";

export interface OperatingConditionSelectProps {
    modelWrapper: EWAModelWrapper
    value: string
    setValue: (_:string) => void
}

export default function OperatingConditionSelect(props:OperatingConditionSelectProps) {
    const {
        modelWrapper,
        value,
        setValue
    } = props

    const {t} = useTranslation()
    const conditions = modelWrapper.model.conditions

    return <FormControl margin="normal" sx={{ minWidth: '200px'}}>
      <InputLabel>{t('model.operating_condition.label')}</InputLabel>
      <Select
        value={value}
        onChange={(event) => setValue(event.target.value)}
        label={t('model.operating_condition.label')}
      >
        {conditions.map(item => <MenuItem
          value={item.id}
          key={item.id}
        >
          {item.name}
        </MenuItem>)}
      </Select>
    </FormControl>
}
