import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValveGPVApply } from "../../views/forms/ValveGPVApply";
import StringValueUpdateTask from "./base/StringValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateStringTaskOptions from "./options/UpdateStringTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class GPVUpdateHeadCurveTask extends StringValueUpdateTask<ValveGPVApply> {
  protected _type: string = 'GPVUpdateHeadCurveTask';
  protected componentName: string = "valve_component_type.GPV";
  protected fieldName: string = "model.components.valve.gpv.head_curve";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<string>) {
    const options = new UpdateStringTaskOptions(plainOptions);
    return new GPVUpdateHeadCurveTask(options);
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValveGPVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValveGPVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValveGPVApply>): ValuesPair<string> {
    return {
      newValue: args.apply.timed.headloss_curve,
      oldValue: args.oldApply.timed.headloss_curve
    };
  }
}
