import { cloneDeep } from "lodash";
import { BaseApply } from "../views/forms/BaseApply";
import { AdaptedTimeSeries } from "./AdaptedTimeSeries";
import { BaseGeometry } from "./BaseGeometry";
import { EWAComponentType } from "./EWAComponentType";
import { EWAModel } from "./EWAModel";
import { ValveComponentType } from "./ValveComponentType";
import * as turf from '@turf/turf'
import { NodeModel } from "./BasePointFeature";
import { LinkModel } from "./BaseLinestringFeature";

export type ComponentModel = NodeModel|LinkModel

export abstract class BaseFeature<V extends BaseGeometry, T, U> {
    type: string = "Feature";
    collection: EWAModel
    //id: string;
    visibleFrom: number;
    visibleTo: number;
    _geometry: V = {} as V;
    // _timedCoordinates: VeryInefficientTimeSeries<V> = new VeryInefficientTimeSeries<V>();
    _timedProperties: AdaptedTimeSeries<U> = new AdaptedTimeSeries<U>();
    data: any

    protected constructor(collection: EWAModel, data: any, visibleTo: number, currentDate: number) {
        this.data = data
        this.collection = collection;
        this.visibleFrom = currentDate;
        this.visibleTo = visibleTo;
        if (data.properties !== undefined && data.properties.timed !== undefined) {
            let keys = Object.keys(data.properties.timed)
            for (let i = 0; i < keys.length; i++) {
                this._timedProperties.setItem(parseInt(keys[i]), data.properties.timed[keys[i]])
            }
            this._timedProperties.fillComputedItems()
        }
    }

    public get valveType(): ValveComponentType | undefined {
        return undefined;
    }

    public get bbox() {
        return turf.bbox(this._geometry)
    }

    public get apiConditional () {
        const conditional = cloneDeep(this.properties.conditional)
        if (this.collection.conditions !== undefined) {
            for (const condition of this.collection.conditions) {
                if (condition.id !== undefined && !conditional[condition.id]) {
                    conditional[condition.id] = {}
                }
            }
        }

        return conditional
    }

    public get properties(): any {
        return this.data.properties
    }

    get id(): string {
        return this.data.id;
    }

    get comment(): string {
        return this.data.comment;
    }

    public abstract delete(): void

    public abstract toComponentModel(): ComponentModel

    abstract get geometry(): any

    abstract get componentType(): EWAComponentType; //for faster filter capabilities

    public apply(apply: BaseApply<T, U>) {
        this._timedProperties.setItem(this.collection.currentDate, apply.timed);
        this._timedProperties.fillComputedItems()
        if (this.data.properties.conditional === undefined)
            this.data.properties.conditional = {}
        this.data.properties.conditional[this.collection.currentCondition!] = apply.conditional
        this.data.properties.comment = apply.comment

        const newId = apply.id
        if (newId !== '') {
            this.data.id = newId
        }
    }

}
