import { ReactNode, useMemo } from 'react'
import MaterialReactTable, { MRT_Cell, MRT_Row, type MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { ComponentTableProps, TableFeature, filterAndMapComponents } from "./Table";
import { ValveComponentType } from "../../../data/ValveComponentType";
import {
  FCVConditionProps,
  FCVTimedProps,
  GPVConditionProps,
  GPVTimedProps,
  PBVConditionProps,
  PBVTimedProps,
  PRVConditionProps,
  PRVTimedProps,
  PSVConditionProps,
  PSVTimedProps,
  TCVConditionProps,
  TCVTimedProps
} from "../../../api/client";
import { useTranslation } from 'react-i18next';
import { EWAComponentType } from '../../../data/EWAComponentType';
import { BaseFeature } from '../../../data/BaseFeature';
import RowActions from './RowActions';
import { EWAModelWrapper } from '../EWAModelWrapper';

type ValveTimedProps = PRVTimedProps |
  FCVTimedProps |
  GPVTimedProps |
  TCVTimedProps |
  PSVTimedProps |
  PBVTimedProps

type ValveConditionProps = PRVConditionProps |
  FCVConditionProps |
  GPVConditionProps |
  TCVConditionProps |
  PSVConditionProps |
  PBVConditionProps

type ValveTableFeature = TableFeature<ValveTimedProps, ValveConditionProps>

export const timedPropFallbacks: Record<ValveComponentType, ValveTimedProps> = {
  GPV: { diameter: 250, headloss_curve: '' },
  TCV: { diameter: 250, headloss_coefficient: 0 },
  FCV: { diameter: 250, maximum_flow: 0 },
  PSV: { diameter: 250, pressure_limit: 0 },
  PRV: { diameter: 250, maximum_pressure: 0 },
  PBV: { diameter: 250, pressure_drop: 0 }
}

function getValveTimedFallback(feature: BaseFeature<any, any, any>) {
  return timedPropFallbacks[feature.valveType!]
}

export function ValveTable(props: ComponentTableProps) {
  const {
    data,
    currentDate,
    currentCondition,
    localization,
    setModelWrapper,
    onFeatureEdit
  } = props;
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })

  const tableData = useMemo<ValveTableFeature[]>(() => {
    const tableData = filterAndMapComponents<ValveTimedProps, ValveConditionProps>(
      data.features,
      EWAComponentType.valve,
      currentDate,
      getValveTimedFallback,
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  const columns = useMemo<MRT_ColumnDef<ValveTableFeature>[]>(
    () => {
      const pipeStatusOptions = [
        {
          text: t('model.components.pipe.status.OPEN'),
          value: 'OPEN'
        },
        {
          text: t('model.components.pipe.status.CLOSED'),
          value: 'CLOSED'
        },
      ]

      const valveTypeOptions: { value: any, text: string }[] = []
      for (const value of Object.values(ValveComponentType)) {
        valveTypeOptions.push({ value, text: t(`valve_component_type.${value}`) })
      }

      return [
        {
          header: 'ID',
          accessorKey: 'id'
        },
        {
          header: t('valve_component_type.label') as string,
          accessorKey: 'subType',
          filterVariant: 'select',
          filterSelectOptions: valveTypeOptions,
          Cell: ({ cell }) => renderSubType(cell.getValue<string | undefined>()),
        },
        {
          header: `${t('model.components.valve.diameter')} (mm)`,
          accessorKey: 'timedProps.diameter',
          Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
          filterVariant: 'range',
          filterFn: 'betweenInclusive'
        },
        {
          header: t('model.components.pipe.status.label') as string,
          accessorFn: (row) => row.conditionProps.status ?? '',
          filterVariant: 'select',
          filterSelectOptions: pipeStatusOptions,
          Cell: ({ cell }) => renderStatus(cell.getValue<string | undefined>()),
          align: 'center',
        }
      ]
    },
    [i18n.language]
  )

  function onDeleteRow (props: {
    row: MRT_Row<ValveTableFeature>,
    table: MRT_TableInstance<ValveTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onStartRowEdit (props: {
    row: MRT_Row<ValveTableFeature>,
    table: MRT_TableInstance<ValveTableFeature>,
  }) {
    const { row } = props

    onFeatureEdit(row.original.id)
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  function renderStatus(status?: string) {
    if (status === undefined) return

    return status !== '' ? t(`model.components.pipe.status.${status}`) : ''
  }

  function renderSubType(value?: string) {
    if (value === undefined) return

    return t(`valve_component_type.${value}`)
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    renderRowActions={(props: {
      cell: MRT_Cell<ValveTableFeature>;
      row: MRT_Row<ValveTableFeature>;
      table: MRT_TableInstance<ValveTableFeature>;
    }): ReactNode => <RowActions<ValveTableFeature>
     {...props}
     onStartRowEdit={onStartRowEdit}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
