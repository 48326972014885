import { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";
import UpdateValueTaskWithoutRelativeOptions from "./UpdateValueTaskWithoutRelativeOptions";

export default class UpdateBooleanTaskOptions extends UpdateValueTaskWithoutRelativeOptions<boolean>
{
  public constructor(options: IUpdateValueTaskOptions<boolean> = {}) {
    super(options);
  }

  public static fromPlain(plainOptions: IUpdateValueTaskOptions<boolean>) {
    return new UpdateBooleanTaskOptions(plainOptions);
  }
}
