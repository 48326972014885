import { ClickAwayListener, Fade, Paper, Popper, useTheme } from "@mui/material";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { usePopupState, bindPopper } from "material-ui-popup-state/hooks";
import ComponentMenuButton from "./ComponentMenuButton";
import ComponentSubMenu from "./ComponentSubMenu";
import componentIsValve from "./componentIsValve";
import { EWAModelWrapper } from "../EWAModelWrapper";
import { ComponentToggleVisibilityButton } from "./ComponentToggleVisibilityButton";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface ValveComponentMenuProps {
    selectedComponentButton: EWAComponentType | ValveComponentType | null
    editLassoMode: EWAComponentType | ValveComponentType | null
    editCreateMode: EWAComponentType | ValveComponentType | null
    setComponentAddMode: (_: EWAComponentType | ValveComponentType) => void
    setComponentLassoMode: (_: EWAComponentType | ValveComponentType) => void
    unsetComponentModes: () => void
    economyEnabled: boolean
    modelWrapper: EWAModelWrapper
    showComponents: EWAComponentType[]
    setShowComponents: (_: EWAComponentType[]) => void
}

export default function ValveComponentMenu(props: ValveComponentMenuProps) {
    const {
        selectedComponentButton,
        editCreateMode,
        editLassoMode,
        setComponentAddMode,
        setComponentLassoMode,
        economyEnabled,
        modelWrapper,
        setShowComponents,
        showComponents
    } = props
    const isValve = selectedComponentButton !== null && componentIsValve(selectedComponentButton)
    const componentType = isValve ? selectedComponentButton : EWAComponentType.valve
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    const popupState = usePopupState({ variant: 'popper', popupId: componentType })
    const commonButtonProps = {
        selectedComponentButton,
        editCreateMode,
        editLassoMode,
        setComponentAddMode: (componentType: EWAComponentType | ValveComponentType) => {
            popupState.close()
            setComponentAddMode(componentType)
        },
        setComponentLassoMode: (componentType: EWAComponentType | ValveComponentType) => {
            popupState.close()
            setComponentLassoMode(componentType)
        },
        economyEnabled,
        parentPopupState: popupState,
        modelWrapper
    }

    return <>
        <ClickAwayListener onClickAway={() => popupState.close()}>
            <div>
                <ComponentMenuButton
                    economyEnabled={economyEnabled}
                    editCreateMode={editCreateMode}
                    editLassoMode={editLassoMode}
                    componentType={componentType}
                    popupState={popupState}
                    selectedComponentButton={selectedComponentButton}
                    modelWrapper={modelWrapper}
                />
                <Popper {...bindPopper(popupState)} placement={"right"} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ backgroundColor, display: "flex", flexDirection: "column" }}>
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.fcv}
                                />
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.gpv}
                                />
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.tcv}
                                />
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.prv}
                                />
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.psv}
                                />
                                <ComponentSubMenu
                                    {...commonButtonProps}
                                    componentType={ValveComponentType.pbv}
                                />
                                <ComponentToggleVisibilityButton
                                    componentType={EWAComponentType.valve}
                                    setShowComponents={setShowComponents}
                                    showComponents={showComponents}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    </>
}
