import { PipeConditionProps, PipeModel, PipeProps, PipeTimedProps } from "../api/client";
import EventManager from "../events/EventManager";
import { PipeApply } from "../views/forms/PipeApply";
import { BaseLinestringFeature } from "./BaseLinestringFeature";
import { EWAComponentType } from "./EWAComponentType";

export class Pipe extends BaseLinestringFeature<PipeConditionProps, Partial<PipeTimedProps>> {

    public get componentType(): EWAComponentType {
        return EWAComponentType.pipe;
    }

    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.pipe) ? 1 : 0;
    }

    get length(): number {
        return this.data.properties.length;
    }

    public apply(apply: PipeApply): void {
        const currentDate = this.collection.currentDate;
        const props = this.data.properties as PipeProps
        const oldApply: PipeApply = {
            id: this.id,
            comment: this.comment,
            lifetime: this.data.lifetime,
            length: props.length,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as PipeConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<PipeTimedProps>
        }

        props.length = apply.length

        super.apply(apply);
        EventManager.getInstance().onPipeApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.pipe,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            startnode: this.startnode,
            endnode: this.endnode,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                length: this.length,
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: EWAComponentType.pipe,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as PipeModel

        return model
    }
}
