import { Data, PlotData } from "plotly.js";
import { EWASimulationDTO, SingleResult } from "../../../../../api/client";
import getTimedResults from "./getTimedResults";
import ScenarioValues from "../ScenarioValue";
import palette from "../../../../../utils/palette";
import { Scenario } from "../../Scenario";

export type ResultTypeFilter = (_: SingleResult) => boolean
export type GenerateTraces = (
    years: number[],
    values: ScenarioValues[],
    color: string,
    enabledScenarios: Scenario[],
    ewaSimulationName: string) => Data[]


export default function createPlotData(ewaSimulations: EWASimulationDTO[], operatingCondition: string | undefined, enabledScenarios: Scenario[], resultTypeFilter: ResultTypeFilter, generateTraces: GenerateTraces): Data[] {
    const data: Data[] = []

    for (const [index, ewaSimulation] of ewaSimulations.entries()) {
        if (ewaSimulation.result?.timed_results === undefined) {
            continue
        }

        const resultCollection: ScenarioValues[] = []
        const years = Object.keys(ewaSimulation.result.timed_results).sort().map(_ => parseFloat(_))

        for (const year of years) {
            const results = getTimedResults(ewaSimulation.result, year, resultTypeFilter, operatingCondition)
            resultCollection.push(results)
        }

        const paletteColor = palette[index % palette.length]
        const traces = generateTraces(years, resultCollection, paletteColor, enabledScenarios, ewaSimulation.name)

        // group traces together by simulation name
        for (const trace of traces) {
            const tmp = trace as PlotData
            tmp.legendgroup = ewaSimulation.name
            tmp.legendgrouptitle = {
                text: ewaSimulation.name
            }
        }

        data.push(...traces)
    }
    return data
}
