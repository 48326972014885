import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChallengeOptions } from "../../challenges/ChallengeOptions";
import { PlainTaskGroup } from "../../challenges/TaskGroup";
import { PlainTask } from "../../challenges/tasks/base/Task";
import { ITaskGroupOptions } from "../../challenges/TaskGroupOptions";
import { v4 as uuidv4 } from 'uuid';

const LOCALE_STORAGE_NAME = 'ewa/challenge-editor-state'

type PersistentStateFields = {
  id: string
  challengeOptions: IChallengeOptions
  taskGroups: PlainTaskGroup[]
  requiredChallenges: string[]
}

type NonPersistentStateFields = {
  taskGroupIndex: number
  taskIndex: number
  locale: string
  challengeMetaDirty: boolean
  challengeOptionsDirty: boolean
  taskGroupOptionsDirty: boolean
  taskOptionsDirty: boolean
}

export type ChallengeEditorState = PersistentStateFields & NonPersistentStateFields

export interface LoadChallengePayload {
  challengeOptions?: IChallengeOptions
  taskGroups?: PlainTaskGroup[]
  id?: string
  requiredChallenges?: string[]
}

export interface ModifyChallengeMetaPayload {
  id?: string
  requiredChallenges?: string[]
}

const emptyTask: PlainTask = {
  type: 'CreateComponentTask',
  options: {}
}

const emptyTaskGroup: PlainTaskGroup = {
  options: {},
  tasks: []
}

function createInitialTaskGroup(): PlainTaskGroup {
  const taskGroup: PlainTaskGroup = {
    ...emptyTaskGroup
  }
  taskGroup.tasks = [{ ...emptyTask }]

  return taskGroup
}

function createDefaultPersistentFields(): PersistentStateFields {
  return {
    id: uuidv4(),
    challengeOptions: {},
    taskGroups: [createInitialTaskGroup()],
    requiredChallenges: []
  }
}

function createDefaultNonPersistentFields(): NonPersistentStateFields {
  return {
    taskGroupIndex: 0,
    taskIndex: 0,
    locale: 'en',
    challengeMetaDirty: false,
    challengeOptionsDirty: false,
    taskGroupOptionsDirty: false,
    taskOptionsDirty: false
  }
}

function createDefaultState(): ChallengeEditorState {
  return {
    ...createDefaultPersistentFields(),
    ...createDefaultNonPersistentFields()
  }
}

function supportsLocaleStorage() {
  return typeof Storage !== 'undefined'
}

function getStateFromLocalStorage(): ChallengeEditorState|undefined {
  if (!supportsLocaleStorage()) {
    return undefined
  }

  const serialized = localStorage.getItem(LOCALE_STORAGE_NAME)

  if (serialized !== null) {
    try {
      const unserialized = JSON.parse(serialized) as PersistentStateFields
      return {
        ...unserialized,
        ...createDefaultNonPersistentFields()
      } as ChallengeEditorState
    } catch (e) {
      console.error(e)
    }
  }

  return undefined
}

function saveStateToLocalStorage(state: ChallengeEditorState) {
  if (!supportsLocaleStorage()) {
    return
  }
  const fields: PersistentStateFields = {
    id: state.id,
    challengeOptions: state.challengeOptions,
    taskGroups: state.taskGroups,
    requiredChallenges: state.requiredChallenges
  }

  const serialized = JSON.stringify(fields)

  localStorage.setItem(LOCALE_STORAGE_NAME, serialized)
}

const initialState: ChallengeEditorState = createDefaultState()

const challengeEditorSlice = createSlice({
  name: 'challengeEditor',
  initialState,
  reducers: {
    loadChallengeFromLocalStorage(state, action: Action) {
      const newState = getStateFromLocalStorage()

      if (newState !== undefined) {
        return newState
      } else {
        return createDefaultState()
      }
    },
    clearChallenge(state, action: Action) {
      state.challengeOptions = {}
      state.taskIndex = 0
      state.taskGroupIndex = 0
      state.taskGroups = [createInitialTaskGroup()]
      state.id = uuidv4()
      state.requiredChallenges = []

      state.challengeMetaDirty = false
      state.challengeOptionsDirty = false
      state.taskGroupOptionsDirty = false
      state.taskOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    loadChallenge(state, action: PayloadAction<LoadChallengePayload>) {
      state.taskIndex = 0
      state.taskGroupIndex = 0
      state.challengeOptions = action.payload.challengeOptions ?? {}
      state.taskGroups = action.payload.taskGroups ?? []
      state.id = action.payload.id ?? uuidv4()
      state.requiredChallenges = action.payload.requiredChallenges ?? []

      state.challengeMetaDirty = false
      state.challengeOptionsDirty = false
      state.taskGroupOptionsDirty = false
      state.taskOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    changeActiveTaskGroup(state, action: PayloadAction<number>) {
      state.taskIndex = 0
      state.taskGroupIndex = action.payload
      state.taskGroupOptionsDirty = false
      state.taskOptionsDirty = false
    },
    changeActiveTask(state, action: PayloadAction<number>) {
      state.taskIndex = action.payload
      state.taskOptionsDirty = false
    },
    updateChallengeMeta(state, action: PayloadAction<ModifyChallengeMetaPayload>) {
      state.id = action.payload.id ?? uuidv4()
      state.requiredChallenges = action.payload.requiredChallenges ?? []
      state.challengeMetaDirty = false

      saveStateToLocalStorage(state)
    },
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload
    },
    setChallengeOptions(state, action: PayloadAction<IChallengeOptions>) {
      state.challengeOptions = action.payload
      state.challengeOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    setTaskGroups(state, action: PayloadAction<PlainTaskGroup[]>) {
      state.taskGroups = action.payload

      saveStateToLocalStorage(state)
    },
    setTaskGroupIndex(state, action: PayloadAction<number>) {
      state.taskGroupIndex = action.payload
      state.taskGroupOptionsDirty = false
      state.taskOptionsDirty = false
    },
    setTaskIndex(state, action: PayloadAction<number>) {
      state.taskIndex = action.payload
      state.taskOptionsDirty = false
    },
    addTaskGroup(state, action: Action) {
      const taskGroups = [...state.taskGroups]

      taskGroups.push(createInitialTaskGroup())

      state.taskGroups = taskGroups

      saveStateToLocalStorage(state)
    },
    removeTaskGroup(state, action: Action) {
      let taskGroupIndex = state.taskGroupIndex
      const taskGroup = state.taskGroups[taskGroupIndex]
      if (taskGroup === undefined) return

      const taskGroups = state.taskGroups.filter(_ => _ !== taskGroup)

      taskGroupIndex--
      if (taskGroupIndex < 0) taskGroupIndex = 0

      state.taskIndex = 0
      state.taskGroupIndex = taskGroupIndex
      state.taskGroups = taskGroups

      state.taskGroupOptionsDirty = false
      state.taskOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    updateTaskGroupOptions(state, action: PayloadAction<ITaskGroupOptions>) {
      const taskGroups = [...state.taskGroups]

      taskGroups[state.taskGroupIndex] = {
        ...taskGroups[state.taskGroupIndex],
        options: action.payload
      }

      state.taskGroups = taskGroups
      state.taskGroupOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    addTask(state, action: Action) {
      const taskGroups = [...state.taskGroups]
      const taskGroup = taskGroups[state.taskGroupIndex]
      const tasks = [...taskGroup.tasks]

      tasks.push({ ...emptyTask })

      taskGroups[state.taskGroupIndex] = {
        ...taskGroup,
        tasks
      }

      state.taskGroups = taskGroups

      saveStateToLocalStorage(state)
    },
    removeTask(state, action: Action) {
      const taskGroups = [...state.taskGroups]
      const taskGroup = taskGroups[state.taskGroupIndex]
      let taskIndex = state.taskIndex

      const task = taskGroup.tasks[taskIndex]

      const tasks = taskGroup.tasks.filter(_ => _ !== task)

      taskIndex--
      if (taskIndex < 0) taskIndex = 0

      taskGroups[state.taskGroupIndex] = {
        ...taskGroup,
        tasks
      }

      state.taskIndex = taskIndex
      state.taskGroups = taskGroups

      state.taskOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    updateTask(state, action: PayloadAction<PlainTask>) {
      const taskGroups = [...state.taskGroups]
      const taskGroup = taskGroups[state.taskGroupIndex]

      const tasks = [...taskGroup.tasks]
      tasks[state.taskIndex] = action.payload

      taskGroup.tasks = tasks

      state.taskGroups = taskGroups
      state.taskOptionsDirty = false

      saveStateToLocalStorage(state)
    },
    setChallengeMetaDirty(state, action: PayloadAction<boolean>) {
      state.challengeMetaDirty = action.payload
    },
    setChallengeOptionsDirty(state, action: PayloadAction<boolean>) {
      state.challengeOptionsDirty = action.payload
    },
    setTaskGroupOptionsDirty(state, action: PayloadAction<boolean>) {
      state.taskGroupOptionsDirty = action.payload
    },
    setTaskOptionsDirty(state, action: PayloadAction<boolean>) {
      state.taskOptionsDirty = action.payload
    }
  }
})

export const {
  clearChallenge,
  loadChallenge,
  setLocale,
  setChallengeOptions,
  setTaskGroupIndex,
  setTaskGroups,
  setTaskIndex,
  changeActiveTaskGroup,
  changeActiveTask,
  updateTaskGroupOptions,
  addTaskGroup,
  removeTaskGroup,
  addTask,
  removeTask,
  updateTask,
  setChallengeMetaDirty,
  setChallengeOptionsDirty,
  setTaskGroupOptionsDirty,
  setTaskOptionsDirty,
  updateChallengeMeta,
  loadChallengeFromLocalStorage
} = challengeEditorSlice.actions

export default challengeEditorSlice.reducer
