import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SourceConditionProps, SourceTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { BaseSinglePointForm, BaseSinglePointFormApply } from "./BaseSinglePointForm";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { Well } from "../../data/Well";
import { WellApply } from "./WellApply";
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from "@mui/icons-material";

export interface SingleWellFormProps {
    well: Well
}

export function SingleWellForm(props: SingleWellFormProps) {
    const { well } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = well.data.lifetime as number | number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: well,
        get: _ => _
    }, currentDate, {}) as Partial<SourceTimedProps>
    const fallbackTimed = findComputedTimed({
        item: well,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<SourceTimedProps>


    const [inflow, setInflow] = useState<string>(currentTimed.inflow?.toString() ?? '')
    const [scaleInflow, setScaleInflow] = useState<boolean|undefined>(currentTimed.scale_inflow);

    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: well,
            get: _ => _
        }, currentDate, {}) as Partial<SourceTimedProps>


        setInflow(timed.inflow?.toString() ?? '')

    }, [well, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || inflow !== '') {
            const parsedInflow = parseFloatOrUndefined(inflow)
            if (!validNumber(parsedInflow) || parsedInflow! < 0) {
                return false
            }
        }

        return true
    }

    function handleApply(a: BaseSinglePointFormApply) {
        const timed: Partial<SourceTimedProps> = {
        }

        const applyInflow = parseFloatOrUndefined(inflow)
        if (applyInflow !== undefined) {
            timed.inflow = applyInflow
        }
        if (scaleInflow !== undefined) {
            timed.scale_inflow = scaleInflow
        }

        const apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            latitude: a.latitude,
            longitude: a.longitude,
            elevation: a.elevation,
            strength: findProp({ item: well, get: _ => _.strength }, undefined),
            conditional: {} as SourceConditionProps,
            timed: timed
        } as WellApply
        well.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function fallbackScaleInflowIcon(fallbackScaleInflow?: boolean) {
        if (fallbackScaleInflow === true) {
            return <CheckBox />
        } else if (fallbackScaleInflow === false) {
            return <CheckBoxOutlineBlank />
        } else {
            return <IndeterminateCheckBox />
        }
    }


    return (<BaseSinglePointForm validate={validate} handleApply={handleApply} feature={well} timedChildren={<>
        <TextField
            required
            type="number"
            value={inflow}
            onChange={(event) => setInflow(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.well.inflow') as string}
            InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.inflow?.toString()}
        />
        <FormControl>
            <FormControlLabel
                style={{ flexGrow: 1 }}
                control={<Checkbox
                    checked={scaleInflow === true}
                    indeterminate={scaleInflow === undefined}
                    onChange={() => {
                        if (scaleInflow === undefined) setScaleInflow(true)
                        else if (scaleInflow) setScaleInflow(false)
                        else setScaleInflow(undefined)
                    }}
                />}
                label={t('model.components.well.scale_inflow') as string}
            />
            {!isFirstYear && <FormHelperText>{fallbackScaleInflowIcon(fallbackTimed.scale_inflow)}</FormHelperText>}
        </FormControl>
    </>}></BaseSinglePointForm>)
}
