import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import ChallengeManager from "../managers/ChallengeManager";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { setChallengeCompletedPanelOpen } from "../../store/slices/challengeSlice";
import { useTranslation } from "react-i18next";
import { Duration } from "luxon";

interface ChallengeMedalProps {
  time: number
  targetTime: number
  color: string
}

function ChallengeMedal(props: ChallengeMedalProps) {
  const {
    color,
    time,
    targetTime
  } = props
  const passed = time <= targetTime
  const passedIcon = passed ? <CheckIcon color="success" /> : <CloseIcon color="error" />
  const displayedTime = (targetTime / 1000).toFixed(1)

  function renderIcon() {
    if (passed) {
      return <EmojiEventsIcon htmlColor={color} sx={{ fontSize: '64px' }} />
    }

    return <EmojiEventsIcon color="disabled" sx={{ fontSize: '64px' }} />
  }


  return <span style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {renderIcon()}
    <span>{displayedTime}s</span>
    {passedIcon}
  </span>
}


interface ChallengeCompletedProps {
}

export default function ChallengeCompleted(props: ChallengeCompletedProps) {
  const open: boolean = useSelector((state: RootState) => state.challenge.challengeCompletedPanelOpen)
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setChallengeCompletedPanelOpen(false));
  }

  function challengeMedals() {
    const challengeManager = ChallengeManager.getInstance();

    const time = challengeManager.challengeTime;
    const medals = challengeManager.challenge?.options.medals;
    if (time === undefined || medals === undefined) return

    const medalItems: JSX.Element[] = [];

    for (const medal of medals) {
      medalItems.push(<ChallengeMedal
        time={time}
        targetTime={medal.time}
        color={medal.color}
        key={medal.color}
      />)
    }

    return <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginBottom: '32px' }}>{medalItems}</div>
  }

  function challengeTimeAndScore() {
    const time = ChallengeManager.getInstance().challengeTime;
    const score = ChallengeManager.getInstance().challenge?.options.score;
    const timeStr = (time === undefined) ? '?' : Duration.fromObject({ seconds: Number.parseFloat((time / 1000).toFixed(1)) }, { locale: i18n.language }).rescale().toHuman();

    return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <div style={{ textAlign: 'center' }}>
        {t('challenge.completed.elapsed_time')}<br />{timeStr}
      </div>
      {score !== 0 && <div style={{ textAlign: 'center' }}>
        {t('challenge.completed.score')}<br />{t('challenge.completed.score_value', { score: score })}
      </div>}
    </div>;
  }

  function challengeCompletionText() {
    const completionText = ChallengeManager.getInstance().challenge?.options.completionText ?? {};
    const localizedCompletionText = completionText[i18n.language] ?? 'Challenge completed successfully.'
    return <p style={{ whiteSpace: 'pre-wrap', marginBottom: '32px' }}>{localizedCompletionText}</p>
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        {t('challenge.completed.title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          {challengeCompletionText()}
          {challengeMedals()}
          {challengeTimeAndScore()}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
