import EconomyManager from "../../../challenges/managers/EconomyManager"
import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType"
import ValveNewImage from "../../../assets/Valve_new.png";
import JunctionNewImage from "../../../assets/Junction_new.png";
import SpringNewImage from "../../../assets/Spring_new.png";
import WellNewImage from "../../../assets/Well_new.png";
import TankNewImage from "../../../assets/Tank_new.png";
import ReservoirNewImage from "../../../assets/Reservoir_new.png";
import PumpNewImage from "../../../assets/Pump_new.png";
import PipeNewImage from "../../../assets/Pipe_new.png";
import componentIsValve from "./componentIsValve";
import { EWAModelWrapper } from "../EWAModelWrapper";

export interface ComponentAddImageProps {
    alt: string
    componentType: EWAComponentType | ValveComponentType
    economyEnabled: boolean
    modelWrapper: EWAModelWrapper
}

export default function ComponentAddImage(props: ComponentAddImageProps) {
    const {
        alt,
        componentType,
        economyEnabled,
        modelWrapper
    } = props

    function imageSrc(): string {
        switch (componentType) {
            case EWAComponentType.junction:
                return JunctionNewImage
            case EWAComponentType.spring:
                return SpringNewImage
            case EWAComponentType.well:
                return WellNewImage
            case EWAComponentType.tank:
                return TankNewImage
            case EWAComponentType.reservoir:
                return ReservoirNewImage
            case EWAComponentType.pipe:
                return PipeNewImage
            case EWAComponentType.pump:
                return PumpNewImage
            case EWAComponentType.valve:
            case ValveComponentType.fcv:
            case ValveComponentType.gpv:
            case ValveComponentType.pbv:
            case ValveComponentType.prv:
            case ValveComponentType.psv:
            case ValveComponentType.tcv:
                return ValveNewImage
        }
    }

    const image = imageSrc()
    const price = economyEnabled ? EconomyManager.getInstance().price(componentType) : 0
    const isValve = componentIsValve(componentType)
    const count = modelWrapper.model.getFeatureCount(componentType)

    return <span>
        <img src={image} height={24} width={24} alt={alt} />
        {economyEnabled && <span style={{ position: 'absolute', right: '0', bottom: '0', fontSize: '8px' }}>{price}</span>}
        {isValve && <span style={{ position: 'absolute', left: '0', bottom: '0', fontSize: '8px' }}>{componentType}</span>}
        <span style={{ position: 'absolute', top: '0', right: '0', fontSize: '8px'}}>{count}</span>
    </span>
}
