import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import PumpUpdateCommentTask from "../../tasks/PumpUpdateCommentTask";
import PumpUpdateHeadCurveTask from "../../tasks/PumpUpdateHeadCurveTask";
import PumpUpdateLifetimeTask from "../../tasks/PumpUpdateLifetimeTask";
import PumpUpdatePowerTask from "../../tasks/PumpUpdatePowerTask";
import PumpUpdateStatusTask from "../../tasks/PumpUpdateStatusTask";

export default function PumpUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new PumpUpdateCommentTask(),
      new PumpUpdateHeadCurveTask(),
      new PumpUpdateLifetimeTask(),
      new PumpUpdatePowerTask(),
      new PumpUpdateStatusTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
