import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import PRVUpdateCommentTask from "../../tasks/PRVUpdateCommentTask";
import PRVUpdateDiameterTask from "../../tasks/PRVUpdateDiameterTask";
import PRVUpdateLifetimeTask from "../../tasks/PRVUpdateLifetimeTask";
import PRVUpdateMaximumPressureTask from "../../tasks/PRVUpdateMaximumPressureTask";
import PRVUpdateStatusTask from "../../tasks/PRVUpdateStatusTask";

export default function PRVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new PRVUpdateCommentTask(),
      new PRVUpdateDiameterTask(),
      new PRVUpdateLifetimeTask(),
      new PRVUpdateMaximumPressureTask(),
      new PRVUpdateStatusTask(),
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
