import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Body_upload_v1_models_post, ModellingService } from "@/api/client";
import UploadIcon from "@mui/icons-material/Upload";
import { ModelUploadDialogProps } from "./ModelUploadDialog";
import { Close, FileUpload } from "@mui/icons-material";
import { minYear } from "@/utils/constants";

export function EWAUploadDialog(props: ModelUploadDialogProps) {
    const { open, setOpen, setSelection, fetchModels, type } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState<File>();
    const handleFileSelect = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files != null && files.length > 0) {
            setSelectedFile(files[0]);
            if (name === "") {
                setName(files[0].name.replace(/\.(ewa|json)/, ""));
            }
        }
    }, [name, setSelectedFile, setName]);
    const { t } = useTranslation();

    const handleUpload = useCallback(async () => {
        if (!name || !selectedFile) return;
        ModellingService.uploadV1ModelsPost(name, description, minYear, undefined, type, {
            upload_file: selectedFile
        } as Body_upload_v1_models_post).then(m => {
            setSelection(m.id);
            setSelectedFile(undefined);
            setName("");
            setDescription("");
            setOpen(false)
            fetchModels()
        }).catch(e => {
            setSelectedFile(undefined);
            setName("");
            setDescription("");
            setOpen(false)
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        })
    }, [setOpen, setSelectedFile, setSelection, enqueueSnackbar, setName, setDescription, name, selectedFile, description]);

    function handleClose() {
        setSelectedFile(undefined);
        setName("");
        setDescription("");
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>
                {t('home.upload_model.title')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUpload />}
                    sx={{ marginBottom: 2 }}
                >
                    {t('home.upload_model.select_btn_ewa')}
                    <input
                        accept=".ewa, .json"
                        hidden
                        id="pset-file-input"
                        type="file"
                        onChange={handleFileSelect}
                    />
                </Button>
                {selectedFile !== undefined && <Typography style={{ marginBottom: "12px" }} variant="body1">{selectedFile?.name}</Typography>}
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label={t('general.name')}
                    required
                    fullWidth
                />
                <TextField
                    multiline
                    rows={4}
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    label={t('general.description')}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleUpload}
                    variant="contained"
                    aria-label={t('general.create') as string}
                    disabled={!name || !selectedFile}
                    startIcon={<UploadIcon />}
                    color="success"
                >
                    {t('general.upload')}
                </Button>
            </DialogActions>
        </Dialog>)
}
