import { OrthoLayer } from "../../data/OrthoLayer";
import EventType from "../EventType";

export interface OrthoLayerChangedEventArgs {
  oldOrthoLayer: OrthoLayer,
  newOrthoLayer: OrthoLayer
}

export default class OrthoLayerChangedEvent extends CustomEvent<OrthoLayerChangedEventArgs> {
  public constructor( detail: OrthoLayerChangedEventArgs) {
    super(EventType.OrthoLayerChanged, { detail })
  }
}
