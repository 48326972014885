import { v4 as uuidv4 } from 'uuid';
import { EWAModel } from '../../data/EWAModel';

export class EWAModelWrapper {
    model: EWAModel;
    id: string;

    constructor(features: EWAModel) {
        this.model = features;
        this.model.id = uuidv4();
        this.id = uuidv4();
    }
}
